

export class EventListReq {
    CType: number = 12;
    PageNum: number = 1;
    PageCount: number = 6;
}

export class EventListRes {
    allDataCount: number = 0;
    infos: Event[] = [];
}
export class Event {
    cContent: string = ""
    cContentType: number
    cCreateDt: string
    cCreator: string
    cEdate: string
    cExpassDt: string
    cExstatus: number
    cImg: string
    cName: string
    cSdate: string
    cSort: number
    cStatus: number
    cSubTitle: string
    cType: number
    typeName: string
    cUpdateDt: string
    cUpdator: string
    cUrl: string
    cisDelete: null
    id: number
    cAnother: number;
}
