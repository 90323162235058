import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MbrInfo } from 'src/app/shared/model/member';
import { BreadcrumbService } from 'src/app/shared/service/breadcrumb.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';

@Component({
  selector: 'app-member-point-transfer',
  templateUrl: './member-point-transfer.component.html',
  styleUrls: ['./member-point-transfer.component.scss']
})
export class MemberPointTransferComponent implements OnInit {
  // modal
  transferModalProcess = 1;
  isTransferModalOpen = false;

  mbrInfo: MbrInfo;
  constructor(private breadcrumbService: BreadcrumbService,
    private loginService: LoginService,
    private pagetagService: PagetagService,
    private router: Router) { }

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbData.next([{ breadcrumb: '轉贈點數', path: '' }])
    this.getMemberInfo();
    this.callPagetagService();
  }

  getMemberInfo() {
    this.mbrInfo = this.loginService.GetLocalInfo();
  }

  openTransferModal() {
    this.transferModalProcess = 1;
    this.isTransferModalOpen = true;
  }

  callPagetagService() {
    this.pagetagService.callPageTag(this.router.url, '會員服務 | 會員點數 | 轉贈點數')
  }
}
