import { HeaderService } from 'src/app/shared/service/header.service';
import { LoginService } from './../../../shared/service/login.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  ProductListReq,
  ProductListRes,
  brandClass,
} from './../../../shared/model/product';
import { HttpService } from './../../../shared/service/http.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { BreadcrumbService } from 'src/app/shared/service/breadcrumb.service';
import { MbrInfo } from 'src/app/shared/model/member';
import { ItemsPerPageData } from 'src/app/shared/model/pagination';
import { PagetagService } from 'src/app/shared/service/pagetag.service';
import { CreateCart, DeleteCartReq } from 'src/app/shared/model/cart';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  // modal
  modalTiTle = '';
  modalMsg = '';
  isModalOpen = false;

  // 預設品牌slider 當前頁數
  currentSlidePage: number = 1;
  // 是否顯示排序區塊
  isSortSectionShow = false;
  // 升降冪排序
  isSortASC = false;
  //排序模式
  readonly defaultSortType = 'SellAmount';
  sort = this.defaultSortType;
  // 是否顯示手機版搜尋區塊
  isSearchSectionShow = false;
  // slider 品牌active
  brandActive = 0;
  // 紀錄品牌展開次數
  brandListExpandCounter = 1;

  windowWidth: number;
  windowY: number;
  ListReq: ProductListReq = new ProductListReq();
  ListRes: ProductListRes = new ProductListRes();
  userInfo: MbrInfo;
  Keywords='';
  itemsPerPage = 20;
  itemsPerPageData = new ItemsPerPageData();
  allSearch = false;
  @ViewChild('searchConditionRangeSelect')
  searchConditionRangeSelect: ElementRef;
  @ViewChildren('searchConditionRangeButton')
  searchConditionRangeButton: QueryList<ElementRef>;

  constructor(
    private elements: ElementRef,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private loaderService: LoaderService,
    private breadcrumbServie: BreadcrumbService,
    private pagetagService: PagetagService,
    private router: Router,
    public headerService:HeaderService,
  ) {}

  ngOnInit(): void {
    this.headerService.Footer =false;
    this.userInfo = this.loginService.GetLocalInfo();
    this.route.params.subscribe((routeParams) => {
      if (routeParams && routeParams['pName']) {
        let Keywords = routeParams['pName'];

        this.Keywords=Keywords;
        this.allSearch = true;
      }
      this.windowWidth = window.innerWidth;
      this.windowY = window.scrollY;
      this.resetRequestParams();

      if (this.route.snapshot.url.toString().split(',')[0] == 'recommend') {
        this.ListReq.RecommendMode = true;
        this.ListReq.HomeProType = parseInt(this.route.snapshot.url.toString().split(',')[1]);
      }


      this.ListReq.Cat1 = this.route.snapshot.params['cat1'];
      let cat2 = parseInt(this.route.snapshot.params['cat2']);
      if (cat2) {
        this.ListReq.Cat2.push(cat2);
      }

      this.getProductList();
      this.addCategoryClickLog();
      this.setItemsPerPageData();
    });
  }

  // 預設request 參數及UI 狀態
  resetRequestParams() {
    this.ListReq = new ProductListReq();

    this.isSortASC = false;
    this.sort = this.defaultSortType;
    this.ListReq.Sort = this.sort;

    if (this.searchConditionRangeSelect?.nativeElement?.value) {
      this.searchConditionRangeSelect.nativeElement.value = '-1';
    }

    if (
      this.elements?.nativeElement &&
      this.elements.nativeElement.querySelectorAll.length > 0
    )
      this.elements.nativeElement
        .querySelectorAll('[name=pointButton]')
        .forEach((item: HTMLElement) => {
          item.classList.remove('search-condition__range-button--active');
        });
  }

  @HostListener('window:resize', ['$event']) onResize($event?: any) {
    this.windowWidth = window.innerWidth;
    this.windowY = window.scrollY;
    this.toggleBrandListHeight('init');
  }

  addCategoryClickLog() {
    this.httpService
      .post('/product/AddCategoryClick', {
        category: this.route.snapshot.params['cat1'],
      })
      .subscribe((x) => {});
  }

  setItemsPerPageData() {
    // 創建新物件，強迫觸發pagination 元件onChange 行為
    this.itemsPerPageData = new ItemsPerPageData();
    this.itemsPerPageData.min = this.itemsPerPage;
    this.itemsPerPageData.sm = this.itemsPerPage;
    this.itemsPerPageData.md = this.itemsPerPage;
    this.itemsPerPageData.lg = this.itemsPerPage;
    this.itemsPerPageData.xl = this.itemsPerPage;
  }

  // 顯示排序功能選項
  showSortSection(condition: string) {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return;
    }

    switch (condition) {
      case 'over':
        this.isSortSectionShow = true;
        break;
      case 'out':
        this.isSortSectionShow = false;
        break;
    }
  }

  showSortSectionMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isSortSectionShow = !this.isSortSectionShow;
    }
  }

  // 改變升降冪
  changeSortDirection($event: any) {
    if ($event.target.classList.contains('js-sort-direction')) {
      this.isSortASC = !this.isSortASC;
      this.getProductList();
    }
  }

  // 點擊加入收藏改變icon
  addToFavorite($event: any) {
    $event.preventDefault();
    const originUrl = window.location.origin;
    const solidIconUrl =
      originUrl + '/assets/images/icon/favorite_heart_pink_solid_ring.svg';
    const hollowIconUrl =
      originUrl + '/assets/images/icon/favorite_heart_pink_opacity_ring.svg';
    const currentUrl = $event.target.src;
    if (currentUrl == solidIconUrl) {
      $event.target.src = hollowIconUrl;
    } else {
      $event.target.src = solidIconUrl;
    }
  }

  showSearchSection(isShow: boolean, $event?: any) {
    if ($event) {
      if (!$event.target.classList.contains('js-search-condition')) {
        return;
      }
      if (this.windowWidth > 1200) {
        return;
      }

      this.isSearchSectionShow = isShow;
      return;
    }

    if (this.windowWidth > 1200) {
      return;
    }

    this.isSearchSectionShow = isShow;
  }

  resetSearchCondition() {
    // 避免input 顯示數字，故賦值undefined
    this.ListReq.LowestPrice = undefined;
    this.ListReq.HishestPrice = undefined;
    this.removePriceActive();
    this.searchConditionRangeSelect.nativeElement.value = '-1';
  }

  setSearchCondition() {
    this.getProductList();
    this.showSearchSection(false);
  }

  getProductList(ischangePage: boolean = false) {
    this.loaderService.start();

    if (!ischangePage) {
      this.ListReq.Page = 1;
    }
    this.ListReq.Sort = this.sort;
    this.ListReq.SortType = this.isSortASC ? 'asc' : 'desc';
    this.ListReq.MbrID = this.userInfo.ID;
    this.ListReq.AllSearch = this.allSearch;

    if (this.allSearch) {
      this.ListReq.Keywords = this.Keywords;
      this.httpService
        .post('/Product/GetAllProductList', this.ListReq)
        .subscribe(
          (x: any) => {
            this.ListRes = x;
            // 非同步作法待優化
            const _this = this;
            setTimeout(function () {
              _this.toggleBrandListHeight('init');
            }, 0);

            this.breadcrumbServie.breadcrumbData.next([
              { breadcrumb: this.ListRes.cat1Name, path: '' },
            ]);
            this.callPagetagService(`全館`);
            this.loaderService.stop();
          },
          (err: Error) => {
            // 非同步作法待優化
            const _this = this;
            setTimeout(function () {
              _this.toggleBrandListHeight('init');
            }, 0);
          }
        );
    } else {
      this.httpService
        .post('/Product/GetProductList', this.ListReq)
        .subscribe(
          (x: any) => {
            this.ListRes = x;
            // 非同步作法待優化
            const _this = this;
            setTimeout(function () {
              _this.toggleBrandListHeight('init');
            }, 0);

            this.breadcrumbServie.breadcrumbData.next([
              { breadcrumb: this.ListRes.cat1Name, path: '' },
            ]);
            this.callPagetagService(`品牌館 | ${this.ListRes.cat1Name}`);
            this.loaderService.stop();
          },
          (err: Error) => {
            // 非同步作法待優化
            const _this = this;
            setTimeout(function () {
              _this.toggleBrandListHeight('init');
            }, 0);
          }
        );
    }
  }

  addOrRemoveBrand(brand: brandClass, $event?: any) {
    if ($event) {
      $event.preventDefault();
    }
    let brandID = brand.cId;
    var isBrandExist = this.ListReq.Cat2.indexOf(brandID) > -1;
    if (isBrandExist && this.ListReq.Cat2.length === 1) {
      this.ListReq.Cat2[0] = brandID;
    } else if (!isBrandExist) {
      this.ListReq.Cat2 = [brandID];
    }

    this.getProductList();
  }

  clickPriceRange(lowest: number, highest: number, $event?: any) {
    if (
      $event.target.classList.contains('search-condition__range-button--active')
    ) {
      this.removePriceActive();
      this.ListReq.LowestPrice = undefined;
      this.ListReq.HishestPrice = undefined;
    } else {
      this.removePriceActive();
      $event.target.classList.add('search-condition__range-button--active');

      this.ListReq.LowestPrice = lowest;
      this.ListReq.HishestPrice = highest;
    }

    this.searchConditionRangeSelect.nativeElement.value = `${lowest.toString()}|${highest.toString()}`;
  }

  handleRangeSelectChange(val: string) {
    if (typeof val !== 'string') {
      return;
    }
    if (!val.includes('|')) {
      this.ListReq.LowestPrice = undefined;
      this.ListReq.HishestPrice = undefined;
    }

    const arr = val.split('|');
    this.ListReq.LowestPrice = parseInt(arr[0]);
    this.ListReq.HishestPrice = parseInt(arr[1]);

    let targetIndex = -1;
    const lowestValueGroup = [0, 201, 1001, 3001, 5001, 8001, 10001, 20001];
    lowestValueGroup.forEach((val, index) => {
      if (val == parseInt(arr[0])) {
        targetIndex = index;
      }
    });
    this.searchConditionRangeButton.forEach((item, i) => {
      if (i == targetIndex) {
        item.nativeElement.classList.add(
          'search-condition__range-button--active'
        );
      } else {
        item.nativeElement.classList.remove(
          'search-condition__range-button--active'
        );
      }
    });
  }

  removePriceActive() {
    this.elements.nativeElement
      .querySelectorAll('[name=pointButton]')
      .forEach((item: HTMLElement) => {
        item.classList.remove('search-condition__range-button--active');
      });
  }

  setPointInput(type: string) {
    this.removePriceActive();
    this.searchConditionRangeSelect.nativeElement.value = '-1';

    if (type == 'L') {
      if (!this.ListReq.LowestPrice) {
        this.ListReq.HishestPrice = undefined;
      } else {
        if (this.ListReq.HishestPrice) {
          if (
            Number(this.ListReq.LowestPrice) > Number(this.ListReq.HishestPrice)
          ) {
            this.ListReq.HishestPrice = this.ListReq.LowestPrice;
          }
        } else {
          this.ListReq.HishestPrice = this.ListReq.LowestPrice;
        }
      }
    } else {
      if (!this.ListReq.HishestPrice) {
        this.ListReq.LowestPrice = undefined;
      } else {
        if (this.ListReq.LowestPrice) {
          if (
            Number(this.ListReq.LowestPrice) > Number(this.ListReq.HishestPrice)
          ) {
            this.ListReq.LowestPrice = this.ListReq.HishestPrice;
          }
        } else {
          this.ListReq.LowestPrice = 0;
        }
      }
    }
  }

  clickSortButton(type: string, $event: any) {
    if ($event) {
      $event.preventDefault();
    }
    this.sort = type;
    this.ListReq.Sort = this.sort;
    this.getProductList();
  }

  goToPage(page: number) {
    this.ListReq.Page = page;
    this.getProductList(true);
  }

  toggleBrandListHeight(condition: string, $event?: any) {
    if ($event) {
      $event.preventDefault();
    }
    //取品牌數量
    const brandAmount =
      this.elements.nativeElement.querySelectorAll('.brand-list__item').length;
    //取頁面展示數量
    let showAmount: number = 4;
    let eachDistance: number = 240;
    let eachDistanceStr: string = '';

    if (this.windowWidth < 576) {
      eachDistance = 116;
    }
    if (this.windowWidth >= 768) {
      showAmount = 6;
    }
    if (this.windowWidth >= 992) {
      showAmount = 8;
    }
    if (this.windowWidth >= 1200) {
      showAmount = 10;
    }
    //取總共頁數
    const maxCounter = Math.ceil(brandAmount / showAmount);
    const btnWrapElement = this.elements.nativeElement.querySelector(
      '.js-brand-list__button-wrap'
    );
    const expandBtn = this.elements.nativeElement.querySelector(
      '.brand-list__button--expand'
    );
    const collapseBtn = this.elements.nativeElement.querySelector(
      '.brand-list__button--collapse'
    );
    const limitWrapElement =
      this.elements.nativeElement.querySelector('.brand-list__wrap');
    const imageRow =
      this.elements.nativeElement.querySelector('.brand-list__row');

    switch (condition) {
      case 'expand':
        let imageRowHeight = imageRow.offsetHeight;

        if (this.brandListExpandCounter == maxCounter - 1) {
          if (this.windowWidth >= 768) {
            if (brandAmount - showAmount * (maxCounter - 1) <= showAmount / 2) {
              eachDistanceStr = imageRowHeight + eachDistance / 2 + 'px';
            } else {
              eachDistanceStr = imageRowHeight + eachDistance + 'px';
            }
          } else {
              eachDistanceStr = imageRowHeight + eachDistance + 'px';
          }

        } else {
          eachDistanceStr = imageRowHeight + eachDistance + 'px';
        }

        imageRow.style['height'] = eachDistanceStr;
        this.brandListExpandCounter++;

        if (this.brandListExpandCounter == maxCounter) {
          btnWrapElement.classList.add('brand-list__button-wrap--hide'); // 如果不展示收合按鈕的情況，需要把wrap element隱藏

          expandBtn.classList.add('brand-list__button--hide');
          collapseBtn.classList.add('brand-list__button--collapse-only');
        } else {
          collapseBtn.classList.remove('brand-list__button--collapse-only');
        }
        btnWrapElement.classList.remove('brand-list__button-wrap--hide');
        expandBtn.classList.remove('brand-list__button--expand-only');
        collapseBtn.classList.remove('brand-list__button--hide');

        break;
      case 'collapse':
        limitWrapElement.style['height'] = '240px';
        this.brandListExpandCounter = 1;
        collapseBtn.classList.add('brand-list__button--hide');
        expandBtn.classList.remove('brand-list__button--hide');
        expandBtn.classList.add('brand-list__button--expand-only');

        break;
      case 'init':
        this.brandListExpandCounter = 1;

        // just one row
        if (brandAmount <= showAmount / 2) {
          eachDistanceStr = eachDistance  + 'px';
          imageRow.style['height'] = eachDistanceStr;
        } else {
          eachDistanceStr = eachDistance + 'px';
          imageRow.style['height'] = eachDistanceStr;
        }

        if (brandAmount <= showAmount) {
          if (!expandBtn.classList.contains('brand-list__button--hide')) {
            expandBtn.classList.add('brand-list__button--hide');
          }

          if (
            !btnWrapElement.classList.contains('brand-list__button-wrap--hide')
          ) {
            btnWrapElement.classList.add('brand-list__button-wrap--hide');
          }
        } else {
          expandBtn.classList.remove('brand-list__button--hide');
          btnWrapElement.classList.remove('brand-list__button-wrap--hide');
        }

        if (!expandBtn.classList.contains('brand-list__button--expand-only')) {
          expandBtn.classList.add('brand-list__button--expand-only');
        }

        if (collapseBtn) {
          if (!collapseBtn.classList.contains('brand-list__button--hide')) {
            collapseBtn.classList.add('brand-list__button--hide');
          }
        }

        if (collapseBtn) {
          collapseBtn.classList.remove('brand-list__button--collapse-only');
        }

        break;
    }
  }

  callPagetagService(name: string) {
    this.pagetagService.callPageTag(this.router.url, name);
  }

  toggleToFavorite(id: number, isCollect: boolean) {
    const _this = this;
    if (!this.userInfo.IsLogin) {
      this.modalTiTle = '收藏';
      this.modalMsg = '請先登入會員';
      this.isModalOpen = true;
      return;
    }
    if (!isCollect) {
      let args = new CreateCart();
      args.isDetailId = false;
      args.Amount = 1;
      args.MemberId = this.userInfo.ID;
      args.Category1_id = 0;
      args.Category2_id = 0;
      args.ProductDtlId = id;

      this.httpService
        .post('/CartNew/CreateColl', args)
        .subscribe((x: any) => {
          if (x.result == '成功') {
            this.modalTiTle = '收藏';
            this.modalMsg = '收藏成功';
            this.isModalOpen = true;
            changeCollectStatus(id);
          } else if (x.result == '重複') {
            this.modalTiTle = '收藏';
            this.modalMsg = '無法重複收藏';
            this.isModalOpen = true;
          }
        });
      return;
    }

    let args = new DeleteCartReq();
    args.isCartId = false;
    args.Ids.push(id);
    args.isAddCollect = false;
    this.httpService.post('/CartNew/Delete', args).subscribe((x: any) => {
      this.isModalOpen = true;
      this.modalTiTle = '收藏';
      this.modalMsg = '刪除成功';
      changeCollectStatus(id);
    });

    function changeCollectStatus(productId: number) {
      _this.ListRes.products.forEach((item) => {
        if (item.id == productId) {
          item.isCollect = !item.isCollect;
        }
      });
    }
  }
}
