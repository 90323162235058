<section class="search-bar">
    <!-- <div class="search-bar__wrap">
        <input class="search-bar__input" type="text" (keyup.enter)="filter()" [(ngModel)]="keyword"
            placeholder="品牌關鍵字查詢">
        <button class="search-bar__button" (click)="filter()">
            <img src="/assets/images/icon/magnifier_pink.svg" alt="">
        </button>
    </div> -->
</section>
<section class="brand-slider-mobile" *ngFor="let item of viewBrands">
    <div>
        <h2 class="brand-slider-mobile__title">{{item.name}}</h2>
    </div>
    <div class="brand-slider-mobile__row">
        <div class="brand-slider-mobile__col" *ngFor="let item2 of item.list">
            <div class="brand-slider-mobile__item">
                <a routerLink="/product/list/{{item2.cat1_id}}/{{item2.cat2_id}}">
                    <img [src]="item2.logo" alt="">
                    <div class="brand-slider-mobile__name">{{item2.name2}}</div>
                </a>
            </div>
        </div>
    </div>
</section>
