<div class="container">
    <!-- <app-breadcrumb [noRootBreadcrumb]='true'></app-breadcrumb>
    <app-tab-navbar [pathPrefix]="'/event'" [childrenPathObjList]="childrenPathObjList"></app-tab-navbar> -->
<h2 class="contact-title" *ngIf="headerService.Active">活動快訊</h2>
    <!-- 子路由元件 -->
    <section class="event-content">
        <router-outlet></router-outlet>
    </section>
    <!-- 子路由元件 -->
</div>
