<!-- <section class="js-carousel carousel" [ngClass]="{'carouselInner': !isTop}" *ngIf="false">

  <div #carouselInner class="js-carousel-inner carousel__inner">

    <div #carouselItemList class="carousel__item" *ngFor="let item of banners;let i=index;"
      [ngClass]="{'carousel__item--show': carouselItemActive==i}">
      <a [href]="item.cUrl">


        <img #carouselMobileImage class="js-carousel__image carousel__image"
          [ngClass]="{'js-active-image': carouselItemActive==i}" src="{{item.cImg}}" alt=""
          *ngIf="item.cMediaType == 1">

        <iframe *ngIf="item.cMediaType == 2" width="330" height="170" id="{{'iframe:'+i}}" src=''
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>


      </a>
    </div>
  </div>

</section> -->

<!-- <swiper [spaceBetween]="isTop ? 15 : 30" [loop]="true" [centeredSlides]="true" [slidesPerView]="isTop ? 1.15 : 1"
  [autoplay]="{delay: 5000,disableOnInteraction: false}" [pagination]="{clickable:true}" [navigation]="true"
  class="mySwiper carousel" [ngClass]="{'carouselInner': !isTop}">
  <ng-template swiperSlide *ngFor="let item of banners;let i=index;">
    <a [href]="item.cUrl">

      <img #carouselMobileImage class="js-carousel__image carousel__image"
        [ngClass]="{'js-active-image': carouselItemActive==i,'carousel__image_top':isTop}" src="{{item.cImg}}" alt=""
        *ngIf="item.cMediaType == 1">

      <iframe *ngIf="item.cMediaType == 2" width="330" height="170" id="{{'iframe:'+i}}" src=''
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </a>
  </ng-template>
</swiper> -->

<div class="mySwiper carousel">
  <owl-carousel-o [options]="customOptions" #owlCar1>
    <ng-container *ngFor="let item of banners;let i=index;">
      <ng-template carouselSlide>
        <a [href]="item.cUrl">
          <img #carouselMobileImage class="js-carousel__image carousel__image"
            [ngClass]="{'js-active-image': carouselItemActive==i,'carousel__image_top':isTop}" src="{{item.cImg}}"
            alt="" *ngIf="item.cMediaType == 1">
              <iframe *ngIf="item.cMediaType == 2" width="330" height="170" id="{{'iframe:'+i}}" src=''
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
          </a>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
