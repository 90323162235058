import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../service/login.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private loginService: LoginService, private router: Router) {}
  login: any;
  // 參數會在進入路由後注入
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    console.log('canActivate');
    let obj = document.getElementById('doLogin');
    if (obj) {
      obj.dispatchEvent(new Event('change'));
    }
    return new Observable<boolean>((obs) => {
      this.login = setInterval(() => {
        let isLogin = this.loginService.GetLocalInfo().Token;
        console.log(isLogin);
        if (isLogin) {
          obs.next(true);
          clearInterval(this.login);
        }
      }, 100);
    });
  }

  // 針對子路由
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
