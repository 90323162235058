import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreadcrumbData } from '../../model/breadcrumb';
import { BreadcrumbService } from '../../service/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  basePathObjectList: BreadcrumbData[] = [];
  pathObjectList: BreadcrumbData[] = [];

  @Input('noRootBreadcrumb') public noRootBreadcrumb: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService) {
  }

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbData.subscribe(x => {
      this.pathObjectList = this.basePathObjectList.concat(x);
    });
    this.getPathObjList(this.route);

    // 訂閱router 變化
    this.router.events.subscribe(() => {
      this.pathObjectList = [];
      this.getPathObjList(this.route);
    })
  }

  getPathObjList(route: ActivatedRoute) {
    if (this.noRootBreadcrumb) {
      return;
    }

    const pathObject: BreadcrumbData = { breadcrumb: route.snapshot.data['breadcrumb'], path: route.snapshot.data['path'] }
    this.pathObjectList.push(pathObject);
    if (!route.firstChild?.snapshot.data['breadcrumb']) {
      this.basePathObjectList = this.pathObjectList;
      return;
    }

    // 若有children 則繼續取值
    this.getPathObjList(route.firstChild)
  }
}
