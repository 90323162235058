<section class="event-detail">
  <h2 class="contact-title"></h2>
  <!-- <div class="event-detail__back-to-list">
    <a href="#/" (click)="backToList($event)">
      <img src="/assets/images/icon/arrow_left_black.svg" alt="">
      回列表
    </a>
  </div> -->
  <div class="event-list__col">
    <div class="event-detail__title">
      <div class="event-detail__image">
        <a *ngIf="eventInfo.cUrl" href="{{eventInfo.cUrl}}" target="{{eventInfo.cAnother ? '_blank' : undefined}}">
          <img src="{{eventInfo.cImg}}" alt="" style="width: 100%;">
        </a>
        <a *ngIf="!eventInfo.cUrl" [routerLink]="['/event', eventInfo.cType,eventInfo.id]">
          <img src="{{eventInfo.cImg}}" alt="" style="width: 100%;">
        </a>
      </div>
      <a *ngIf="eventInfo.cName">{{eventInfo.cName}}</a>
      <!-- <span class="dateorange" *ngIf="eventInfo.cSdate">{{eventInfo.cSdate | date: 'yyyy/MM/dd'}}~{{eventInfo.cEdate |
        date: 'yyyy/MM/dd'}}</span> -->
    </div>
    <div class="event-detail__content-wrap" *ngIf="eventInfo.cContent" [innerHtml]="eventInfo.cContent | html">
    </div>

<a class="best-seller__more-link" (click)="backToList($event)">返回列表</a>
  </div>
<!--
  <a class="best-seller__more-link" (click)="backToList($event)">返回列表</a> -->
</section>


<!-- <div class="active-content">
  <div class="active">
    <div class="blue-icon"></div>
    <span class="title">活動內容</span>
    <span class="text">本活動1月份回饋已於2023/1/31上午11:00:37額滿。感謝您的支持!本活動1月份回饋已於2023/1/31上午11:00:37額滿。感謝您的支持!，感謝您的支持!</span>
  </div>

  <div class="active">
    <div class="blue-icon"></div>
    <span class="title">注意事項</span>
    <span class="text">活動參加人於參加本活動之時，即同意接受本活動之活動辦法及注意事項，如有違反，主辦單位得取消其參加或回饋資格。(活動期間使用悠遊付消費之人，視同參加本活動，為活動參加人)</span>
  </div>
</div> -->
