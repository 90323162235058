<section class="js-carousel carousel" [ngClass]="{'carouselInner': !isTop}" *ngIf="false">
  <!-- <div class="carousel__go-next-button">
        <a href="#/" (click)="scrollToAnchor($event)">
            <span><img src="/assets/images/icon/arrow_bottom_pink.svg" alt=""></span>
        </a>
    </div> -->
  <div #carouselInner class="js-carousel-inner carousel__inner">
    <!-- ngfor -->
    <!-- ngClass 0,1,2 should be index-->
    <div #carouselItemList class="carousel__item" *ngFor="let item of banners;let i=index;"
      [ngClass]="{'carousel__item--show': carouselItemActive==i}">
      <a [href]="item.cUrl">
        <!-- desktop -->
        <!-- <img #carouselDesktopImage class="js-carousel__image carousel__image"
          [ngClass]="{'js-active-image': carouselItemActive==i}" src="{{item.cImg}}" alt="" *ngIf="!isMobile"
          (load)="resetCarouselHeight(carouselDesktopImage)"> -->

        <!-- mobile -->
        <img #carouselMobileImage class="js-carousel__image carousel__image"
          [ngClass]="{'js-active-image': carouselItemActive==i}" src="{{item.cImg}}" alt=""
          *ngIf="item.cMediaType == 1">
        <!-- <iframe *ngIf="item.cMediaType == 2 && isYoutube(item.cImg)"  width="100%" height="170px"   src="{{item.youtubeUrl}}">
        </iframe> -->
        <iframe *ngIf="item.cMediaType == 2" width="330" height="170" id="{{'iframe:'+i}}" src=''
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>

        <!-- <iframe *ngIf="item.cMediaType == 2" width="610" height="140" [src]="'https://www.youtube.com/embed/'+item.cImg+'?autoplay=1&mute=1'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
      </a>
    </div>
  </div>
  <!-- <ol class="carousel__indicators"> -->
  <!-- ngFor -->
  <!-- ngClass 0,1,2 should be index-->
  <!-- <li class="indicators__item" (click)="onCarouselChange(carouselInner,i,$event)"
            *ngFor="let item of banners;let i =index">
            <a href="#/" class="indicators__link" [ngClass]="{'indicators__link--active': carouselItemActive==i}"></a>
        </li>
    </ol> -->
</section>

<swiper [spaceBetween]="isTop ? 15 : 30" [loop]="true" [centeredSlides]="true" [slidesPerView]="isTop ? 1.15 : 1"[autoplay]="{delay: 5000,disableOnInteraction: false}"
  [pagination]="{clickable:true}" [navigation]="true" class="mySwiper carousel" [ngClass]="{'carouselInner': !isTop}">
  <ng-template swiperSlide *ngFor="let item of banners;let i=index;">
    <a [href]="item.cUrl">
      <!-- desktop -->
      <!-- <img #carouselDesktopImage class="js-carousel__image carousel__image"
        [ngClass]="{'js-active-image': carouselItemActive==i}" src="{{item.cImg}}" alt="" *ngIf="!isMobile"
        (load)="resetCarouselHeight(carouselDesktopImage)"> -->

      <!-- mobile -->
      <img #carouselMobileImage class="js-carousel__image carousel__image"
        [ngClass]="{'js-active-image': carouselItemActive==i,'carousel__image_top':isTop}" src="{{item.cImg}}" alt=""
        *ngIf="item.cMediaType == 1">
      <!-- <iframe *ngIf="item.cMediaType == 2 && isYoutube(item.cImg)"  width="100%" height="170px"   src="{{item.youtubeUrl}}">
  </iframe> -->
      <iframe *ngIf="item.cMediaType == 2" width="330" height="170" id="{{'iframe:'+i}}" src=''
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>

      <!-- <iframe *ngIf="item.cMediaType == 2" width="610" height="140" [src]="'https://www.youtube.com/embed/'+item.cImg+'?autoplay=1&mute=1'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
    </a>
  </ng-template>
</swiper>
