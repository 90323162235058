<div class="container">
    <ng-container *ngIf="!isCartPage">
        <app-breadcrumb></app-breadcrumb>
    </ng-container>
    <ng-container *ngIf="!isCartPage &&  headerService.Order">
     <div class="tab-navbar-mobile">
      <div class="tab-navbar-mobilediv "><a  class="tab-navbar-mobile__link"  routerLink="/member/order">訂單查詢</a></div>
     </div>
        <!-- <app-tab-navbar [pathPrefix]="'/member'" [childrenPathObjList]="childrenPathObjList"></app-tab-navbar> -->
    </ng-container>
    <!-- 子路由元件 -->
    <div class="member-content">
        <router-outlet></router-outlet>
    </div>
    <!-- 子路由元件 -->
