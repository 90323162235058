import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PagetagService } from 'src/app/shared/service/pagetag.service';
import { ShutdownService } from 'src/app/shared/service/shutdown.service';

@Component({
  selector: 'app-home-shutdown',
  templateUrl: './home-shutdown.component.html',
  styleUrls: ['./home-shutdown.component.scss']
})
export class HomeShutdownComponent implements OnInit {
  shutdownContent = '';
  constructor(
    private shutdownService: ShutdownService,
    private router: Router,
    private pagetagService: PagetagService,
  ) { }

  ngOnInit(): void {
    this.callPagetagService();
    this.shutdownService.shutdownContent.subscribe(val => {
      this.shutdownContent = val;
    })
  }

  callPagetagService() {
    this.pagetagService.callPageTag(this.router.url, '停機公告')
  }
}
