<div class="top-btn" [ngClass]="{'top-btn--hide': isTopBtnHide}">
    <a href="#/" (click)="onTopBtnClick($event)">
        <span><img src="/assets/images/icon/arrow_top_pink.svg" alt=""></span>
        <span>TOP</span>
    </a>
</div>

<div class="home-btn" [ngClass]="{'top-btn--hide': isTopBtnHide}">
  <a [routerLink]="'/question/contact'" >
      <span><img  src="/assets/images/icon/customer_se.svg" alt=""></span>
      <!-- <span>聯絡我們</span> -->
  </a>
</div>
