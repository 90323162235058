import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VoucherExchangeReq, VoucherExchangeRes } from 'src/app/shared/model/member';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';

@Component({
  selector: 'app-member-voucher',
  templateUrl: './member-voucher.component.html',
  styleUrls: ['./member-voucher.component.scss']
})
export class MemberVoucherComponent implements OnInit {
  isModalOpen = false;
  modalTiTle = "";
  modalPoint = 0;
  modalMsg = "";
  // modalDate = "2021/05/05";

  voucher = "";
  voucherStatus = 1; // 1 for success, 2 for fail

  isResolved = false;
  token: string | undefined;
  constructor(private httpService: HttpService,
    private loginService: LoginService,
    private pagetagService: PagetagService,
    private router: Router) { }

  ngOnInit(): void {
    this.callPagetagService();
  }

  verifyVoucher() {
    let reqParams = new VoucherExchangeReq();
    reqParams.id = this.loginService.GetLocalInfo().ID;
    reqParams.voucher = this.voucher;
    reqParams.token = this.token;

    this.httpService.post('/member/verifyVoucher', reqParams).subscribe((x: { code: number, result: VoucherExchangeRes }) => {
      if (x.result.result) {
        this.voucherStatus = 1;
        this.modalPoint = x.result.vBonus;
        this.isModalOpen = true;
        return;
      }

      this.voucherStatus = 2;
      this.modalTiTle = '失敗';
      this.modalMsg = x.result.resultMsg;
      this.isModalOpen = true;
    }, err => {
      this.voucherStatus = 2;
      this.modalTiTle = '失敗';
      this.modalMsg = '兌換失敗';
      this.isModalOpen = true;
    })
  }

  clickConfirm() {
    this.isModalOpen = false;
    location.reload();
  }

  callPagetagService() {
    this.pagetagService.callPageTag(this.router.url, '會員服務 | 優惠碼')
  }
}
