import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { LoginService } from './login.service';
import { Subject } from 'rxjs';
@Injectable()

export class HeaderService {
  Global = true;
  Footer = true;
  Active = true;
  Colorless =false;
  Order = true;
}
