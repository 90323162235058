<section class="brand-list" [hidden]="ListRes.brandList.length==0 ||  allSearch">
  <div class="brand-list__wrap" >
    <div class="brand-list__row">
      <div class="brand-list__col" *ngFor="let brand of ListRes.brandList">
        <div class="brand-list__item" [ngClass]="{'brand-list__item--active': brand.isChoose}">
          <a href="#" (click)="addOrRemoveBrand(brand,$event)">
            <img src="{{brand.img}}" alt="">
            <div class="brand-slider-mobile__name">{{brand.cName}}</div>
          </a>
        </div>
      </div>
    </div>
    <div class="js-brand-list__button-wrap brand-list__button-wrap">
      <button class="brand-list__button brand-list__button--expand"
        (click)="toggleBrandListHeight('expand',$event)">展開全部品牌</button>
    </div>
  </div>
</section>
<section class="search-bar-section">
  <div class="container" style="position: relative;">
    <!-- <div class="search-bar" *ngIf="!allSearch">
      <div class="search-bar__wrap">
        <input class="search-bar__input" type="text" (keyup.enter)="getProductList()" placeholder="商品關鍵字查詢"
          [(ngModel)]="ListReq.Keywords">
        <button class="search-bar__button" (click)="getProductList()">
          <img src="/assets/images/icon/magnifier_pink.svg" alt="">
        </button>
      </div>
    </div> -->
    <div class="js-search-condition search-condition" (click)="showSearchSection(false,$event)"
      [ngClass]="{'search-condition--show': isSearchSectionShow}">
      <div class="search-condition__wrap" [ngClass]="{'search-condition__wrap--show': isSearchSectionShow}">
        <div class="search-condition__content">
          <div class="search-condition__title-wrap">
            <h3 class="search-condition__title">
              <span class="search-condition__title-icon-wrap">
                <img src="/assets/images/icon/money-red_shadow.svg" alt="">
              </span>
              <span>
                金額級距搜尋
              </span>
            </h3>
          </div>
          <div class="search-condition__range-button-wrap-desktop-top">
            <button #searchConditionRangeButton class="search-condition__range-button" name="pointButton"
              (click)="clickPriceRange(0,200,$event)">200 元以下</button>
            <button #searchConditionRangeButton class="search-condition__range-button" name="pointButton"
              (click)="clickPriceRange(201,1000,$event)">201 - 1000 元</button>
            <button #searchConditionRangeButton class="search-condition__range-button" name="pointButton"
              (click)="clickPriceRange(1001,3000,$event)">1001 - 3000 元</button>
            <button #searchConditionRangeButton class="search-condition__range-button" name="pointButton"
              (click)="clickPriceRange(3001,5000,$event)">3001 - 5000 元</button>
            <button #searchConditionRangeButton class="search-condition__range-button" name="pointButton"
              (click)="clickPriceRange(5001,8000,$event)">5001 - 8000 元</button>
            <button #searchConditionRangeButton
              class="search-condition__range-button search-condition__range-button--last" name="pointButton"
              (click)="clickPriceRange(8001,10000,$event)">8001 - 10000 元</button>
          </div>
          <div class="search-condition__range-button-wrap-desktop-bottom">
            <button #searchConditionRangeButton class="search-condition__range-button" name="pointButton"
              (click)="clickPriceRange(10001,20000,$event)">10001 - 20000 元</button>
            <button #searchConditionRangeButton class="search-condition__range-button" name="pointButton"
              (click)="clickPriceRange(20001,30000,$event)">20001 - 30000 元</button>
            <input class="search-condition__range-input" type="number" placeholder="最小值" min="0"
              [(ngModel)]="ListReq.LowestPrice" (change)="setPointInput('L')">
            <span style="line-height: 40px;margin: 0px 5.62px;">-</span>
            <input class="search-condition__range-input" type="number" placeholder="最大值" min="0"
              [(ngModel)]="ListReq.HishestPrice" (change)="setPointInput('H')">
            <span style="line-height: 40px;margin: 0px 5.62px;">元</span>
            <button style="margin-left: auto;margin-right: 0px !important;"
              class="search-condition__search-button search-condition__search-button--search-desktop"
              (click)="setSearchCondition()">
              查詢
            </button>
          </div>
        </div>
        <div class="search-condition__range-button-wrap-mobile">
          <select #searchConditionRangeSelect (change)="handleRangeSelectChange($any($event).target.value)"
            class="search-condition__range-select">
            <option value="-1" selected disabled>請選擇金額範圍</option>
            <option value="0|200">200元以下</option>
            <option value="201|1000">201 - 1000 元</option>
            <option value="1001|3000">1001 - 3000 元</option>
            <option value="3001|5000">3001 - 5000 元</option>
            <option value="5001|8000">5001 - 8000 元</option>
            <option value="8001|10000">8001 - 10000 元</option>
            <option value="10001|20000">10001 - 20000 元</option>
            <option value="20001|30000">20001 - 30000 元</option>
          </select>
          <input style="width:calc(50% - 10px);margin-bottom: 10px;" class="search-condition__range-input" type="number"
            placeholder="最小值" min="0" [(ngModel)]="ListReq.LowestPrice" (change)="setPointInput('L')">
          <span style="line-height: 40px;margin: 0px 5.62px 10px 5.62px;">-</span>
          <input style="width:calc(50% - 10px);margin-bottom: 10px;" class="search-condition__range-input" type="number"
            placeholder="最大值" min="0" [(ngModel)]="ListReq.HishestPrice" (change)="setPointInput('H')">
          <button class="search-condition__search-button search-condition__search-button--reset"
            (click)="resetSearchCondition()">
            重置
          </button>
          <button class="search-condition__search-button search-condition__search-button--search-mobile"
            (click)="setSearchCondition()">
            查詢
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<section #brandTitle class="sort-section">
  <!-- <button class="sort-section__mobile-trigger-button" (click)="showSearchSection(true)">
    <img src="/assets/images/icon/search_bar_white.svg" alt="">
    篩選級距
  </button> -->
  <h2 class="sort-section__title">{{ListRes.cat1Name}}</h2>
  <div class="sort-section__sort-section" style>
    <span class="sort-section__sort-text" (click)="showSortSectionMobile()">排序</span>

    <button class="sort-section__desktop-trigger-button js-sort-direction" (click)="changeSortDirection($event)"
      (mouseover)="showSortSection('over')" (mouseout)="showSortSection('out')">
      <img class="js-sort-direction" [ngStyle]="{'transform' : !isSortASC? 'rotate(180deg)' : 'none'}"
        src="/assets/images/icon/arrow_top_white.svg" alt="">
      <span class="sort-section__link-wrap" [ngClass]="{'sort-section__link-wrap--show': isSortSectionShow}">
        <a class="sort-section__link" [ngClass]="{'sort-section__link--active':sort=='SaleDT'}"
          (click)="clickSortButton('SaleDT',$event)">
          依上架時間排序
        </a>
        <!-- <a class="sort-section__link" [ngClass]="{'sort-section__link--active':sort=='Price'}"
          (click)="clickSortButton('Price',$event)">
          依價格排序
        </a> -->
        <a class="sort-section__link" [ngClass]="{'sort-section__link--active':sort=='Price'}"
          (click)="clickSortButton('Price',$event)">
          依金額排序
        </a>
        <a class="sort-section__link" [ngClass]="{'sort-section__link--active':sort=='SellAmount'}"
          (click)="clickSortButton('SellAmount',$event)">
          依兌換數量排序
        </a>
      </span>
    </button>
  </div>
</section>
<section class="product-list" *ngIf="ListRes.products.length>0">
  <div class="product-list__row">
    <!-- each item -->
    <div class="product-list__col" *ngFor="let item of ListRes.products">
      <div class="product-list__item">
        <div *ngIf="item.label" class="product-list__labeltag" [ngStyle]="{color:item.label.color,backgroundColor: item.label.backGroundColor}">{{item.label.text}}</div>
        <div class="product-list__image-wrap">
          <div class="product-list__out-of-stock-mask" *ngIf="item.isSoldOut" routerLink="/product/detail/{{item.id}}">
            <div>
              <span>已售完</span>
            </div>
          </div>
          <a routerLink="/product/detail/{{item.id}}" class="product-list__product-image">
            <img class="main" src="{{item.img}}" alt="">
          </a>
          <!-- <span (click)="toggleToFavorite(item.id, item.isCollect)" class="product-list__favorite-icon">
            <img class="favorite"
              src="/assets/images/icon/{{item.isCollect?'favorite_heart_pink_solid_ring.svg':'favorite_heart_pink_opacity_ring.svg'}}"
              alt="">
          </span> -->
        </div>
        <div class="product-list__name-wrap">
          <a routerLink="/product/detail/{{item.id}}" class="product-list__name">
            {{item.name}}
          </a>
        </div>
        <div class="product-list__price-wrap">
          <div style="display: flex;">
            <p class="product-list__price product-list__price--money" >
              {{item.pP_Price}}元
            </p>
            <p class="product-list__price product-list__price--delete" *ngIf="item.discountPrice !== 0" >
              {{item.discountPrice}}元
            </p>
          </div>
          <!-- <div class="d-flex align-items-center">
            <p class="product-list__price product-list__price--point" *ngIf="item.pP_Point">
              {{item.pP_Point}}點</p>
            <span class="product-list__add-sign" *ngIf="item.pP_Point||item.pP_Point">＋</span>
            <p class="product-list__price product-list__price--money" *ngIf="item.pP_Point ||item.pP_Price ">
              {{item.pP_Price}}元</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <app-pagination *ngIf="ListRes.maxPage && ListRes.maxPage>1" [anchorElement]="brandTitle"
    [totalItems]="ListRes.maxPage*itemsPerPage" [itemsPerPageData]="itemsPerPageData" (SelectPage)="goToPage($event)">
  </app-pagination>
</section>

<section class="no-content" *ngIf="ListRes.products.length==0">
  <img class="no-content__image" src="/assets/images/icon/no_content.svg" alt="">
  <p class="no-content__text">找不到結果</p>
  <p class="no-content__text no-content__text--gray">嘗試不同或更常見的關鍵字</p>
</section>

<!-- modal -->
<app-modal [isModalOpen]="isModalOpen" (modalClose)="isModalOpen=$event;">
  <h4 headerContent class="general-modal__title">{{modalTiTle}}</h4>
  <div bodyContent class="general-modal__text-wrap">
    <p class="general-modal__text">{{modalMsg}}</p>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="isModalOpen=false;">確認</button>
  </div>
</app-modal>
