import { Injectable } from '@angular/core';
import { ChildActivationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MbrInfo } from '../model/member';
import { HttpService } from './http.service';
import { AES, mode, pad, enc } from 'crypto-js';
@Injectable()
export class LoginService {
  localKey = 'mbrkey';
  expireMin: number = environment.expireMin;
  encryptKey: string = environment.encryptKey;
  $token = new BehaviorSubject<string>('');
  constructor(private http: HttpService, private router: Router) {}

  Login(token: string): Observable<string> {
    return new Observable((subscriber) => {
      this.http
        .post('/Member/CheckMember', { token: token })
        .subscribe((x: any) => {
          if (x.code == 1) {
            let mbrInfo = new MbrInfo();
            mbrInfo.ID = x.Result;
            this.SetLocalInfo(mbrInfo);
            subscriber.next('success');
            subscriber.complete();
          } else {
            this.ClearLocalInfo();
            subscriber.next(x.result);
            subscriber.complete();
          }
        });
    });
  }

  KeepToken() {
    this.$token.subscribe((x) => {
      if (x) {
        sessionStorage.setItem('userToken', x);
      }
    });
  }
  CheckToken(token: string, empNo: string = ''): Observable<string> {
    return new Observable((subscriber) => {
      this.http
        .post('/HNAuth/GetUserInfo', { Token: token })
        .subscribe((x: any) => {
          if (x.code == 1) {
            let data = x.result;
            let mbrInfo = new MbrInfo();
            mbrInfo.IsLogin = true;
            mbrInfo.ID = data.custID;
            mbrInfo.Token = data.vPwd;
            mbrInfo.Bonus = data.bonus;
            mbrInfo.CardBonus = data.cardBonus;
            mbrInfo.ClearCardBonus = data.clearCardBonus;
            mbrInfo.ClearCardDate = data.clearCardDate;
            mbrInfo.ClearVBonus = data.clearVBonus;
            mbrInfo.ClearVBonusDate = data.clearVBonusDate;
            mbrInfo.Company = data.company;
            mbrInfo.ExpireDate = new Date(
              new Date().getTime() + this.expireMin * 60000
            );
            mbrInfo.EmpNo = empNo;
            mbrInfo.IsEmp = empNo != '';
            this.SetLocalInfo(mbrInfo);
            subscriber.next('success');
            subscriber.complete();
          } else {
            this.ClearLocalInfo();
            subscriber.next(x.result);
            subscriber.complete();
          }
        });
    });
  }
  CheckMember(token: string): Observable<boolean> {
    let arg = { Token: token };
    return new Observable((subscriber) => {
      this.http.post('/Member/GetUserConfirm', arg).subscribe((x: any) => {
        subscriber.next(x);
        subscriber.complete();
      });
    });
  }
  MemberConfirm(token: string): Observable<boolean> {
    return new Observable((subscriber) => {
      this.http
        .authPost('/Member/MemberConfirm', { Token: token })
        .subscribe((x: any) => {
          subscriber.next(x);
          subscriber.complete();
        });
    });
  }
  ResetToken(token: string): Observable<string> {
    return new Observable((subscriber) => {
      this.http.get('/HNAuth/ResetVPwd?token=' + token).subscribe((x: any) => {
        if (x.code == 1) {
          let data = x.result;
          let mbrInfo = this.GetLocalInfo();
          mbrInfo.Token = data.VPwd;
          mbrInfo.ExpireDate = new Date(
            new Date().getTime() + this.expireMin * 59000
          );
          this.SetLocalInfo(mbrInfo);
          subscriber.next('success');
          subscriber.complete();
        } else {
          subscriber.next(x.result);
          subscriber.complete();
        }
      });
    });
  }
  Logout() {
    this.ClearLocalInfo();
    location.href = '/';
  }
  GetLocalInfo() {
    let model = new MbrInfo();
    let token = sessionStorage.getItem('jwtToken');
    let id = sessionStorage.getItem('userToken');
    if (token != null && token.length > 0) {
      model.ID = id||"";
      model.Token = token;
    }

    // if (document.cookie.indexOf('IsLogin') == -1) {
    //   this.ClearLocalInfo();
    //   return model;
    // }
    // let info = localStorage.getItem(this.localKey) ?? '';
    // // let info = sessionStorage.getItem(this.localKey) ?? "";
    // if (info != '') {
    //   let decrypt = this.encryptByDeAES(info);
    //   model = JSON.parse(decrypt);
    // }
    return model;
  }
  SetLocalInfo(info: MbrInfo) {
    localStorage.setItem(
      this.localKey,
      this.encryptByEnAES(JSON.stringify(info))
    );
    this.SetCookie('IsLogin', 'true');
  }
  ClearLocalInfo() {
    localStorage.removeItem(this.localKey);
    // sessionStorage.removeItem(this.localKey);
    this.ClearCookie();
  }
  ResetBonus(mbrID: string): Observable<string> {
    return new Observable((subscriber) => {
      this.http.get('/HNAuth/ResetBonus?MbrID=' + mbrID).subscribe((x: any) => {
        var info = this.GetLocalInfo();
        info.CardBonus = x.cardBonus;
        info.Bonus = x.bonus;
        this.SetLocalInfo(info);
        subscriber.next('success');
        subscriber.complete();
      });
    });
  }
  SetCookie(name: string, value: string) {
    document.cookie = name + '=' + value;
  }
  ClearCookie() {
    document.cookie = '';
  }
  //aes加密
  encryptByEnAES(data: string): string {
    let tmpAES = AES.encrypt(data, this.encryptKey, {
      mode: mode.CBC,
      padding: pad.Pkcs7,
    });
    return tmpAES.toString();
  }
  encryptByDeAES(data: string): string {
    let tmpDeAES = AES.decrypt(data, this.encryptKey, {
      mode: mode.CBC,
      padding: pad.Pkcs7,
    });
    return tmpDeAES.toString(enc.Utf8);
  }
  //網銀登入
  CheckNetBankToken(token: string): Observable<string> {
    return new Observable((subscriber) => {
      this.http
        .post('/HNAuth/GetUserInfoWithNetBank', { Token: token })
        .subscribe((x: any) => {
          if (x.code == 1) {
            let data = x.result;
            let mbrInfo = new MbrInfo();
            mbrInfo.IsLogin = true;
            mbrInfo.ID = data.custID;
            mbrInfo.Token = data.vPwd;
            mbrInfo.Bonus = data.bonus;
            mbrInfo.CardBonus = data.cardBonus;
            mbrInfo.ClearCardBonus = data.clearCardBonus;
            mbrInfo.ClearCardDate = data.clearCardDate;
            mbrInfo.ClearVBonus = data.clearVBonus;
            mbrInfo.ClearVBonusDate = data.clearVBonusDate;
            mbrInfo.Company = data.company;
            mbrInfo.ExpireDate = new Date(
              new Date().getTime() + this.expireMin * 60000
            );
            this.SetLocalInfo(mbrInfo);
            subscriber.next('success');
            subscriber.complete();
          } else {
            this.ClearLocalInfo();
            subscriber.next(x.result);
            subscriber.complete();
          }
        });
    });
  }
}
