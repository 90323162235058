import { Injectable } from "@angular/core";
import { ScriptService } from "./script.service";
declare const CallPageTag: Function;

@Injectable()
export class PagetagService {
    constructor(private scriptService: ScriptService) { }

    callPageTag(url: string, data: string) {
        // this.scriptService.loadScript('pagetag').then(res => {
        //     try {
        //         CallPageTag(url, data);
        //     } catch (err) {
        //         console.error('CallPageTag error:', err);
        //     }
        // });
    }
}
