import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';

@Component({
  selector: 'app-home-register',
  templateUrl: './home-register.component.html',
  styleUrls: ['./home-register.component.scss']
})
export class HomeRegisterComponent implements OnInit {
  registerStep: number = 0;
  registerType: number = 1;
  isResolved = false;
  token: string | undefined;
  id: string;
  //msgModal
  isMsgModalOpen: boolean = false;
  Msg: string = "";
  constructor(
    private httpService: HttpService,
    private loaderService: LoaderService,
    private pagetagService: PagetagService,
    private router: Router
  ) {
    this.token = undefined;
  }

  ngOnInit(): void {
    this.callPagetagService();
  }

  public send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }
    this.loaderService.start();

    this.httpService.post("/HNAuth/GetRegisterUrl", { ID: this.id, RecaptchaToken: this.token, RegisterType: this.registerType }).subscribe((x: any) => {
      if (x.code == 1) {
        location.href = x.result;
      }
      else {
        this.isMsgModalOpen = true;
        this.Msg = x.result;
        this.loaderService.stop();
      }
    });
  }
  Confirm() {
    this.isMsgModalOpen = false;
    this.isResolved = false;
  }

  callPagetagService(url = '', name = '') {
    if (url.length == 0 || name.length == 0) {
      this.pagetagService.callPageTag(this.router.url, '加入會員')
      return;
    }

    this.pagetagService.callPageTag(url, name)
  }
}
