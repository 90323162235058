export const environment = {
  production: true,
  apiUrl: "/webapi/api",

  uuPayUrl: "https://epgw.easycard.com.tw/uupay-api-merchant/",
  recaptcha: {
    siteKey: '6Lf6iCIjAAAAABRPzxf7TUbNZ7PMDWV9-ntA0lXv',
  },
  expireMin: 30,
  encryptKey: 'XjdasDSAdM',

  EasyWallet:"https://epkaw.easycard.com.tw/assets/javascripts/epapp.js",
  licenseId : "96ee7a0e-0ed0-4c77-bc33-35f7c67e35a7"


};
