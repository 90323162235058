import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/shared/service/breadcrumb.service';
import { HeaderService } from 'src/app/shared/service/header.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';

@Component({
  selector: 'app-member-cart-result',
  templateUrl: './member-cart-result.component.html',
  styleUrls: ['./member-cart-result.component.scss']
})
export class MemberCartResultComponent implements OnInit {
  //購物步驟
  state: number = 1;
  msg: string = "";
  constructor(private route: ActivatedRoute,
    private breadcrumbServie: BreadcrumbService,
    private pagetagService: PagetagService,
    private router: Router,
    public headerService: HeaderService,
  ) { }

  ngOnInit(): void {
    this.headerService.Global = false;
    this.headerService.Footer = false;
    this.route.queryParams.subscribe(params => {
      this.state = params["state"] ? +params["state"] : 1;
      this.msg = params["msg"] ? params["msg"] : "";

      this.breadcrumbServie.breadcrumbData.next([{ breadcrumb: this.state == 1 ? '交易成功' : '交易失敗', path: '' }])
      this.callPagetagService(`會員服務 | 購物車 | ${this.state == 1 ? '交易成功' : '交易失敗'}`);
    })
  }

  // ngOnDestroy(): void {
  //   this.headerService.Global = true;
  //   this.headerService.Footer = true;
  // }

  BackToCart() {
    location.href = '/member/cart';
  }

  callPagetagService(name: string) {
    this.pagetagService.callPageTag(this.router.url, name)
  }
}
