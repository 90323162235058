import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tab-navbar-modal',
  templateUrl: './tab-navbar-modal.component.html',
  styleUrls: ['./tab-navbar-modal.component.scss']
})
export class TabNavbarModalComponent implements OnInit {

  @Input() public pathPrefix: string;
  @Input() public pathObjList: any;
  // 接收從母傳入的isModalOpen property
  @Input() public isModalOpen: boolean;
  // 傳給母元件boolean value，作為關閉modal 的依據
  @Output() public modalClose = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onModalClose($event: any) {
    if ($event.target.classList.contains('js-close')) {
      this.modalClose.emit(false);
      return;
    }
  }
}
