import { ActivatedRoute, Router } from '@angular/router';
import { EventListRes } from './../../../shared/model/event';
import { LoaderService } from './../../../shared/service/loader.service';
import { HttpService } from './../../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { ItemsPerPageData } from 'src/app/shared/model/pagination';
import { EventListReq } from 'src/app/shared/model/event';
import { BreadcrumbService } from 'src/app/shared/service/breadcrumb.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {
  // for pagination
  itemsPerPageData: ItemsPerPageData = { 'min': 6, 'sm': 6, 'md': 6, 'lg': 6, 'xl': 6 };
  itemsPerPage: number = 6;
  totalDataCount: number = 0; // default

  ListReq: EventListReq = new EventListReq;
  ListRes: EventListRes = new EventListRes;
  constructor(private httpService: HttpService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private pagetagService: PagetagService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.ListReq.CType = this.route.snapshot.params['id'];
      this.ListReq.PageNum = 1;
      // to force pagination component call ngOnchange()
      this.itemsPerPageData = { ...this.itemsPerPageData }
      this.getEventList();
    });
  }

  getEventList() {
    this.loaderService.start();
    this.httpService.post("/tbl_ActivityNews/GetActivityList", this.ListReq).subscribe((res) => {
      this.ListRes = res;
      this.totalDataCount = res.allDataCount;

      let breadcrumbName = this.ListReq.CType == 21 ? '悠遊付產品與服務' : this.ListReq.CType == 13 ? '最新消息' : '集點秘笈';
      this.breadcrumbService.breadcrumbData.next([{ breadcrumb: breadcrumbName, path: '' }])
      this.callPagetagService(breadcrumbName);

      this.loaderService.stop();
    })
  }

  goToPage(page: number) {
    this.ListReq.PageNum = page;
    this.getEventList();
  }

  callPagetagService(name: string) {
    this.pagetagService.callPageTag(this.router.url, name)
  }
}
