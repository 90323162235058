<header class="main-header" [ngClass]="{'main-header--fixed': isHeaderFixed,'isSearch': isSearch,'main-header-colorless':headerService.Colorless}" *ngIf="headerService.Global || headerService.Colorless">
<!-- <header class="main-header-colorless" [ngClass]="{'main-header--fixed': isHeaderFixed,'isSearch': isSearch}" ></header> -->
  <div>
  <div class="container homeinput" style="position: relative;" >
      <!-- <div class="home" ><a class="homelink" [routerLink]="'/'"><img src="/assets/images/icon/gohome.svg"></a></div> -->
      <div class="search content-18">
        <a [routerLink]="'/'"><div class="home"><img src="/assets/images/icon/home_new.svg"></div></a>
        <input type="search" placeholder="請輸入商品關鍵字" [(ngModel)]="keyword">
        <a [routerLink]="'/search/'+keyword"><div><img src="/assets/images/icon/search-icon.svg" class="magnifier"></div></a>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-8">
        <input class="input-group  input-group__input" type="text" placeholder="全館商品搜索" [(ngModel)]="keyword">
      </div>
      <div class="col-2">
        <button class="input-group   general-modal__button" (click)="goSearch()">查詢</button>
      </div>
    </div>-->
  </div>
  <!-- <div class="container" style="position: relative;">
    <div class="d-flex">
      <div class="main-header__logo" [ngClass]="{'main-header__logo--fixed': isHeaderFixed}">
        <h1>
          <a [routerLink]="['/']" (click)="onRouteChange()">
            <img class="logo__img" src="/assets/images/logo/logo_black.svg" alt="悠遊付票券商城平台" height="70">
            <img class="logo__img logo__img--mobile" [src]="headerLogoUrl" alt="悠遊付票券商城平台"> -->
  <!-- <img class="logo__animation" src="/assets/images/logo/hn_goat.svg"> -->
  <!-- <object class="logo__animation" type="image/svg+xml" data="/assets/images/logo/hn_goat.svg"></object> -->
  <!-- </a>
        </h1>
      </div>
      <ng-container *ngIf="!isSystemShutdown">
        <div #mainMenuWrap class="main-header__main-menu"
          [ngClass]="{'main-header__main-menu--active': isBurgerActived}">
          <ul class="main-menu__list"> -->
  <!-- <li class="main-menu__item" [ngClass]="{'main-menu__item--fixed': isHeaderFixed}"
              (click)="onSubMenuCollapse($event, serviceSubMenuWrap, serviceSubMenuList)">
              <ng-container *ngIf="!isMobile">
                <a href="#/" [routerLink]="['/event', 21]" class="js-main-menu__link main-menu__link"
                  data-collapse-state="+">悠客券產品與服務
                  <span
                    class="js-main-menu__link-state-icon--expand main-menu__link-state-icon main-menu__link-state-icon--expand"></span>
                  <span
                    class="js-main-menu__link-state-icon--shrink main-menu__link-state-icon main-menu__link-state-icon--shrink"></span>
                </a>
              </ng-container>
              <ng-container *ngIf="isMobile">
                <a href="#/" class="js-main-menu__link main-menu__link" data-collapse-state="+">悠客券產品與服務
                  <span
                    class="js-main-menu__link-state-icon--expand main-menu__link-state-icon main-menu__link-state-icon--expand"></span>
                  <span
                    class="js-main-menu__link-state-icon--shrink main-menu__link-state-icon main-menu__link-state-icon--shrink"></span>
                </a>
              </ng-container>
              <div #serviceSubMenuWrap class="js-main-menu__sub-menu main-menu__sub-menu"
                [ngClass]="{'main-menu__sub-menu--fixed': isHeaderFixed}">
                <ul #serviceSubMenuList class="sub-menu__list">
                  <li *ngFor="let item of eventListResFor21.infos" class="sub-menu__item">
                    <a *ngIf="!item.cUrl" href="{{'/event/' + item.cType + '/' + item.id}}" class="sub-menu__link"
                      (click)="goToLink($event, true, '/event/' + item.cType + '/' + item.id, item.cAnother==1)">{{item.cName}}</a>
                    <a *ngIf="item.cUrl" href="{{item.cUrl}}" class="sub-menu__link"
                      (click)="goToLink($event, false, item.cUrl, item.cAnother==1)">{{item.cName}}</a>
                  </li>
                  <li *ngIf="eventListResFor21.infos.length > 0" class="sub-menu__item">
                    <a class="sub-menu__link sub-menu__link--last" [routerLink]="['/event', '21']">看更多</a>
                  </li>
                </ul>
              </div>
            </li> -->
  <!-- <li class="main-menu__item" [ngClass]="{'main-menu__item--fixed': isHeaderFixed}"
              (click)="onSubMenuCollapse($event, productSubMenuWrap, productSubMenuList)">
              <ng-container *ngIf="!isMobile">
                <a href="#/" routerLink="/product/overview" class="js-main-menu__link main-menu__link">品牌館
                  <span
                    class="js-main-menu__link-state-icon--expand main-menu__link-state-icon main-menu__link-state-icon--expand"></span>
                  <span
                    class="js-main-menu__link-state-icon--shrink main-menu__link-state-icon main-menu__link-state-icon--shrink"></span></a>
              </ng-container>
              <ng-container *ngIf="isMobile">
                <a href="#/" class="js-main-menu__link main-menu__link">品牌館
                  <span
                    class="js-main-menu__link-state-icon--expand main-menu__link-state-icon main-menu__link-state-icon--expand"></span>
                  <span
                    class="js-main-menu__link-state-icon--shrink main-menu__link-state-icon main-menu__link-state-icon--shrink"></span></a>
              </ng-container>
              <div #productSubMenuWrap class="js-main-menu__sub-menu main-menu__sub-menu main-menu__sub-menu--category"
                [ngClass]="{'main-menu__sub-menu--fixed': isHeaderFixed}"> -->
  <!-- 依據數量，調整容器寬度 -->
  <!-- <ul #productSubMenuList class="sub-menu__list sub-menu__list--category" [ngClass]="{
                                'sub-menu__list--category-1col':brandList.length<=3,
                                'sub-menu__list--category-2col':brandList.length>3&&brandList.length<=6,
                                'sub-menu__list--category-3col':brandList.length>6
                            }">
                  <li *ngFor="let item of brandList;let isLast = last;" class="sub-menu__item sub-menu__item--category"
                    [ngClass]="{
                                        'sub-menu__item--category-1col':brandList.length<=3,
                                        'sub-menu__item--category-2col':brandList.length>3&&brandList.length<=6,
                                        'sub-menu__item--category-3col':brandList.length>6
                                    }">
                    <a [routerLink]="['/product','list',item.id]" class="sub-menu__link"
                      [ngClass]="{'sub-menu__link--last': isLast}" (click)="onRouteChange()">{{item.name}}</a>
                  </li>
                </ul>
              </div>
            </li> -->
  <!-- <li class="main-menu__item" [ngClass]="{'main-menu__item--fixed': isHeaderFixed}"
              (click)="onSubMenuCollapse($event, eventSubMenuWrap, eventSubMenuList)">
              <ng-container *ngIf="!isMobile">
                <a href="#/" [routerLink]="['/event', 13]" class="js-main-menu__link main-menu__link">最新消息
                  <span
                    class="js-main-menu__link-state-icon--expand main-menu__link-state-icon main-menu__link-state-icon--expand"></span>
                  <span
                    class="js-main-menu__link-state-icon--shrink main-menu__link-state-icon main-menu__link-state-icon--shrink"></span>
                </a>
              </ng-container> -->
  <!--  -->
  <!-- <ng-container *ngIf="isMobile">
                <a href="#/" class="js-main-menu__link main-menu__link">最新消息
                  <span
                    class="js-main-menu__link-state-icon--expand main-menu__link-state-icon main-menu__link-state-icon--expand"></span>
                  <span
                    class="js-main-menu__link-state-icon--shrink main-menu__link-state-icon main-menu__link-state-icon--shrink"></span>
                </a>
              </ng-container>
              <div #eventSubMenuWrap class="js-main-menu__sub-menu main-menu__sub-menu"
                [ngClass]="{'main-menu__sub-menu--fixed': isHeaderFixed}">
                <ul #eventSubMenuList class="sub-menu__list">
                  <li *ngFor="let item of eventListResFor13.infos" class="sub-menu__item">
                    <a *ngIf="!item.cUrl" class="sub-menu__link" href="{{'/event/' + item.cType + '/' + item.id}}"
                      (click)="goToLink($event, true, '/event/' + item.cType + '/' + item.id, item.cAnother==1)">{{item.cName}}</a>
                    <a *ngIf="item.cUrl" href="{{item.cUrl}}" class="sub-menu__link"
                      (click)="goToLink($event, false, item.cUrl, item.cAnother==1)">{{item.cName}}</a>
                  </li>
                  <li *ngIf="eventListResFor13.infos.length > 0" class="sub-menu__item">
                    <a class="sub-menu__link sub-menu__link--last" [routerLink]="['/event', '13']">看更多</a>
                  </li>
                </ul>
              </div>
            </li> -->
  <!-- <li>
              <ng-container *ngIf="!isMobile">
                <a href="#/" routerLink="/member/order" class="js-main-menu__link main-menu__link">訂單查詢
                  <span
                    class="js-main-menu__link-state-icon--expand main-menu__link-state-icon main-menu__link-state-icon--expand"></span>
                  <span
                    class="js-main-menu__link-state-icon--shrink main-menu__link-state-icon main-menu__link-state-icon--shrink"></span></a>
              </ng-container>
              <ng-container *ngIf="isMobile">
                <a href="#/" routerLink="/member/order" class="js-main-menu__link main-menu__link"
                  (click)="onRouteChange()">訂單查詢
                  <span
                    class="js-main-menu__link-state-icon--expand main-menu__link-state-icon main-menu__link-state-icon--expand"></span>
                  <span
                    class="js-main-menu__link-state-icon--shrink main-menu__link-state-icon main-menu__link-state-icon--shrink"></span></a>
              </ng-container>
            </li>
            <li class="main-menu__item" [ngClass]="{'main-menu__item--fixed': isHeaderFixed}">
              <ng-container *ngIf="!isMobile">
                <a href="#/" [routerLink]="['/question/contact']" class="js-main-menu__link main-menu__link">聯絡我們 <span
                    class="js-main-menu__link-state-icon--expand main-menu__link-state-icon main-menu__link-state-icon--expand"></span>
                  <span
                    class="js-main-menu__link-state-icon--shrink main-menu__link-state-icon main-menu__link-state-icon--shrink"></span></a>
              </ng-container>
              <ng-container *ngIf="isMobile">
                <a href="#/" [routerLink]="['/question/contact']" class="js-main-menu__link main-menu__link"
                  (click)="onRouteChange()">聯絡我們 <span
                    class="js-main-menu__link-state-icon--expand main-menu__link-state-icon main-menu__link-state-icon--expand"></span>
                  <span
                    class="js-main-menu__link-state-icon--shrink main-menu__link-state-icon main-menu__link-state-icon--shrink"></span></a>
              </ng-container> -->
  <!-- <div #questionSubMenuWrap class="js-main-menu__sub-menu main-menu__sub-menu"
                [ngClass]="{'main-menu__sub-menu--fixed': isHeaderFixed}">
                <ul #questionSubMenuList class="sub-menu__list">
                  <li *ngFor="let item of questionTypeList" class="sub-menu__item">
                    <a [routerLink]="['/question',item.id]" href="#" class="sub-menu__link"
                      (click)="onRouteChange()">{{item.name}}</a>
                  </li>
                  <li class="sub-menu__item">
                    <a [routerLink]="['/question','contact']" href="#" class="sub-menu__link sub-menu__link--last"
                      (click)="onRouteChange()">聯絡我們</a>
                  </li>
                </ul>
              </div> -->
  <!-- </li> -->
  <!-- 會員登入時顯示 *ngIf -->
  <!-- <li *ngIf="this.userInfo.IsLogin" class="main-menu__item main-menu__item--member-info"
              [ngClass]="{'main-menu__item--fixed': isHeaderFixed}"> -->
  <!--[routerLink]="['/member','point']" -->
  <!-- <div class="member-info__point">
                <p *ngIf="!this.userInfo.IsEmp">Hi! 您好</p>
                <p *ngIf="this.userInfo.IsEmp">專員{{this.userInfo.EmpNo}}您好</p> -->
  <!-- <span>
                  <img src="/assets/images/icon/money-red.svg" alt="">
                  <span>{{addThouthandMark(this.userInfo.Bonus + +this.userInfo.CardBonus)}}</span>
                </span> -->
  <!-- </div>
              <div class="member-info__logout">
                <a (click)="logout()">
                  登出
                </a>
              </div>
            </li> -->
  <!-- 會員登入時顯示 *ngIf -->
  <!-- <li *ngIf="this.userInfo.IsLogin" class="main-menu__item main-menu__item--member-service"
              [ngClass]="{'main-menu__item--fixed': isHeaderFixed}"
              (click)="onSubMenuCollapse($event, memberSubMenuWrap, memberSubMenuList)">
              <a href="#/" class="js-main-menu__link main-menu__link main-menu__link--member-service">
                <span class="member-service__icon">
                  <img src="/assets/images/icon/member_icon_white.svg" alt="">
                </span>
                <span class="js-main-menu__text member-service__text">會員專區
                  <span
                    class="js-main-menu__link-state-icon--expand main-menu__link-state-icon main-menu__link-state-icon--expand"></span>
                  <span
                    class="js-main-menu__link-state-icon--shrink main-menu__link-state-icon main-menu__link-state-icon--shrink"></span>
                </span>
              </a>
              <div #memberSubMenuWrap class="js-main-menu__sub-menu main-menu__sub-menu"
                [ngClass]="{'main-menu__sub-menu--fixed': isHeaderFixed}">
                <ul #memberSubMenuList class="sub-menu__list"> -->
  <!-- <li class="sub-menu__item menu-item sub">
                                        <a [routerLink]="['/member','cart']" class="sub-menu__link"
                                            (click)="onRouteChange()">購物車</a>
                                    </li> -->
  <!-- <li class="sub-menu__item menu-item sub">
                    <a [routerLink]="['/member','order']" class="sub-menu__link" (click)="onRouteChange()">訂單查詢</a>
                  </li> -->
  <!-- <li class="sub-menu__item menu-item sub">
                    <a [routerLink]="['/member','point']" class="sub-menu__link" (click)="onRouteChange()">會員點數</a>
                  </li> -->
  <!-- <li class="sub-menu__item menu-item sub">
                    <a [routerLink]="['/member','ticket']" class="sub-menu__link" (click)="onRouteChange()">會員票夾</a>
                  </li> -->
  <!-- <li class="sub-menu__item menu-item sub">
                    <a [routerLink]="['/member','favorite']" class="sub-menu__link" (click)="onRouteChange()">我的收藏</a>
                  </li> -->
  <!-- <li class="sub-menu__item menu-item sub">
                    <a [routerLink]="['/member','voucher']" class="sub-menu__link sub-menu__link--last"
                      (click)="onRouteChange()">優惠碼</a>
                  </li>
                  <li class="sub-menu__item menu-item sub sub-menu__item--logout">
                    <a (click)="logout()" class="sub-menu__link" (click)="onRouteChange()">登出</a>
                  </li>
                </ul>
              </div>
            </li> -->
  <!-- 會員未登入時顯示 *ngIf -->
  <!-- <li *ngIf="!this.userInfo.IsLogin" class="main-menu__item main-menu__item--member-login"
              [ngClass]="{'main-menu__item--fixed': isHeaderFixed} ">
              <div>
                 <a [routerLink]="['/register']" (click)="onRouteChange()">
                  加入會員
                </a>
                <div>&nbsp;</div>
                <a [routerLink]="['/login']" (click)="onRouteChange()">
                  登入
                  <span><img src="/assets/images/icon/arrow_right_white.svg " alt=" "></span>
                </a>
              </div>
            </li> -->
  <!-- </ul>
        </div> -->
  <!-- 會員登入時顯示 *ngIf -->
  <!-- <div *ngIf="this.userInfo.IsLogin" class="main-header__member-info-mobile"> -->
  <!-- [routerLink]="['/member','point']" -->
  <!-- <p>Hi! 您好</p> -->
  <!-- <span>
            <img src="/assets/images/icon/money-red.svg " alt=" ">
            <span>{{addThouthandMark(this.userInfo.Bonus+this.userInfo.CardBonus)}}</span>
          </span> -->
  <!-- </div>
        <div *ngIf="this.userInfo.IsLogin" class="main-header__icon-wrap"> -->
  <!-- <a [routerLink]="['/member','cart']" (click)="onRouteChange()"
                        class="icon-wrap__link icon-wrap__link--cart ">
                        <span class="icon-wrap__cart-number" *ngIf="cartNumber>0">{{cartNumber}}</span>
                        <img class="icon-wrap__image icon-wrap__image--cart"
                            src="/assets/images/icon/shopping_cart_white.svg" alt="">
                    </a> -->
  <!-- <a href="#/ " id="burger " class="icon-wrap__link icon-wrap__link--burger"
            (click)="onBurgerIconClick($event) ">
            <img class="icon-wrap__image" [src]="burgerIconUrl " alt=" ">
          </a>
        </div> -->
  <!-- 會員未登入時顯示 *ngIf -->
  <!-- <div *ngIf="!this.userInfo.IsLogin" class="main-header__icon-wrap"> -->
  <!-- <a [routerLink]="['/login']" (click)="onRouteChange()"
                        class="icon-wrap__link icon-wrap__link--cart ">
                        <img class="icon-wrap__image icon-wrap__image--cart"
                            src="/assets/images/icon/shopping_cart_white.svg" alt="">
                    </a> -->
  <!-- <a href="#/ " id="burger " class="icon-wrap__link icon-wrap__link--burger"
            (click)="onBurgerIconClick($event) ">
            <img class="icon-wrap__image" [src]="burgerIconUrl " alt=" ">
          </a>
        </div>
      </ng-container> -->
  <!-- </div> -->
  <!-- <app-header-info *ngIf="collectionTarget" [isInfoShow]="isInfoShow" [isHeaderFixed]="isHeaderFixed"
      (infoClose)="isInfoShow=$event" [collectionTarget]="collectionTarget">
    </app-header-info>
  </div> -->
  <div class="main-header__overlay" [ngClass]="{'main-header__overlay--show': isBurgerActived}"></div>
</header>
