<div class="orderheader ">
  <h2 class="title" style="font-size: 22px;">訂單內容</h2>
</div>
<h2 class="order-title">您的訂單紀錄</h2>
<!-- <div class="order-back-to-list order-back-to-list--mobile">
  <a [routerLink]="['/member', 'order']" [queryParams]="{orderMainNo: orderMainNo}" style="background-color: transparent;">
    <img src="/assets/images/icon/arrow_left_black.svg" alt=""> 回訂單列表
  </a>
</div> -->
<section class="order-content">
  <!-- <div class="order-back-to-list order-back-to-list--desktop">
    <a [routerLink]="['/member','order']" [queryParams]="{orderMainNo: orderMainNo}">
      <img src="/assets/images/icon/arrow_left_black.svg" alt=""> 回訂單列表
    </a>
  </div> -->
  <div class="process-step" [ngClass]="{'process-step--cancel': detailInfo.itemStatus==4}">
    <div class="process-step__item process-step__item--completed"
      [ngClass]="{'process-step__item--completed-last':detailInfo.itemStatus==0 || detailInfo.itemStatus==4,'process-step__item--completed-cancel':detailInfo.itemStatus==4}">
      <span class="process-step__text-wrap">
        <span class="process-step__title">收到訂單</span>
        <!-- if has date data -->
        <span class="process-step__date" *ngIf="detailInfo.orderDT">{{detailInfo.orderDT| date:
          'yyyy/MM/dd'}}</span>
      </span>
    </div>
    <div class="process-step__item" *ngIf="detailInfo.itemStatus!=4"
      [ngClass]="{'process-step__item--completed':detailInfo.itemStatus>=1,'process-step__item--completed-last':detailInfo.itemStatus==1}">
      <span class="process-step__text-wrap">
        <span class="process-step__title">訂單處理中</span>
      </span>
    </div>
    <!-- 宅配用 -->
    <!-- <div class="process-step__item" *ngIf="detailInfo.itemStatus!=4"
      [ngClass]="{'process-step__item--completed':detailInfo.itemStatus>=2,'process-step__item--completed-last':detailInfo.itemStatus==2}">
      <span class="process-step__text-wrap">
        <span class="process-step__title">已出貨</span>
        <span class="process-step__date" *ngIf="detailInfo.shippingDT">{{detailInfo.shippingDT| date:
          'yyyy/MM/dd'}}</span>
      </span>
    </div> -->
    <!-- <div class="process-step__item process-step__item--last" *ngIf="detailInfo.itemStatus!=4"
      [ngClass]="{'process-step__item--completed':detailInfo.itemStatus>=3,'process-step__item--completed-last':detailInfo.itemStatus>=3}">
      <span class="process-step__text-wrap">
        <span class="process-step__title">{{deliveryFinalStepName}}</span>
        <span class="process-step__date" *ngIf="detailInfo.completedDT">{{detailInfo.completedDT| date:
          'yyyy/MM/dd'}}</span>
      </span>
    </div> -->
    <div *ngIf="detailInfo.itemStatus!=4">
      <section class="process-step">
        <div class="process-step__item process-step__item--completed process-step__item--completed-last">
          <span class="process-step__text">
            1 確認訂單
          </span>
          <div class="line"></div>
        </div>
        <div class="process-step__item-gray process-step__item--completed process-step__item--completed-last">
          <span class="process-step__text-gray">
            2 交易成功
          </span>
        </div>
      </section>
    </div>
    <div
      class="process-step__item process-step__item--last process-step__item--completed process-step__item--completed-last"
      *ngIf="detailInfo.itemStatus==4">
      <span class="process-step__text-wrap">
        <span class="process-step__title">訂單取消</span>
      </span>
    </div>
  </div>
  <!--訂單檢視/我的票夾-->
  <div class="main-function">
    <!-- <div><a [routerLink]="['/member/order']"><img src="assets/images/icon/order-view-icon.svg">檢視訂單</a></div>
    <div><a href="#" (click)="goTicketWallet()"><img src="assets/images/icon/my-wallet-icon.svg">我的票夾</a></div> -->
  </div>
  <div class="list-section-wrap">
    <div class="list-section">
      <h3 class="list-section__title">訂單明細</h3>
      <ul class="order-detail-list">
        <li class="order-detail-list__item order-detail-list__item--first-row">
          <span class="order-detail-list__cell order-detail-list__cell--first-row"></span>
          <span class="order-detail-list__cell order-detail-list__cell--first-row">商品資訊/商品類型/訂單編號</span>
          <span class="order-detail-list__cell order-detail-list__cell--first-row">兌換點數/金額</span>
          <!-- <span class="order-detail-list__cell order-detail-list__cell--first-row">功能</span> -->
          <span class="order-detail-list__cell order-detail-list__cell--first-row">付款狀態</span>
          <!-- <span class="order-detail-list__cell order-detail-list__cell--first-row">點數狀態</span> -->
          <span class="order-detail-list__cell order-detail-list__cell--first-row">訂單狀態</span>
          <span class="order-detail-list__cell order-detail-list__cell--first-row">票券序號</span>
        </li>
        <li class="order-detail-list__item ">
          <!-- nth-child(1) only show in mobile width -->
          <span class="order-detail-list__cell" [attr.data-category]="detailInfo.details[0].productType">
            <span>
              {{detailInfo.details[0].orderNo}}
            </span>
          </span>
          <span class="order-detail-list__cell">
            <img class="order-detail-list__image" src="{{detailInfo.details[0].img}}" alt="">
            <span class="order-detail-list__info">
              <span class="order-detail-list__info-text">{{detailInfo.details[0].productType}}</span>
              <span class="order-detail-list__info-text">{{detailInfo.details[0].name}}</span>
              <span class="order-detail-list__info-text">{{detailInfo.details[0].orderNo}}</span>
              <span class="order-detail-list__info-text__expiration">商品兌換期</span>
              <span class="order-detail-list__info-text__expiration">{{detailInfo.details[0].expiration}}</span>
            </span>
          </span>
          <span class="order-detail-list__cell" data-attr="購買數量">
            <span>{{detailInfo.details.length}}</span>
          </span>
          <span class="order-detail-list__cell" data-attr="購買金額">
            <span *ngIf="detailInfo.details[0].payType==2">
              <!-- <span>悠遊付</span> -->
              <!-- <span>{{detailInfo.priceType==1||detailInfo.priceType==2?"信用卡":"金融卡"}}</span> -->
              <span class="point-dollar-number point-dollar-number--bold">
                <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
                <span class="point-dollar-number__text">{{detailInfo.details[0].prices*detailInfo.details.length}}元</span>
              </span>
            </span>
            <span class="order-detail-list__transaction-info" *ngIf="detailInfo.details[0].vBonus!=0">
              <span>悠遊付點數</span>
              <span class="point-dollar-number point-dollar-number--bold">
                <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
                <span class="point-dollar-number__text">{{detailInfo.details[0].vBonus}}點</span>
              </span>
            </span>
            <span class="order-detail-list__transaction-info" *ngIf="detailInfo.details[0].cardBonus!=0">
              <span>悠客券平台</span>
              <span class="point-dollar-number point-dollar-number--bold">
                <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
                <span class="point-dollar-number__text">{{detailInfo.details[0].cardBonus}}點</span>
              </span>
            </span>
          </span>
          <!-- <span class="order-detail-list__cell" [attr.data-category]="item.productType">
                        <span>
                            <button class="order-detail-list__button"
                                routerLink="/question/contact/{{item.orderNo}}">聯繫客服</button>
                            <button class="order-detail-list__button order-detail-list__button--last"
                                *ngIf="item.cDeliveryStatus==3 && item.canReturn && !userInfo.IsEmp"
                                (click)="openReturnProductModal(item.sn)">退貨申請</button>
                        </span>
                    </span> -->
          <!-- <span class="order-detail-list__cell" data-attr="付款狀態">
            <span>{{detailInfo.details[0].goldFlowStatus}}</span>
          </span> -->
          <!-- <span class="order-detail-list__cell" data-attr="點數狀態">
            <span>{{item.pointStatus}}</span>
          </span> -->
          <!-- <span class="order-detail-list__cell" data-attr="訂單狀態">
            <span>{{detailInfo.details[0].orderStatusName}}</span>
          </span> -->
          <!-- <span class="order-detail-list__cell" data-attr="票券序號">
            <span>{{item.sn}}</span>
          </span>
          <span class="order-detail-list__cell" data-attr="票券效期">
            <span>{{item.useLimitDate | date:'yyyy/MM/dd'}}</span>
          </span> -->
        </li>
      </ul>
    </div>
  </div>
  <div class="info-section-wrap">
    <div class="order-info-section" [ngClass]="{'order-info-section--full': !detailInfo.isHasDelivery}">
      <h3 class="order-info-section__title">訂單資訊</h3>
      <div class="order-info-section__info-wrap">
        <h6 class="order-info-section__sub-title">訂購日期</h6>
        <p class="order-info-section__text">{{detailInfo.orderDT}}</p>
      </div>
     <!--  <div class="order-info-section__info-wrap">
        <h6 [ngClass]="{'order-info-section__sub-title--last': detailInfo.details[0].payType!=2}"
          class="order-info-section__sub-title">訂單狀態</h6>
        <p [ngClass]="{'order-info-section__text--last': detailInfo.details[0].payType!=2}"
          class="order-info-section__text">
          {{itemStatusName}}</p>
      </div> -->
      <div class="order-info-section__info-wrap" *ngIf="detailInfo.details[0].payType==2">
        <h6 class="order-info-section__sub-title">發票類型</h6>
        <p class="order-info-section__text">{{detailInfo.invoiceType}}</p>
      </div>
      <div class="order-info-section__receipt-note-wrap" *ngIf="detailInfo.details[0]?.payType==2&&detailInfo.invoiceType=='電子信箱'">
        <p class="order-info-section__receipt-note">
          <span class="note__icon-wrap">
            <img class="note__icon" src="/assets/images/icon/shock_mark_white.svg" alt="">
          </span>
          <a target="_blank" href="https://giftshop.systex.com/"
            class="order-info-section__receipt-link">您可透過電子郵件中的帳號密碼查詢您的發票資訊。</a>
        </p>
      </div>
      <!-- <span class="order-detail-list__cell" data-attr="票券效期">
        <span>{{item.useLimitDate | date:'yyyy/MM/dd'}}</span>
      </span> -->
      <!-- <span class="order-detail-list__cell" data-attr="票券序號" >
        <span>{{item.sn}}</span>
      </span> -->
      <div class="order-info-section__info-wrap">
        <h6 [ngClass]="{'order-info-section__sub-title--last': detailInfo.details[0].payType!=2}"
          class="order-info-section__sub-title">票券效期</h6>
        <p [ngClass]="{'order-info-section__text--last': detailInfo.details[0].payType!=2}"
          class="order-info-section__text">
          {{detailInfo.details[0].useLimitDate| date:'yyyy/MM/dd HH:mm'}}</p>
      </div>
      <ng-container>
        <div class="order-info-section__info-wrap" *ngFor="let item of detailInfo.details ;let i =index">

            <h6 [ngClass]="{'order-info-section__sub-title--last': detailInfo.details[0].payType!=2}"
              class="order-info-section__sub-title" *ngIf="i==0">票券序號</h6>

          <div style="height: auto;min-width: 100px;" *ngIf="i!==0 && i<5"></div>
          <p class="order-info-section__text" *ngIf="i<5">{{item.sn}} {{ item.orderStatusName }}</p>
          <div style="height: auto;min-width: 100px;" *ngIf="TicktOpen ==true && i>=5"></div>
          <p class="order-info-section__text" *ngIf="TicktOpen ==true && i>=5">{{item.sn}} {{ item.orderStatusName }}</p>
        </div>
        <div class="order-info-section__info-wrap" *ngIf="detailInfo.details.length>5&& TicktOpen==false;">
          <div style="height: auto;min-width: 100px;"></div><a class="bring-Information" (click)="TicktOpen=true;">查看更多</a>
        </div>
        <div class="order-info-section__info-wrap" *ngIf="detailInfo.details.length>5&& TicktOpen==true;">
          <div style="height: auto;min-width: 100px;"></div><a class="bring-Information" (click)="TicktOpen=false;">收合</a>
        </div>
      </ng-container>
    </div>
    <div class="delivery-info-section" *ngIf="detailInfo.isHasDelivery">
      <h3 class="delivery-info-section__title">宅配資訊</h3>
      <div class="delivery-info-section__info-wrap">
        <h6 class="delivery-info-section__sub-title">收件者姓名</h6>
        <p class="delivery-info-section__text">{{detailInfo.recipent}}</p>
      </div>
      <div class="delivery-info-section__info-wrap">
        <h6 class="delivery-info-section__sub-title">收件者電話</h6>
        <p class="delivery-info-section__text">{{detailInfo.tel}}</p>
      </div>
      <div class="delivery-info-section__info-wrap">
        <h6 class="delivery-info-section__sub-title">宅配地址</h6>
        <p class="delivery-info-section__text">{{detailInfo.address}}</p>
      </div>
      <div class="delivery-info-section__info-wrap" *ngIf="detailInfo.deliveryNo">
        <h6 class="delivery-info-section__sub-title delivery-info-section__sub-title--last">貨運單號</h6>
        <p class="delivery-info-section__text delivery-info-section__text--last">
          {{detailInfo.deliveryNo}}
          <a href="{{detailInfo.deliveryLink}}" target="_blank">(連結)</a>
        </p>
      </div>
    </div>
  </div>
  <!-- <div class="summary-section-wrap">
    <div class="summary-section">
      <h3 class="summary-section__title">訂單資訊</h3>
      <h4 class="summary-section__sub-title summary-section__sub-title--regular">支付明細</h4>
      <div class="summary-section__text-outer-wrap">
        <div class="summary-section__text-inner-wrap" *ngIf="detailInfo.priceType!=0">
          <span>{{detailInfo.priceType==1||detailInfo.priceType==2?"信用卡":"金融卡"}}</span>
          <span>悠遊付</span>
          <span class="point-dollar-number point-dollar-number--bold">
            <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
            <span class="point-dollar-number__text">{{detailInfo.allPrice}}元</span>
          </span>
        </div> -->
  <!-- <div class="summary-section__text-inner-wrap">
          <span>悠遊付點數</span>
          <span class="point-dollar-number point-dollar-number--bold">
            <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
            <span class="point-dollar-number__text">{{detailInfo.allVBonus}}點</span>
          </span>
        </div> -->
  <!-- <div class="summary-section__text-inner-wrap summary-section__text-inner-wrap--last">
          <span>悠遊付點數兌換平台</span>
          <span class="point-dollar-number point-dollar-number--bold">
            <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
            <span class="point-dollar-number__text">{{detailInfo.allCardBonus}}點</span>
          </span>
        </div> -->
  <!-- </div>
    </div>
  </div> -->
</section>

<!-- 推薦商品 -->
<article class="mobile-product-list">
  <h4 style="margin-top: 15px;color: #CD2382;font-size: 20px;;margin-left:5px;">推薦商品</h4>
  <div class="recommended-brand__brand-slider-mobile-text" *ngIf="product?.recommendProducts">
    <div class="brand-slider-mobile__row-wrap">
      <swiper [spaceBetween]="2" [loop]="true" [slidesPerView]="1.1" [autoplay]="false" [navigation]="false"
        class="mySwiper">
        <ng-template swiperSlide *ngFor="let productList of product.productLists;">
          <div class="brand-slider-mobile__col" *ngFor="let item of productList ">
            <div class="brand-slider-mobile__item">
              <ng-container *ngIf="item.label">
                <div class="brand-slider-mobile__labeltag"
                  [ngStyle]="{color:item.label.color,backgroundColor: item.label.backGroundColor}">
                  {{item.label.text}}
                </div>
              </ng-container>

              <a (click)="goRecommendProductLink(item.id)">
                <div class="image-container">
                  <img src="{{item.img}}" alt="">
                  <div class="sold-out" *ngIf="item.isSoldOut">
                    <span>已售完</span>
                  </div>
                </div>
                <div style="margin-bottom: 10px;">
                  <!-- <img src="{{item.img}}" alt=""> -->
                  <div class="brand-slider-mobile__name">{{item.name}}</div>
                </div>
                <div class="best-seller__price__main" *ngIf="item.pP_Point ||item.pP_Price">
                  <p class="best-seller__price best-seller__price--money">
                    {{item.pP_Price}}元
                  </p>
                  <p class="best-seller__price best-seller__price--delete" *ngIf="item.discountPrice !== 0">
                    {{item.discountPrice}}元
                  </p>
                </div>
              </a>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>
</article>
<div class="process-step-button row">
  <div class="process-step-button__button-wrap" *ngIf="detailInfo.details[0].payType==2">
    <div class="order-top-info-cell">
      <button class="process-step-button__button process-step-button__button--next"
      [routerLink]="'/'">
        回首頁
      </button>
    </div>
    <button class="process-step-button__button process-step-button__button--next" (click)="goTicketWallet()">
      查看票券
    </button>
  </div>
</div>

<app-modal [isModalOpen]="isModalOpen" (modalClose)="isModalOpen=$event">
  <h3 headerContent class="general-modal__title">退貨申請</h3>
  <!-- 申請退貨 -->
  <div bodyContent *ngIf="!modalStepCompleted" class="general-modal__body">
    <div>
      <p class="return-product-modal__text return-product-modal__text--mb5">提醒您：</p>
      <p class="return-product-modal__text">7天鑑賞期指的是猶豫期而非試用期，您僅可檢視商品外觀但不能使用，若是經過使用發現新品不良或商品本身有問題則不在此限，您仍可提出退貨申請。
      </p>
      <p class="return-product-modal__text">
        申請退貨時，請務必將您所訂購之完整商品、配件、贈品及包裝盒置入於原外包裝箱內，並將商品回復原狀，並向取貨人員收取退貨簽單並保留該單據到收到退款為止，以利後續退換貨作業。
      </p>
      <p class="return-product-modal__text">7天鑑賞期的算法是從收到商品的隔天開始算7個日曆天（包含例假日）。
      </p>
      <p class="return-product-modal__text return-product-modal__text--mb10">請選擇退貨原因 (如選擇其他 請填寫說明)：</p>
    </div>
    <div class="return-product-modal__reason-section">
      <div class="return-product-modal__radio-wrap">
        <input name="return-product-reason" class="return-product-modal__radio" type="radio" value="商品缺件"
          [(ngModel)]="returnProductReq.Remark">
        <label class="return-product-modal__label" for="">商品缺件</label>
      </div>
      <div class="return-product-modal__radio-wrap">
        <input name="return-product-reason" class="return-product-modal__radio" type="radio" value="商品部分功能不能使用"
          [(ngModel)]="returnProductReq.Remark">
        <label class="return-product-modal__label" for="">商品部分功能不能使用</label>
      </div>
      <div class="return-product-modal__radio-wrap">
        <input name="return-product-reason" class="return-product-modal__radio" type="radio" value="我收到不對的商品(賣家寄錯商品)"
          [(ngModel)]="returnProductReq.Remark">
        <label class="return-product-modal__label" for="">我收到不對的商品(賣家寄錯商品)</label>
      </div>
      <div class="return-product-modal__radio-wrap">
        <input name="return-product-reason" class="return-product-modal__radio" type="radio" value="other"
          [(ngModel)]="returnProductReq.Remark">
        <label class="return-product-modal__label" for="">其他(請輸入說明)</label>
      </div>
    </div>
    <div class="">
      <textarea class="return-product-modal__textarea" name="return-product-textarea" id="" rows="5"
        [(ngModel)]="otherRemark"></textarea>
    </div>
    <div class="return-product-modal__note-wrap">
      <p class="return-product-modal__note">
        <span class="note__icon-wrap">
          <img class="note__icon" src="/assets/images/icon/shock_mark_white.svg" alt="">
        </span>
        <span>退貨申請後，需要7~14的工作天，如有疑問可洽聯絡我們聯繫客服人員。</span>
      </p>
    </div>
  </div>
  <div bodyContent *ngIf="modalStepCompleted" class="general-modal__body"
    [ngClass]="{'general-modal__body--completed': modalStepCompleted}">
    <p class="general-modal__text">{{modalMessage}}</p>
  </div>
  <!--  -->
  <div footerContent *ngIf="!modalStepCompleted" class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--cancel" (click)="isModalOpen=false">取消</button>
    <button class="general-modal__button general-modal__button--confirm" (click)="returnProduct()">
      申請退貨</button>
  </div>
  <div footerContent *ngIf="modalStepCompleted" class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="isModalOpen=false">確認</button>
  </div>

</app-modal>
