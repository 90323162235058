import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { LoginService } from './login.service';
import { Subject } from 'rxjs';
@Injectable()

export class CartService {
    cartNumber = new Subject<number>();
    constructor(private httpService: HttpService, private loginService: LoginService) {
    }

    // 取得購物車商品數量
    getCartNumber() {
        var info = this.loginService.GetLocalInfo();
        this.httpService.get("/Home/GetCartTypeNum?mbrID=" + info.ID).subscribe(
            (x: any) => {
                this.cartNumber.next(parseInt(x));
            }, (err) => {
                this.cartNumber.next(0);
            }
        )
    }
}
