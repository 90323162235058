import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  // 接收從母傳入的modalOpen property
  @Input() public isModalOpen: boolean;
  @Input() public isCloseBtnShow = true;
  @Input() public isOverlayClickable = true;
  @Input() public noFooter = false;
  // 傳給母元件boolean value，作為關閉modal 的依據
  @Output() public modalClose = new EventEmitter<boolean>();
  
  constructor() { }

  ngOnInit(): void {
  }

  onModalClose($event: any) {
    if ($event.target.classList.contains('js-close')) {
      this.modalClose.emit(false);
      return;
    }
  }
}
