import { Pipe } from '@angular/core';
import { Observable, from } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
export { openTicketWallet, getUserToken, makeOrderPayment };
declare var callApp: (
  arg0: string,
  arg1: { license_id: string },
  arg2: Function | null
) => void;

let lastCall = 0;

function throttle(fn: Function, delay: number) {
  return function (...args: any[]) {
    const now = new Date().getTime();
    console.log('lastCall', lastCall);
    if (lastCall != 0 && now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return fn(...args);
  };
}

function openTicketWallet() {
  let parameter = {
    license_id: environment.licenseId,
  };
  const throttledFn = throttle(() => {
    callApp('openTicketWallet', parameter, null);
  }, 1000);

  throttledFn();
}

function getUserToken() {
  let parameter = {
    license_id: environment.licenseId, //請填入核發的	Lincense	ID
  };
  const throttledFn = throttle(() => {
    callApp('getUserToken', parameter, getUserTokenCallback);
  }, 1000);
  throttledFn();
}

function makeOrderPayment(orderNumber: string) {
  let parameter = {
    license_id: environment.licenseId, //請填入核發的	Lincense	ID
    order_number: orderNumber,
  };
  const throttledFn = throttle(() => {
    callApp('makeOrderPayment', parameter, null);
  }, 1000);
  throttledFn();
}

function getUserTokenCallback(response1: string) {
  const response = JSON.parse(response1);
  //錯誤訊息
  if (response.error !== undefined) {
    //錯誤狀態
    alert(response.error.message); //錯誤訊息
  }
  //Now	you	hava	your	user	tokenof	the	EasyWallet	member
  let userToken = response.user_token;
  if (userToken && (userToken as string).length > 0) {
    // localStorage.setItem('userToken', userToken);
    sessionStorage.setItem('userToken', userToken);
  }
}
