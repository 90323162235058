<div class="home container">
    <app-breadcrumb></app-breadcrumb>
    <section class="register">
        <h2 class="register__title">加入會員</h2>
        <form #form="ngForm">
            <div class="register__content">
                <div class="register__wrap">
                    <!-- ngIf registerStep == 0 -->
                    <div class="register__button-wrap register__button-wrap--method" *ngIf="registerStep==0">
                        <button class="register__button register__button--code"
                            (click)="registerStep=1;registerType=1;callPagetagService('/register?registerType=1','加入會員 | 以認證碼方式註冊')">以認證碼方式註冊</button>
                        <button class="register__button register__button--card"
                            (click)="registerStep=1;registerType=2;callPagetagService('/register?registerType=2','加入會員 | 以信用卡方式註冊')">以信用卡方式註冊</button>
                    </div>
                    <!-- ngIf registerStep == 1 -->
                    <div class="register__input-wrap" *ngIf="registerStep==1">
                        <p class="register__type-note">{{registerType == 1 ? '以取得認證訊息通知方式註冊' : '悠遊付'}}</p>
                        <label class="register__label" for="">身分證字號</label>
                        <input class="register__input" placeholder="請輸入您的身分證字號" type="text"
                            (ngModelChange)="id = $event.toUpperCase()" [ngModel]="id"
                            [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="register__recaptcha-wrap" *ngIf="registerStep==1">
                        <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required
                            [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"
                            (resolved)="isResolved=true">
                        </re-captcha>
                    </div>
                    <div class="register__button-wrap register__button-wrap--register" *ngIf="registerStep==1">
                        <a (click)="registerStep=0" class="register__button register__button--cancel">取消</a>
                        <button class="register__button register__button--register"
                            [ngClass]="{'register__button--disabled': !isResolved}" (click)="send(form)">註冊</button>
                    </div>
                    <!--  -->
                    <div class="register__note-wrap">
                        <p class="register__note">
                            <span class="note__icon-wrap">
                                <img class="note__icon" src="/assets/images/icon/shock_mark_white.svg" alt="">
                            </span>
                            <a href="#/" [routerLink]="['/question', 4]" class="note__text">
                                如何取得認證通知
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </section>
</div>
<!-- alertModal -->
<app-modal [isModalOpen]="isMsgModalOpen" (modalClose)="isMsgModalOpen=$event">
    <h3 headerContent class="general-modal__title">提示信息</h3>
    <div bodyContent class="general-modal__text-wrap">
        <p class="general-modal__text" *ngFor="let row of Msg.split('br')">{{row}}</p>
    </div>
    <div footerContent class="general-modal__button-wrap">
        <button class="general-modal__button general-modal__button--confirm general-modal__button--only"
            (click)="Confirm()">確認</button>
    </div>
</app-modal>
