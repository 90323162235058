import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionListRes, QuestionType } from 'src/app/shared/model/question';
import { BreadcrumbService } from 'src/app/shared/service/breadcrumb.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';
import { QuestionService } from 'src/app/shared/service/question.service';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss']
})
export class QuestionListComponent implements OnInit {
  pageTitle: string = "";
  ListRes: QuestionListRes[] = [];
  questionTypeList: QuestionType[] = [];
  firstQuestionType: number = 0; // default
  questionType: number = 3; // default
  childrenPathObjList: { path: string, name: string }[];

  constructor(
    private route: ActivatedRoute,
    private elements: ElementRef,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private breadcrumbService: BreadcrumbService,
    private questionService: QuestionService,
    private pagetagService: PagetagService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.questionType = this.route.snapshot.params['id'];
      this.setPageTitle(this.questionType);
      this.getQuestionList(this.questionType)
    });

    this.questionService.questionTypeList.subscribe(x => {
      this.childrenPathObjList = [];
      for (let i = 0; i < x.length; i++) {
        this.childrenPathObjList.push({ path: x[i].id.toString(), name: x[i].name })
      }

      // this.childrenPathObjList.push({ path: 'contact', name: '聯絡我們' })
    });

    this.questionService.questionTypeList.subscribe(x => {
      this.questionTypeList = x;
      this.firstQuestionType = this.questionTypeList.length > 0 ? this.questionTypeList[0].id : 0
      this.setPageTitle(this.questionType);
    })
  }

  getQuestionList(type: number) {
    this.loaderService.start();
    this.httpService.get("/tbl_Qa/" + type).subscribe(
      (res: any) => {
        this.ListRes = res;

        this.breadcrumbService.breadcrumbData.next([{ breadcrumb: '常見問題', path: this.firstQuestionType.toString() }, { breadcrumb: this.pageTitle, path: '' }])
        this.callPagetagService(`常見問題 | ${this.pageTitle}`);
        this.loaderService.stop();
      }
    )
  }

  setPageTitle(type: number) {
    for (let i = 0; i < this.questionTypeList.length; i++) {
      if (type == this.questionTypeList[i].id) {
        this.pageTitle = this.questionTypeList[i].name;
        break;
      }
    }
  }

  toggleListContent($event: any, contentElement: any, contentListElement: any) {
    $event.preventDefault();
    if (!($event.target.classList.contains('js-question-list__link') ||
      ($event.target.classList.contains('js-question-list__item-state-icon--expand')) ||
      ($event.target.classList.contains('js-question-list__item-state-icon--shrink')))) { return; };

    let targetLink: HTMLAnchorElement;
    if ($event.target.classList.contains('js-question-list__link')) {
      targetLink = $event.target;
    } else {
      targetLink = $event.target.parentNode.closest('.js-question-list__link');
    }

    let contentListHeight = contentListElement.offsetHeight;
    let contentElements = this.elements.nativeElement.querySelectorAll('.js-question-list__content');
    let listLinks = this.elements.nativeElement.querySelectorAll('.js-question-list__link');
    for (let i = 0; i < contentElements.length; i++) {
      if (contentElements[i] == contentElement) {
        continue;
      }

      contentElements[i].style["max-height"] = "0px";
      contentElements[i].style["visibility"] = "hidden";
    }

    for (let j = 0; j < listLinks.length; j++) {
      listLinks[j].querySelector('.js-question-list__item-state-icon--expand').style.display = "inline";
      listLinks[j].querySelector('.js-question-list__item-state-icon--shrink').style.display = "none";
    }

    if (contentElement.offsetHeight > 0) {
      let targetLinkExpandIcon = targetLink.querySelector('.js-question-list__item-state-icon--expand') as HTMLElement;
      let targetLinkShrinkIcon = targetLink.querySelector('.js-question-list__item-state-icon--shrink') as HTMLElement;
      targetLinkExpandIcon.style.display = "inline";
      targetLinkShrinkIcon.style.display = "none";


      contentElement.style["max-height"] = "0px";
      contentElement.style["visibility"] = "hidden";

    } else {
      let targetLinkExpandIcon = targetLink.querySelector('.js-question-list__item-state-icon--expand') as HTMLElement;
      let targetLinkShrinkIcon = targetLink.querySelector('.js-question-list__item-state-icon--shrink') as HTMLElement;
      targetLinkExpandIcon.style.display = "none";
      targetLinkShrinkIcon.style.display = "inline";

      contentElement.style["max-height"] = contentListHeight + "px";
      contentElement.style["visibility"] = "visible";
    }
  }

  callPagetagService(name: string) {
    this.pagetagService.callPageTag(this.router.url, name)
  }
}
