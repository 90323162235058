<h2 class="voucher-title">優惠碼</h2>
<section class="voucher-content">
    <section class="voucher-form">
        <div class="voucher-form__text-wrap">
            <img class="voucher-form__icon" src="/assets/images/icon/gift_pink.svg" alt="">
            <h3 class="voucher-form__text">請輸入優惠碼</h3>
        </div>
        <div class="voucher-form__input-wrap">
            <input class="voucher-form__input" type="text" [(ngModel)]="voucher">
        </div>
        <div class="voucher-form__recaptcha-wrap">
            <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required
                [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"
                (resolved)="isResolved=true">
            </re-captcha>
        </div>
        <div class="voucher-form__button-wrap">
            <button class="voucher-form__button" [ngClass]="{'voucher-form__button--disabled': !isResolved}"
                (click)="verifyVoucher()">確認</button>
        </div>
    </section>
</section>

<!-- modal -->
<app-modal [isModalOpen]="isModalOpen" (modalClose)="isModalOpen=$event">
    <h4 headerContent class="general-modal__title">{{modalTiTle}}</h4>
    <div bodyContent class="general-modal__body">
        <ng-container *ngIf="voucherStatus==1">
            <p class="general-modal__text general-modal__text--flex-center">您已獲得
                <span class="point-dollar-number">
                    <img class="point-dollar-number__image point-dollar-number__image--mr2p"
                        src="/assets/images/icon/money-red.svg" alt="">
                    <span
                        class="point-dollar-number__text point-dollar-number__text--primary point-dollar-number__text--mr3p">{{modalPoint}}</span>
                </span>點
            </p>
            <!-- <p class="general-modal__text">效期 {{modalDate}}</p> -->
        </ng-container>
        <ng-container *ngIf="voucherStatus==2">
            <p class="general-modal__text general-modal__text--fail">{{modalMsg}}</p>
        </ng-container>
    </div>
    <div footerContent class="general-modal__button-wrap">
        <button class="general-modal__button general-modal__button--confirm general-modal__button--only"
            (click)="clickConfirm();">確認</button>
    </div>
</app-modal>
