<div class="container">
  <div class="container">
    <h2 class="contact-title">版權聲明</h2>
    <!-- <h3 class="contact-title">本平台致力於遵守保護個人資料的相關法令及本平台隱私保護政策，妥善管理和保護會員的隱私權。</h3> -->
    <ol class="contact-note">
      <li>
        1.本平台所使用之軟體或程式、平台網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本平台或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。
      </li>
      <li>
        2.若您欲引用或轉載前述軟體、程式或網站內容，必須依法取得本平台或其他權利人的事前書面授權同意。尊重智慧財產權是您應盡的義務，如有違反，您應對悠遊卡股份有限公司／精誠資訊股份有限公司負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
      </li>
      <li>3.在尊重他人智慧財產權之原則下，會員同意在使用本平台服務時，不作侵害他人智慧財產權之行為。</li>
      <li>4.若會員有涉及侵權之情事，本平台可暫停全部或部份之服務，或逕自以取消會員帳號之方式處理。</li>
      <li class="contact-note--last">5.若對本平台版權有任何問題，或有發現智慧財產權遭侵害之情事，敬請聯絡本平台，聯絡方式：電子郵件信箱：giftshop@systex.com
        </li>
        <li>6.本聲明之解釋與適用，以及有關的爭議，均應依照中華民國法律予以處理，並以臺灣臺北地方法院為第一審管轄法院。</li>
    </ol>
    <!-- <h3 class="contact-title">會員對於本平台之授權</h3>
    <p class="contact-note--last">
      對於會員所登錄或留存之個人資料，會員同意本平台網站得於合理之範圍內蒐集、處理、保存、傳遞及使用該等資料，以提供使用者其他資訊或服務、或作成會員統計資料、或進行關於網路行為之調查或研究，或為任何之合法使用。
    </p>
    <h3 class="contact-title">準據法與管轄法院</h3>
    <p class="contact-note--last">
      　　本約定書之解釋與適用，以及與本約定書有關的爭議，均應依照中華民國法律予以處理，並以台灣台北地方法院為第一審管轄法院。
    </p> -->
  </div>
</div>
