<!-- <section class="js-carousel carousel">

  <div #carouselInner class="js-carousel-inner carousel__inner">

    <div #carouselItemList *ngFor="let temp of banners; let i=index;"
      [ngClass]="{'carousel__item--show': carouselItemActive==i}">---{{i}}
      <a [href]="'product/list/'+temp.id" > -->
<!-- <img #carouselMobileImage class="js-carousel__image carousel__image"
            [ngClass]="{'js-active-image': carouselItemActive==i}" src="{{temp.logo}}" alt=""
            (load)="resetCarouselHeight(carouselMobileImage)">

      </a>

    </div>
  </div>
</section> -->

<section class="js-carousel carousel" *ngIf="false">
  <!-- <div class="carousel__go-next-button">
        <a href="#/" (click)="scrollToAnchor($event)">
            <span><img src="/assets/images/icon/arrow_bottom_pink.svg" alt=""></span>
        </a>
    </div> -->
  <div #carouselInner class="js-carousel-inner carousel__inner">
    <!-- ngfor -->
    <!-- ngClass 0,1,2 should be index-->
    <div #carouselItemList class="carousel__item" *ngFor="let q of dataTeamList;let i=index;"
      [ngClass]="{'carousel__item--show': carouselItemActive==i}">
      <div class="teamList">
        <a class="teamItemWrap" [href]="'product/list/'+item.id" *ngFor="let item of banners | slice:i*8:i*8+8">
          <img #carouselDesktopImage class="js-carousel__image carousel__image"
            [ngClass]="{'js-active-image': carouselItemActive==i}"
            [src]="sanitizer.bypassSecurityTrustResourceUrl(item?.logo??'')" alt=""
            (load)="resetCarouselHeight(carouselDesktopImage)">
          <p>{{item.name}} </p>
        </a>
      </div>
    </div>
  </div>
  <!-- <ol class="carousel__indicators"> -->
  <!-- ngFor -->
  <!-- ngClass 0,1,2 should be index-->
  <!-- <li class="indicators__item" (click)="onCarouselChange(carouselInner,i,$event)"
            *ngFor="let item of banners;let i =index">
            <a href="#/" class="indicators__link" [ngClass]="{'indicators__link--active': carouselItemActive==i}"></a>
        </li>
    </ol> -->
</section>

<swiper [spaceBetween]="30" [centeredSlides]="true" [pagination]="{clickable:true}" [navigation]="true"
  class="mySwiper">
  <ng-template swiperSlide *ngFor="let q of dataTeamList;let i=index;">
    <div class="teamList">
      <ng-container *ngFor="let item of banners | slice:i*8:i*8+8">
        <a *ngIf="item.name == '所有品牌'" class="teamItemWrap" [href]="'product/overview'">
          <img #carouselDesktopImage class="js-carousel__image carousel__image"
            [ngClass]="{'js-active-image': carouselItemActive==i}"
            [src]="sanitizer.bypassSecurityTrustResourceUrl(item?.logo??'')" alt=""
            (load)="resetCarouselHeight(carouselDesktopImage)">
          <p>{{item.name}} </p>
        </a>
        <a *ngIf="item.name != '所有品牌'" class="teamItemWrap" [href]="'product/list/'+item.id">
          <img #carouselDesktopImage class="js-carousel__image carousel__image"
            [ngClass]="{'js-active-image': carouselItemActive==i}"
            [src]="sanitizer.bypassSecurityTrustResourceUrl(item?.logo??'')" alt=""
            (load)="resetCarouselHeight(carouselDesktopImage)">
          <p>{{item.name}} </p>
        </a>
      </ng-container>

    </div>
  </ng-template>
</swiper>
