import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { QuestionType } from '../model/question';
@Injectable()

export class QuestionService {
    private questionTypeListSource = new BehaviorSubject<QuestionType[]>([]);
    questionTypeList = this.questionTypeListSource.asObservable();
    constructor() { }

    setQuestionTypeList(list: QuestionType[]) {
        this.questionTypeListSource.next(list)
    }
}