<h2 class="ticket-title">會員票券</h2>
<section class="ticket-content">
  <section class="filter-section">
    <div class="ticket-type-tab">
      <div class="no-scrollbar">
        <div drag-scroll-item class="ticket-type-tab__button-wrap">
          <button class="ticket-type-tab__button" [ngClass]="{'ticket-type-tab__button--active':ticketTypeTab==1}"
            (click)="clickTicketTypeTag(1)">可使用票劵</button>
        </div>
        <div drag-scroll-item class="ticket-type-tab__button-wrap">
          <button class="ticket-type-tab__button" [ngClass]="{'ticket-type-tab__button--active':ticketTypeTab==2}"
            (click)="clickTicketTypeTag(2)">已使用或過期</button>
        </div>
        <div drag-scroll-item class="ticket-type-tab__button-wrap">
          <button *ngIf="false" class="ticket-type-tab__button"
            [ngClass]="{'ticket-type-tab__button--active':ticketTypeTab==3}"
            (click)="clickTicketTypeTag(3)">已轉贈票劵</button>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="sort-menu">
      <span class="sort-menu__text">兌換期限</span>
      <button class="sort-menu__desktop-trigger-button js-sort-direction" (click)="changeSortDirection($event)">
        <img class="js-sort-direction" [ngStyle]="{'transform' : !isSortASC? 'rotate(180deg)' : 'none'}"
          src="/assets/images/icon/arrow_top_white.svg" alt="">
      </button>
    </div>
  </section>
  <section class="ticket-list">
    <div class="ticket-list__row">
      <!-- 可使用票劵 樣式 -->
      <ng-container *ngIf="filterInfos.length>0">
        <div class="ticket-list__col" *ngFor="let item of filterInfos">
          <div class="ticket-list__item">
            <div class="ticket-list__detail">
              <h3 class="ticket-list__name">{{item.title}}</h3>
              <div class="ticket-list__image-wrap">
                <div class="ticket-list__image-mask" *ngIf="item.ticketType!=1">
                  {{item.ticketType==2?"已使用":(item.ticketType==3?"已過期":"已轉贈")}}</div>
                <img class="ticket-list__image" src="{{item.img}}" alt="">
              </div>
              <div class="ticket-list__info-wrap">
                <p class="ticket-list__text ticket-list__text--first" *ngIf="item.ticketNo && item.ticketType!=3">
                  票券序號：{{item.ticketNo}}
                </p>
                <p class="ticket-list__text"
                  [ngClass]="{'ticket-list__text--first': !(item.ticketNo && item.ticketType!=3)}">
                  票劵效期：{{item.useLimitDT}}
                </p>
                <p class="ticket-list__text">
                  購買日期：{{item.buyDT}}
                </p>
                <p class="ticket-list__text" *ngIf="item.ticketType!=1">
                  {{item.ticketType==2 ? "使用日期：" : "轉贈日期：" }}{{item.exchangeDT}}
                </p>
                <p class="ticket-list__text">
                  兌換方式：{{item.exchangeType}}
                </p>
              </div>
            </div>
            <div class="ticket-list__button-wrap" *ngIf="item.ticketType==1">
              <!-- ngFor 的時候把item 放進useTicket()裡 -->
              <button class="ticket-list__button" (click)="useTicket(item)">使用票券</button>
              <button *ngIf="userInfo.IsEmp" class="ticket-list__button" (click)="shareTicket(item)">分享票券</button>
              <button *ngIf="false" class="ticket-list__button" (click)="transferTicket()">轉贈票券</button>
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="filterInfos.length==0" class="ticket-empty__wrap">
        <img class="ticket-empty__image" src="/assets/images/icon/cart_empty.svg" alt="">
        <p class="ticket-empty__text">
          {{ticketTypeTab==1?'目前未有可使用的票券資訊':ticketTypeTab==2?'目前未有使用的票券資訊':'目前未有已轉贈的票券資訊'}}</p>
        <a class="ticket-empty__button" [routerLink]="['/']">去購物吧</a>
      </div>
    </div>
  </section>
</section>

<app-modal [isModalOpen]="isModalOpen" [noFooter]="noModalFooter" (modalClose)="isModalOpen=$event">
  <!-- 使用票券 / modalProcess 0 -->
  <h4 *ngIf="modalProcess==0" headerContent class="general-modal__title">{{modalDataForUseTicket.name}}</h4>
  <div *ngIf="modalProcess==0" bodyContent class="general-modal__body">
    <div class="ticket-use-modal__detail">
      <div class="ticket-use-modal__image-wrap">
        <img class="ticket-use-modal__image" src="{{modalDataForUseTicket.imgUrl}}" alt="">
      </div>
      <div class="ticket-use-modal__info-wrap">
        <p class="ticket-use-modal__text">
          票劵效期：{{modalDataForUseTicket.expiredDate}}
        </p>
        <p class="ticket-use-modal__text">
          票券序號：{{modalDataForUseTicket.sn}}
        </p>
        <p class="ticket-use-modal__text">
          購買日期：{{modalDataForUseTicket.purchaseDate}}
        </p>
        <p class="ticket-use-modal__text">
          兌換方式：{{modalDataForUseTicket.exchangeWay}}
        </p>
      </div>
    </div>
    <div lass="ticket-use-modal__bar-code-wrap">
      <ngx-barcode6
        style="max-width: 100%;margin-bottom:12px;display: flex;justify-content: center;align-items: center;"
        *ngIf="modalDataForUseTicket.isQR ==0" [bc-value]="modalDataForUseTicket.sn" [bc-display-value]="true">
      </ngx-barcode6>
      <ngx-qrcode *ngIf="modalDataForUseTicket.isQR ==1" [value]="modalDataForUseTicket.qRcodeString">
      </ngx-qrcode>
      <span *ngIf="modalDataForUseTicket.isQR ==1">{{modalDataForUseTicket.couponNo}}</span>
      <!-- <p *ngIf="modalDataForUseTicket.isQR ==3">PIN碼:{{modalDataForUseTicket.pinCode}}</p> -->
      <ngx-barcode6
        style="max-width: 100%;margin-bottom:12px;display: flex;justify-content: center;align-items: center;"
        *ngIf="modalDataForUseTicket.isQR ==2"
        [bc-value]="modalDataForUseTicket.barcode1" [bc-display-value]="true"></ngx-barcode6>
        <ngx-barcode6
        style="max-width: 100%;margin-bottom:12px;display: flex;justify-content: center;align-items: center;"
        *ngIf="modalDataForUseTicket.isQR ==3"  [bc-format]="'CODE39'"
        [bc-value]="modalDataForUseTicket.barcode1" [bc-display-value]="true"></ngx-barcode6>
      <ngx-barcode6
        style="max-width: 100%;margin-bottom:12px;display: flex;justify-content: center;align-items: center;"
        *ngIf="modalDataForUseTicket.isQR ==2" [bc-value]="modalDataForUseTicket.barcode2" [bc-display-value]="true">
      </ngx-barcode6>
      <ngx-barcode6
        style="max-width: 100%;margin-bottom:12px;display: flex;justify-content: center;align-items: center;"
        *ngIf="modalDataForUseTicket.isQR ==4" [bc-value]="modalDataForUseTicket.multiBarcodeType"
        [bc-display-value]="true">
      </ngx-barcode6>
      <ngx-barcode6
        style="max-width: 100%;margin-bottom:12px;display: flex;justify-content: center;align-items: center;"
        *ngIf="modalDataForUseTicket.isQR ==4" [bc-value]="modalDataForUseTicket.couponNo" [bc-display-value]="true">
      </ngx-barcode6>
    </div>
    <div class="ticket-use-modal__notice-wrap">
      <h5 class="ticket-use-modal__notice-title">使用電子票券注意事項</h5>
      <div [innerHTML]="modalDataForUseTicket.ticketPrecautions | html"></div>
    </div>
  </div>

  <!-- 轉贈票券 / modalProcess 1, 2, 3, 4, 5 -->
  <h4 *ngIf="modalProcess>0&&6>modalProcess" headerContent class="general-modal__title">{{modalProcess == 2 ? '請選擇受贈者對象'
    : '轉贈票券'}}
  </h4>
  <!-- 1 -->
  <div *ngIf="modalProcess==1" bodyContent class="general-modal__body">
    <div class="ticket-transfer-modal__input-wrap">
      <label class="ticket-transfer-modal__text">請輸入受贈者的手機號碼</label>
      <input class="ticket-transfer-modal__input" type="text">
    </div>
  </div>
  <!-- 2 -->
  <div *ngIf="modalProcess==2" bodyContent class="general-modal__body">
    <ul class="ticket-transfer-modal__receiver-list">
      <li class="receiver-list__item">
        <span class="receiver-list__cell receiver-list__cell--first-row">選擇</span>
        <span class="receiver-list__cell receiver-list__cell--first-row">收件人</span>
        <span class="receiver-list__cell receiver-list__cell--first-row">收件人電話</span>
      </li>
      <li class="receiver-list__item">
        <span class="receiver-list__cell">
          <input class="receiver-list__radio" name="receiver-radio" type="radio">
        </span>
        <span class="receiver-list__cell">王曉明</span>
        <span class="receiver-list__cell">0912345678</span>
      </li>
      <li class="receiver-list__item">
        <span class="receiver-list__cell">
          <input class="receiver-list__radio" name="receiver-radio" type="radio">
        </span>
        <span class="receiver-list__cell">王曉明</span>
        <span class="receiver-list__cell">0912345678</span>
      </li>
    </ul>
  </div>
  <!-- 3 -->
  <div *ngIf="modalProcess==3" bodyContent class="general-modal__body">
    <div class="ticket-transfer-modal__input-wrap">
      <label class="ticket-transfer-modal__text">請輸入手機驗證碼</label>
      <input class="ticket-transfer-modal__input" type="text">
    </div>
    <div class="ticket-transfer-modal__note-wrap">
      <p class="ticket-transfer-modal__note">
        <span class="note__icon-wrap">
          <img class="note__icon" src="/assets/images/icon/shock_mark_white.svg" alt="">
        </span>
        <span>若未收到簡訊認證，您可點選<a href="" class="note__link">重新發送</a>一次</span>
      </p>
    </div>
  </div>
  <!-- 4 -->
  <div *ngIf="modalProcess==4" bodyContent class="general-modal__body">
    <div>
      <p class="ticket-transfer-modal__text ticket-transfer-modal__text--mb15">您確定要轉贈給</p>
      <p class="ticket-transfer-modal__text ticket-transfer-modal__text--mb15">王小明</p>
      <p class="ticket-transfer-modal__text ticket-transfer-modal__text--mb15">A123456789</p>
    </div>
  </div>
  <!-- 5 -->
  <div *ngIf="modalProcess==5" bodyContent class="general-modal__body">
    <div style="width: 310px;margin: auto;">
      <p class="ticket-transfer-modal__text ticket-transfer-modal__text--mb10">轉贈成功</p>
    </div>
  </div>

  <!-- 1,2,3,4 footer -->
  <div *ngIf="modalProcess!=0 && modalProcess!=5" footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--cancel" (click)="isModalOpen=false">取消</button>
    <button class="general-modal__button general-modal__button--confirm"
      (click)="modalProcess=modalProcess+1">確認</button>
  </div>
  <!-- 5 footer -->
  <div *ngIf="modalProcess==5" footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--cancel general-modal__button--only"
      (click)="isModalOpen=false">關閉</button>
  </div>
  <!-- 6 分享票券 -->
  <h4 *ngIf="modalProcess==6" headerContent class="general-modal__title">{{modalDataForShareTicket.name}}</h4>
  <div *ngIf="modalProcess==6" bodyContent class="general-modal__body">
    <div class="ticket-use-modal__detail">
      <div class="ticket-use-modal__info-wrap">
        <p class="ticket-use-modal__text">
          票劵連結：
        </p>
        <a href="{{modalDataForShareTicket.ticketLink}}" target="_blank">
          <p class="ticket-use-modal__text" style="white-space:normal">
            {{modalDataForShareTicket.ticketLink}}
          </p>
        </a>
        <p class="ticket-use-modal__text">
          票券密碼：{{modalDataForShareTicket.snKey}}
        </p>
      </div>
    </div>
  </div>
</app-modal>
