<ng-container class="carousel" *ngIf="homeInfo.topBanners && homeInfo.topBanners.length>0">
  <app-owl-carousel [banners]="homeInfo.topBanners" ></app-owl-carousel>
  <!--訂單檢視/我的票夾-->
  <div class="main-function">
    <!-- <div><a [routerLink]="['/member/order']"><img src="assets/images/icon/order-view-icon.svg">檢視訂單</a></div> -->
    <div><a href="javascript:void(0)" (click)="goOrder()"><img src="assets/images/icon/order-view-icon.svg">檢視訂單</a></div>
    <!-- <div><a [routerLink]="'/event/13'"><img src="assets/images/icon/Activity-icon.svg">活動快訊</a></div> -->
    <div><a href="javascript:void(0)" (click)="goActivity()"><img src="assets/images/icon/Activity-icon.svg">活動快訊</a></div>
    <div><a href="#" (click)="goTicketWallet()"><img src="assets/images/icon/my-wallet-icon.svg">我的票夾</a></div>
  </div>
</ng-container>

<div class="container teamCarousel">
  <ng-container *ngIf="brands && brands.length > 0">
    <app-teamCarousel [banners]="brands"></app-teamCarousel>
  </ng-container>
</div>
<div class="home container black-line">
  <section class="accumulated-points" [hidden]="homeInfo.activities && homeInfo.activities.length==0">
    <div class="accumulated-points__title-wrap">
      <h2 class="accumulated-points__title">快速累積點數</h2>
      <a class="accumulated-points__more-link"
        routerLink="{{homeInfo.activities[0]  ? '/event/' + homeInfo.activities[0].cType : ''}}">更多秘笈</a>
    </div>
    <div class="accumulated-points__row-wrap">
      <!--  -->
      <div class="accumulated-points__slide-button-wrap">
        <div class="accumulated-points__slide-button accumulated-points__slide-button--prev"
          [ngClass]="{'accumulated-points__slide-button--hide': brandSliderCounter==0}"
          (click)="onBrandSliderChange('prev',$event)">
          <a href="#/">
            <span><img src="/assets/images/icon/arrow_top_pink.svg" alt=""></span>
          </a>
        </div>
        <div class="accumulated-points__slide-button accumulated-points__slide-button--next"
          [ngClass]="{'accumulated-points__slide-button--hide': brandSliderCounter==1}"
          (click)="onBrandSliderChange('next',$event)">
          <a href="#/">
            <span><img src="/assets/images/icon/arrow_top_pink.svg" alt=""></span>
          </a>
        </div>
      </div>
      <!--  -->
      <div class="accumulated-points__row">
        <div class="accumulated-points__col" *ngFor="let item of homeInfo.activities">
          <div class="accumulated-points__item">
            <ng-container *ngIf="item.cContentType!=0">
              <a class="accumulated-points__image-wrap" href="{{item.cUrl}}" target="_blank">
                <img class="accumulated-points__image" src="{{item.cImg}}" alt="">
              </a>
              <a class="accumulated-points__button" href="{{item.cUrl}}" target="_blank">了解更多</a>
            </ng-container>
            <!--  -->
            <ng-container *ngIf="item.cContentType==0">
              <a class="accumulated-points__image-wrap" href="{{'/event/' + item.cType + '/' + item.cID}}"
                routerLink="{{'/event/' + item.cType + '/' + item.cID}}">
                <img class="accumulated-points__image" src="{{item.cImg}}" alt="">
              </a>
              <a class="accumulated-points__button" href="{{'/event/' + item.cType + '/' + item.cID}}"
                routerLink="{{'/event/' + item.cType + '/' + item.cID}}">了解更多</a>
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- 本期主打 -->
  <section class="best-seller" [hidden]="homeInfo.hitProducts && homeInfo.hitProducts.length==0">
    <div class="best-seller__title-wrap">
      <!-- <h2 class="best-seller__title">本期主打</h2> -->
      <h2 class="best-seller__title">{{homeInfo.hotTitle}}</h2>
      <a class="bring-Information" routerLink="/product/recommend/0">查看更多</a>
    </div>
    <div class="best-seller__bannerWrap" *ngIf="homeInfo.midBanners && homeInfo.midBanners.length>0">
      <app-carousel [banners]="homeInfo.midBanners" [isTop]="false"></app-carousel>
    </div>
    <div class="recommended-brand__brand-slider-mobile-text"
      *ngIf="1&& mobileBrandList.productLists.length">
      <div class="brand-slider-mobile__row-wrap">
        <swiper [spaceBetween]="2" [loop]="true" [slidesPerView]="2.1" [navigation]="false" class="mySwiper">
          <ng-template swiperSlide *ngFor="let list of mobileBrandList.productLists;">
            <!-- <div class="brand-slider-mobile__row"> -->
            <div class="brand-slider-mobile__col" *ngFor="let item of list.products">

              <div class="brand-slider-mobile__item">
                <ng-container *ngIf="item.label">
                  <div class="brand-slider-mobile__labeltag" [ngStyle]="{color:item.label.color,backgroundColor: item.label.backGroundColor}">
                    {{item.label.text}}
                  </div>
                </ng-container>

                <a routerLink="/product/detail/{{item.id}}">
                  <div class="image-container">
                    <img src="{{item.img}}" alt="">
                    <div class="sold-out" *ngIf="item.isSoldOut">
                      <span>已售完</span>
                    </div>
                  </div>

                  <div style="margin-bottom: 10px;">
                    <div class="brand-slider-mobile__name">{{item.name}}</div>

                  </div>
                  <div class="best-seller__price__main" *ngIf="item.pP_Point ||item.pP_Price">
                    <p class="best-seller__price best-seller__price--money" >
                      {{item.pP_Price}}元
                    </p>
                    <p class="best-seller__price best-seller__price--delete" *ngIf="item.discountPrice !== 0">
                      {{item.discountPrice}}元
                    </p>
                  </div>
                </a>

              </div>
              <!-- </div> -->
            </div>
          </ng-template>
        </swiper>
      </div>
    </div>
    <!-- <ng-container class="best-seller__bannerWrap" *ngIf="homeInfo.midBanners && homeInfo.midBanners.length>0">
      <app-carousel [banners]="homeInfo.midBanners" [isTop]="false"></app-carousel>
    </ng-container> -->
    <!-- <div class="best-seller__bannerWrap">
      <img class="best-seller__bannerS" src="/assets/images/background/banner-s.png"/>
    </div> -->

  </section>
  <!-- 推薦商品 -->
  <section class="best-seller" [hidden]="homeInfo.hotProducts2.length==0">
    <div class="best-seller__title-wrap">
      <h2 class="best-seller__title">{{homeInfo.hotTitle2}}</h2>
      <!-- <a class="bring-Information" routerLink="/product/recommend">查看更多</a> -->
    </div>
    <div class="best-seller__row">
      <!-- each item -->
      <div class="best-seller__col" *ngFor="let item of homeInfo.hotProducts2">
        <ng-container *ngIf="item.label" >
          <div class="best-seller__labeltag" [ngStyle]="{color:item.label.color,backgroundColor: item.label.backGroundColor}">
            {{item.label.text}}
          </div>
        </ng-container>
        <div class="best-seller__item">
          <div class="best-seller__image-wrap">
            <div routerLink="/product/detail/{{item.id}}" class="out-of-stock-mask" *ngIf="item.isSoldOut" >
              <div class="out-of-stock-mask__circle">
                <span class="out-of-stock-mask__text">已售完</span>
              </div>
            </div>

            <a routerLink="/product/detail/{{item.id}}" class="best-seller__product-image">
              <img class="" src="{{item.img}}" alt="">
            </a>
            <!-- <span (click)="toggleToFavorite(item.id, item.isCollect)" class="best-seller__favorite-icon">
              <img class="favorite"
                src="/assets/images/icon/{{item.isCollect?'favorite_heart_pink_solid_ring.svg':'favorite_heart_pink_opacity_ring.svg'}}"
                alt="">
            </span> -->
          </div>
          <div class="best-seller__name-wrap">
            <a routerLink="/product/detail/{{item.id}}" class="best-seller__name">
              {{item.name}}
            </a>
          </div>
          <div class="best-seller__price-wrap">
            <!-- <div class="d-flex marginB-5p">
              <p class="best-seller__price best-seller__price--money" *ngIf="item.points">
                {{item.points}}點</p>
            </div> -->
            <div class="d-flex align-items-center">
              <!-- <p class="best-seller__price best-seller__price--point" *ngIf="item.pP_Point">
                {{item.pP_Point}}點</p>
              <span class="best-seller__add-sign" *ngIf="item.pP_Point||item.pP_Point">＋</span> -->
              <div style="display: flex;" *ngIf="item.pP_Point ||item.pP_Price">
                <p class="best-seller__price best-seller__price--money" >
                  {{item.pP_Price}}元
                </p>
                <p class="best-seller__price best-seller__price--delete" *ngIf="item.discountPrice !== 0">
                  {{item.discountPrice}}元
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="best-seller__more-butn"><a class="best-seller__more-link" routerLink="/product/recommend/1">查看更多</a></div>
  </section>
  <!--toDo  這塊先塞熱銷商品 之後確認邏輯再調整 -->
  <section class="best-seller" [hidden]="homeInfo.hotSellProducts.length==0">
    <div class="best-seller__title-wrap">
      <!-- 資料 hotSellProducts -->
      <h2 class="best-seller__title">推薦首選</h2>
      <!-- <a class="bring-Information" routerLink="/product/recommend">查看更多</a> -->
    </div>
    <div class="best-seller__row">
      <!-- each item -->
      <div class="best-seller__col" *ngFor="let item of homeInfo.hotSellProducts">
        <ng-container *ngIf="item.label" >
          <div class="best-seller__labeltag" [ngStyle]="{color:item.label.color,backgroundColor: item.label.backGroundColor}">
            {{item.label.text}}
          </div>
        </ng-container>
        <div class="best-seller__item">
          <div class="best-seller__image-wrap">
            <div routerLink="/product/detail/{{item.id}}" class="out-of-stock-mask" *ngIf="item.isSoldOut">
              <div class="out-of-stock-mask__circle">
                <span class="out-of-stock-mask__text">已售完</span>
              </div>
            </div>
            <a routerLink="/product/detail/{{item.id}}" class="best-seller__product-image">
              <img class="" src="{{item.img}}" alt="">
            </a>
            <!-- <span (click)="toggleToFavorite(item.id, item.isCollect)" class="best-seller__favorite-icon">
              <img class="favorite"
                src="/assets/images/icon/{{item.isCollect?'favorite_heart_pink_solid_ring.svg':'favorite_heart_pink_opacity_ring.svg'}}"
                alt="">
            </span> -->
          </div>
          <div class="best-seller__name-wrap">
            <a routerLink="/product/detail/{{item.id}}" class="best-seller__name">
              {{item.name}}
            </a>
          </div>
          <div class="best-seller__price-wrap">
            <!-- <div class="d-flex marginB-5p">
              <p class="best-seller__price best-seller__price--money" *ngIf="item.points">
                {{item.points}}點</p>
            </div> -->
            <div class="d-flex align-items-center">
              <!-- <p class="best-seller__price best-seller__price--point" *ngIf="item.pP_Point">
                {{item.pP_Point}}點</p>
              <span class="best-seller__add-sign" *ngIf="item.pP_Point||item.pP_Point">＋</span> -->
              <div style="display: flex;" *ngIf="item.pP_Point ||item.pP_Price">
                <p class="best-seller__price best-seller__price--money" >
                  {{item.pP_Price}}元
                </p>
                <p class="best-seller__price best-seller__price--delete" *ngIf="item.discountPrice !== 0">
                  {{item.discountPrice}}元
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
   <div class="best-seller__more-butn">  <a class="best-seller__more-link" routerLink="/product/recommend/2">查看更多</a></div>
    </div>
  </section>
</div>
<section class="recommended-brand" [hidden]="homeInfo.brands.length==0">
  <div class="recommended-brand__title-wrap">
    <h2 class="recommended-brand__title">推薦品牌</h2>
    <a class="bring-Information" routerLink="/product/overview">查看更多</a>
  </div>
  <!-- if width >= 768 -->
  <div class="recommended-brand__brand-slider-desktop" *ngIf="!isMobile">
    <div class="brand-slider-desktop__row-wrap">
      <div class="brand-slider-desktop__row">
        <div class="brand-slider-desktop__col" *ngFor="let item of homeInfo.brands">
          <div class="brand-slider-desktop__item">
            <a routerLink="/product/list/{{item.cat1_id}}/{{item.cat2_id}}">
              <img src="{{item.logo}}" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <ol class="brand-slider-desktop__indicators">
      <li class="indicators__item" *ngFor="let item of createEmptyArray(brandCarouselTotalPages);let i=index;">
        <a href="" class="indicators__link" [ngClass]="{'indicators__link--active': brandCarouselCounter==i}"
          (click)="onBrandCarouselChange(i,$event)"></a>
      </li>
    </ol>
  </div>
  <!-- if width < 768 -->
  <div class="recommended-brand__brand-slider-mobile" *ngIf="isMobile && false">
    <div class="brand-slider-mobile__row-wrap">
      <!-- each row -->
      <div class="brand-slider-mobile__row" *ngFor="let list of mobileBrandList.brandLists;">
        <div class="brand-slider-mobile__col" *ngFor="let item of list.brands">
          <div class="brand-slider-mobile__item">
            <a routerLink="/product/list/{{item.cat1_id}}/{{item.cat2_id}}">
              <img src="{{item.logo}}" alt="">
              <div class="brand-slider-mobile__name">{{item.name2}}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- indicators -->
    <!-- <ol class="brand-slider-mobile__indicators">
      <li class="indicators__item" *ngFor="let item of createEmptyArray(brandCarouselTotalPages);let i=index;">
        <a href="" class="indicators__link" [ngClass]="{'indicators__link--active': brandCarouselCounter==i}"
          (click)="onBrandCarouselChange(i,$event)"></a>
      </li>
    </ol> -->
  </div>
  <div class="recommended-brand__brand-slider-mobile" *ngIf="isMobile && 1">
    <div class="brand-slider-mobile__row-wrap">
      <swiper [spaceBetween]="30" [centeredSlides]="true" [autoplay]="{delay: 5000,disableOnInteraction: false}"
        [pagination]="{clickable:true}" [navigation]="true" class="mySwiper">
        <ng-template swiperSlide *ngFor="let list of mobileBrandList.brandLists;">
          <!-- <div class="brand-slider-mobile__row"> -->
          <div class="brand-slider-mobile__col" *ngFor="let item of list.brands">
            <div class="brand-slider-mobile__item">
              <a routerLink="/product/list/{{item.cat1_id}}/{{item.cat2_id}}">
                <img src="{{item.logo}}" alt="">
                <div class="brand-slider-mobile__name">{{item.name2}}</div>
              </a>
            </div>
            <!-- </div> -->
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>
  <!-- <a class="recommended-brand__more-link" routerLink="/product/overview">更多品牌</a> -->
</section>
<!-- <app-modal [isCloseBtnShow]="false" [isOverlayClickable]="false" [isModalOpen]="isModalOpen"
    (modalClose)="isModalOpen=$event">
    <h4 headerContent class="signature-modal__title">系統通知</h4>
    <div bodyContent class="signature-modal__text-wrap">
        <p class="signature-modal__text">{{signatureContent}}</p>
        <div class="signature-modal__checkbox-wrap">
            <input id="signature-checkbox" class="signature-modal__checkbox" type="checkbox"
                [(ngModel)]="isSignatureCheckboxChecked">
            <label for="signature-checkbox" class="signature-modal__label"
                [ngClass]="{'signature-modal__label--active': isSignatureCheckboxChecked}">近一個月不要通知我</label>
        </div>
    </div>
    <div footerContent class="signature-modal__button-wrap">
        <button class="signature-modal__button signature-modal__button--confirm"
            (click)="handleSignatureInfo(true)">同意</button>
        <button class="signature-modal__button signature-modal__button--cancel"
            (click)="handleSignatureInfo(false)">不同意</button>
    </div>
</app-modal> -->

<!-- modal -->
<app-modal [isModalOpen]="isModalOpen" (modalClose)="isModalOpen=$event;">
  <h4 headerContent class="general-modal__title">{{modalTiTle}}</h4>
  <div bodyContent class="general-modal__text-wrap">
    <p class="general-modal__text">{{modalMsg}}</p>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="isModalOpen=false;">確認</button>
  </div>
</app-modal>
