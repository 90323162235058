<div class="container">
  <div class="container">
    <h2 class="contact-title">服務條款</h2>
    <h3 class="contact-title">本平台致力於遵守保護個人資料的相關法令及本平台隱私保護政策，妥善管理和保護會員的隱私權。</h3>
    <ol class="contact-note">
      <li>本平台之會員身分驗證係由悠遊付所協助驗證，本平台並不保留身分證字號等信息。</li>
      <li>如參與商品兌換活動或贈獎活動，本平台可能會請您提供收件人姓名、住址、電話、e-mail或其他相關資料，並以合法公正的方法取得個人資料。</li>
      <li>當您透過本平台進行交易，為辦理收付款金流服務時，本平台將導至悠遊卡股份有限公司所提供之支付方式(悠遊付)，您因使用該支付服務所提供的相關資料之保護及管理，請參閱該交易服務之隱私權政策。</li>
      <li>本平台依照法律告知蒐集目的，除法律另有規定外，本平台僅在下述蒐集目的範圍内使用個人資料。</li>
      <li>本平台採取必要且適當的措施以安全管理取得之個人資料。</li>
      <li>除下述告知範圍或法律另有規定外，本平台不會將個人資料提供給第三人。</li>
      <li>本平台在接獲會員本人提出個人資料的確認、修正、停止使用、刪除等相關要求時，將會依法妥適處理。</li>
      <li class="contact-note--last">
        若您的會員帳號密碼遭到冒用，請聯絡悠遊卡股份有限公司，悠遊卡將依照您的請求處理您的帳號。（如停止使用或刪除等相關要求）另本平台應於知悉您帳號密碼被冒用時，立即暫停該帳號所生交易之處理及後續利用。當您於本平台輸入個人資料並開始使用本服務時，即視為您已清楚瞭解並同意以下本平台蒐集、處理或利用您個人資料之目的及用途。
      </li>
    </ol>
  </div>
</div>
