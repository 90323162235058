<div class="modal js-close" [ngClass]="{'modal--open' : isModalOpen, 'js-close' : isOverlayClickable}"
    (click)="onModalClose($event)">
    <div class="modal__content" [ngClass]="{'modal__content--open': isModalOpen}">
        <div class="modal__header">
            <!-- 插入標題 -->
            <ng-content select="[headerContent]"></ng-content>
            <button *ngIf="isCloseBtnShow" class="modal__close-button js-close" click="onModalClose($event)">+</button>
        </div>
        <div class="modal__body" [ngClass]="{'modal__body--rounded': noFooter}">
            <!-- 插入內容 -->
            <ng-content select="[bodyContent]"></ng-content>
        </div>
        <div class="modal__footer" *ngIf="!noFooter">
            <!-- 插入按鈕 -->
            <ng-content select="[footerContent]"></ng-content>
        </div>
    </div>
</div>