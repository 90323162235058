<h2 class="favorite-title">我的收藏</h2>
<section class="favorite-content" *ngIf="infos.length>0">
    <div>
        <ul class="favorite-list">
            <li class="favorite-list__item favorite-list__item--first-row">
                <span class="favorite-list__cell favorite-list__cell--first-row">取消收藏</span>
                <span class="favorite-list__cell favorite-list__cell--first-row">商品資料</span>
                <span class="favorite-list__cell favorite-list__cell--first-row">兌換方式</span>
                <span class="favorite-list__cell favorite-list__cell--first-row">前往結帳</span>
            </li>
            <li class="favorite-list__item" *ngFor="let item of infos;let isLast = last;"
                [ngClass]="{'favorite-list__item--last-row': isLast}">
                <span class="favorite-list__cell">
                    <a href="#/" (click)="confirmDelete(item.cartID,$event)" class="favorite-list__favorite-icon">
                        <img src="/assets/images/icon/favorite_heart_pink_solid_ring.svg" alt="">
                    </a>
                    <button class="favorite-list__add-to-cart-button" *ngIf="item.remainingProductNum>0"
                        (click)="addToCart(item.productID)">前往結帳
                        <img src="/assets/images/icon/checkout.svg" alt="">
                    </button>
                </span>
                <span class="favorite-list__cell">
                    <span class="favorite-list__image-wrap">
                        <img class="favorite-list__image" src="{{item.img}}" alt="">
                        <span class="out-of-stock-mask" *ngIf="item.remainingProductNum==0">
                            <div class="out-of-stock-mask__circle">
                                <span class="out-of-stock-mask__text">已售完</span>
                            </div>
                        </span>
                    </span>

                    <span class="favorite-list__info">
                        <span class="favorite-list__info-text">{{item.productType}}</span>
                        <span class="favorite-list__info-text">{{item.productName}}
                            <!-- red label -->
                            <span *ngIf="false"
                                class="favorite-list__activity-label-wrap favorite-list__activity-label-wrap--first"><img
                                    class="favorite-list__activity-label"
                                    src="/assets/images/icon/product_red_label.svg" alt="">
                            </span>
                            <!-- green label -->
                            <span *ngIf="false" class="favorite-list__activity-label-wrap"><img
                                    class="favorite-list__activity-label"
                                    src="/assets/images/icon/product_green_label.svg" alt="">
                            </span>
                            <!-- reach label-->
                            <span *ngIf="false"
                                class="favorite-list__activity-label-wrap favorite-list__activity-label-wrap--last"><img
                                    class="favorite-list__activity-label"
                                    src="/assets/images/icon/product_reach_label.svg" alt="">
                            </span>
                        </span>
                    </span>
                </span>
                <span class="favorite-list__cell" data-attr="兌換方式">
                    <span class="favorite-list__point-type-text" *ngIf="item.payType!=2">純點數兌換</span>
                    <span class="favorite-list__point-type-text" *ngIf="item.payType==2">點＋金兌換</span>
                    <span class="point-dollar-number-wrap">
                        <span class="point-dollar-number point-dollar-number--bold">
                            <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
                            <span class="point-dollar-number__text">{{item.point}}點</span>
                        </span>
                        <span class="add-sign" *ngIf="item.payType==2">＋</span>
                        <span class="point-dollar-number point-dollar-number--bold" *ngIf="item.payType==2">
                            <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
                            <span class="point-dollar-number__text">{{item.price}}元</span>
                        </span>
                    </span>
                </span>
                <span class="favorite-list__cell" [attr.data-category]="item.productType" [attr.data-pId]="item.sn">
                    <a href="#"
                        (click)="$event.preventDefault(); item.productTypeCodeID == 14 ? openTicketAlertModal(item.productID, $event) : addToCart(item.productID)"
                        class="favorite-list__add-to-cart-icon" *ngIf="item.remainingProductNum>0">
                        <!-- 購物車有商品時顯示 *ngIf -->
                        <img src="/assets/images/icon/checkout.svg" alt="">
                    </a>
                    <a href="#" (click)="confirmDelete(item.cartID,$event)" class="favorite-list__favorite-icon-mobile">
                        <img src="/assets/images/icon/favorite_heart_pink_solid_ring.svg" alt="">
                    </a>
                </span>
            </li>
        </ul>
    </div>
</section>

<!-- UI for empty condition -->
<section class="favorite-content favorite-content--empty" *ngIf="infos.length==0">
    <div class="favorite-empty__wrap">
        <img class="favorite-empty__image" src="/assets/images/icon/cart_empty.svg" alt="">
        <p class="favorite-empty__text">您還沒有收藏品喔，快去收藏吧</p>
        <a class="favorite-empty__button" [routerLink]="['/']">去購物吧</a>
    </div>
</section>


<!-- modal -->
<app-modal [isModalOpen]="isModalOpen" (modalClose)="isModalOpen=$event">
    <h3 headerContent class="general-modal__title">{{modalTiTle}}</h3>
    <div bodyContent class="general-modal__text-wrap">
        <p class="general-modal__text">{{modalMsg}}</p>
    </div>
    <div footerContent class="general-modal__button-wrap">
        <ng-container *ngIf="isDeleteModalMode">
            <button class="general-modal__button general-modal__button--cancel"
                (click)="handleDeleteModal()">取消</button>
            <button class="general-modal__button general-modal__button--confirm"
                (click)="handleDeleteModal(true)">確認</button>
        </ng-container>

        <ng-container *ngIf="isRefreshModalMode">
            <button class="general-modal__button general-modal__button--cancel"
                (click)="handleRefreshModal()">取消</button>
            <button class="general-modal__button general-modal__button--confirm"
                (click)="handleRefreshModal(true)">確定</button>
        </ng-container>

        <ng-container *ngIf="isTicketModalMode">
            <button class="general-modal__button general-modal__button--cancel"
                (click)="handleTickeAlertModal()">取消</button>
            <button class="general-modal__button general-modal__button--confirm"
                (click)="handleTickeAlertModal(true)">確定</button>
        </ng-container>
    </div>
</app-modal>
