import { LoginService } from '../../../../shared/service/login.service';
import {
  OrderDetailRes,
  ReturnProductReq,
  MbrInfo,
} from '../../../../shared/model/member';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../../shared/service/breadcrumb.service';
import { LoaderService } from '../../../../shared/service/loader.service';
import { PagetagService } from '../../../../shared/service/pagetag.service';
import { environment } from '../../../../../environments/environment';
import { openTicketWallet } from '../../../../shared/service/uupay_js';
import { HeaderService } from 'src/app/shared/service/header.service';
import { Product, ProductType, RelatedNews } from 'src/app/shared/model/product';
import { HomeInfoRes } from 'src/app/shared/model/home';

@Component({
  selector: 'app-member-order-detail',
  templateUrl: './member-order-detail.component.html',
  styleUrls: ['./member-order-detail.component.scss'],
})
export class MemberOrderDetailComponent implements OnInit {
  // id = '';
  orderMainNo = '';
  product: Product;
  productID: string;
  TicktOpen =false;

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private loaderService: LoaderService,
    private loginService: LoginService,
    private pagetagService: PagetagService,
    private router: Router,
    public headerService: HeaderService,
    private breadcrumbServie: BreadcrumbService,
  ) { }

  productTypeName = '';
  // deliveryFinalStepName = '包裹抵達/票券已至您的票夾';
  deliveryFinalStepName = '訂單失敗';

  detailInfo: OrderDetailRes = new OrderDetailRes();
  itemStatusName: string;
  userInfo: MbrInfo;


  //退貨
  returnProductReq = new ReturnProductReq();
  otherRemark: string;
  //sn
  sn: string;
  relatedNews: RelatedNews;
  isInfoShow = false;
  isTicketProductType = false;
  //modal
  isModalOpen = false;
  modalStepCompleted = false;
  modalMessage: string;
  // timer: any;
  // timerLoading: any;
  goHomePage = false;
  isCloseBtnShow = true;
  modalTiTle = "";
  modalMsg = "";

  ngOnInit(): void {
    this.headerService.Order = false;
    this.headerService.Global = false;
    // this.headerService.Footer = false;
    this.userInfo = this.loginService.GetLocalInfo();
    // this.id = this.route.snapshot.params['id'];
    this.orderMainNo = this.route.snapshot.params['orderMainNo'];

    this.breadcrumbService.breadcrumbData.next([
      { breadcrumb: this.orderMainNo, path: '' },
    ]);
    this.getOrderDetail(this.orderMainNo);

    // this.loaderService.start();
    // this.timer = setInterval(() => {
    //   this.getOrderDetail(this.orderMainNo);
    // }, 5000);
    // this.callPagetagService(this.orderMainNo);

    // setTimeout(() => {
    //   this.loaderService.isLoading.subscribe((x) => {
    //     if (x == true) {
    //       this.loaderService.stop();
    //     }
    //   });
    // }, 60000);

    // this.timerLoading = setInterval(() => {
    //   this.loaderService.isLoading.subscribe((x) => {
    //     if (x == false) {
    //       this.loaderService.start();
    //     }
    //   });
    // }, 500);

    this.httpService
    .get('/Home/Home?MbrID=' + this.userInfo.ID)
    .subscribe((x: any) => {
      let homeInfo = x as HomeInfoRes;
      this.productID = homeInfo.hotSellProducts[0].id.toString();
      window.scrollTo({
        top: 0,
      });
      this.getProduct();
    })
  }
  ngOnDestroy(): void {
    this.headerService.Global = true;

  }
  getOrderDetail(MainOrderNo: string) {
    this.loaderService.start();
    this.httpService
      .authGet('/Member/GetOrderDetail?mainOrderNo=' + MainOrderNo)
      .subscribe((x: any) => {
        this.detailInfo = x;

        // this.detailInfo.details=[...this.detailInfo.details].concat([...this.detailInfo.details])
        if (!this.detailInfo.isHasDelivery) {
          this.detailInfo.shippingDT = x.orderDT;
          this.detailInfo.completedDT = x.orderDT;
        }
        this.productTypeName =
          this.detailInfo.details.length > 0
            ? this.detailInfo.details[0].productType
            : '';

        switch (x.orderStatus) {
          case 0:
            this.itemStatusName = '收到訂單';
            break;
          case 1:
            this.itemStatusName = '訂單處理中';
            break;
          case 2: {
            this.loaderService.stop();
            // if (this.productTypeName.includes('票券')) {
            // clearInterval(this.timerLoading);
            // clearInterval(this.timer);
            this.itemStatusName = '交易成功';
            this.deliveryFinalStepName =
              this.productTypeName.length > 0
                ? this.productTypeName.includes('票券')
                  ? '交易成功'
                  : this.productTypeName.includes('宅配')
                    ? '包裹抵達'
                    : '包裹抵達/票券已至您的票夾'
                : '包裹抵達/票券已至您的票夾';
            // }

            break;
          }
          case 3:
          case 5:
          case 6:
            // clearInterval(this.timerLoading);
            // clearInterval(this.timer);
            this.itemStatusName = this.deliveryFinalStepName;
            this.loaderService.stop();
            break;
          case 4:
            // clearInterval(this.timerLoading);
            // clearInterval(this.timer);
            this.itemStatusName = '訂單失敗';
            this.loaderService.stop();
            break;
          // case 5: this.itemStatusName = "申請退貨中"; break;
          // case 6: this.itemStatusName = "退貨完成"; break;
          case 7:
            // clearInterval(this.timerLoading);
            // clearInterval(this.timer);
            this.itemStatusName = '其他';
            this.loaderService.stop();
            break;
        }
        if (
          !this.deliveryFinalStepName.indexOf('抵達') &&
          !this.deliveryFinalStepName.indexOf('票夾')
        ) {
          this.deliveryFinalStepName = this.itemStatusName;
        }
        // switch (x.itemStatus) {
        //   case 0: this.itemStatusName = "收到訂單"; break;
        //   case 1: this.itemStatusName = "訂單處理中"; break;
        //   case 2:{
        //     if(this.productTypeName.includes('票券'))
        //     this.itemStatusName = "交易完成";
        //      break;
        //   }
        //   case 3: case 5: case 6: this.itemStatusName = this.deliveryFinalStepName; break;
        //   case 4: this.itemStatusName = "訂單取消"; break;
        //   // case 5: this.itemStatusName = "申請退貨中"; break;
        //   // case 6: this.itemStatusName = "退貨完成"; break;
        //   case 7: this.itemStatusName = "其他"; break;
        // }
      });
  }
  openReturnProductModal(sn: string) {
    this.modalStepCompleted = false;
    this.returnProductReq.SN = sn;
    this.otherRemark = '';
    this.returnProductReq.Remark = '';
    this.isModalOpen = true;
  }
  returnProduct() {
    if (!this.returnProductReq.Remark) {
      this.modalStepCompleted = true;
      this.modalMessage = '請選擇申請退貨原因';
      return;
    }
    if (this.returnProductReq.Remark == 'other') {
      if (this.otherRemark) {
        this.returnProductReq.Remark = this.otherRemark;
      } else {
        this.modalStepCompleted = true;
        this.modalMessage = '請輸入其他原因';
        return;
      }
    }
    this.isModalOpen = false;
    this.loaderService.start();
    this.httpService
      .post('/Member/ReturnOrder', this.returnProductReq)
      .subscribe((x: any) => {
        this.modalStepCompleted = true;
        this.isModalOpen = true;
        this.modalMessage = x.result;
        let itemNo = this.route.snapshot.params['id'];
        this.getOrderDetail(itemNo);
        this.loaderService.stop();
      });
  }

  callPagetagService(orderID: string) {
    this.pagetagService.callPageTag(
      this.router.url,
      `會員服務 | 訂單查詢 | ${orderID}`
    );
  }
  goTicketWallet() {
    openTicketWallet();
  }

  getProduct() {
    this.loaderService.start();

    this.httpService.get("/Product/" + this.productID).subscribe((x: any) => {//+ "?memberId=" + this.userInfo.ID

      if (x.code != 1) {
        this.goHomePage = true;
        this.isCloseBtnShow = false;
        this.modalTiTle = "提示訊息";
        this.modalMsg = x.result;
        this.isModalOpen = true;
      }

      this.product = x.result;
      // this.limitMsg = this.product.exchangeTypes[0].limitPointMsg;
      // this.isCollect = this.product.exchangeTypes[0].isCollect;
      this.sn = this.product.exchangeTypes[0].sn;
      this.relatedNews = this.product.relatedNews;
      this.isInfoShow = (!this.relatedNews) ? false : true;
      this.isTicketProductType = x.result.productType == ProductType.電子票券;

      // 手機swiper 每兩個為一組
      let products: any[] = [];
      for (let i = 0; i < this.product.recommendProducts.length; i += 2) {
        let item1 = this.product.recommendProducts[i];
        let item2 = this.product.recommendProducts[i + 1];
        if (!item2) {
          products.push([item1]);
        } else {
          products.push([item1, item2]);
        }
      }
      this.product.productLists = products;


      this.breadcrumbServie.breadcrumbData.next([
        { breadcrumb: this.product.categoryName1, path: '/product/list/' + this.product.category1 },
        { breadcrumb: this.product.categoryName2, path: '/product/list/' + this.product.category1 + '/' + this.product.category2 },
        { breadcrumb: this.product.name, path: '' }
      ]);

      this.callPagetagService(`品牌館 | ${this.product.categoryName1} | ${this.product.categoryName2} | ${this.product.name}`);
      this.loaderService.stop();
    });
  }

  goRecommendProductLink(id: any) {
    this.router.navigateByUrl('/product/detail/' + id);
  }
}
