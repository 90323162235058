
export class Product {
  id: number
  category1: number;
  category2: number;
  categoryName1: string;
  categoryName2: string;
  name: string;
  productType?: number
  desc: string;
  img1: string
  img2: string
  img3: string
  img4: string
  img5: string
  prodPrecautions: string
  ticketPrecautions: string
  isSoldOut: boolean;
  exchangeTypes: ExchangeType[]
  relatedNews: RelatedNews;
  label: Label;
  recommendProducts: proListItem[];
  ads: RelatedNews[];
  expiration: string;
  productLists: any;
}
export class ExchangeType {
  id: number
  sn: string;
  type: number;
  point?: number;
  price?: number;
  count: number;
  isLimitPoint: boolean;
  limitPointMsg: string;
  isCollect: boolean;
  discountPrice: number = 0;
}

export class RelatedNews {
  id: number;
  name: string
  cSubTitle: string;
  content: string;
  cType: number;
  cContentType: number;
  cUrl: string;
  cAnother?: number;
  cImg: string = '';
}

export class Label {
  text: string;
  color: string;
  backGroundColor: string;
}

export class Brand {
  id: number
  name: string
  logo?: string
  list?: Brandlist[] | null
}

export class Brandlist {
  cat1_id: number
  cat2_id: number
  name2: string
  logo: string
}

export class ProductListReq {
  AllSearch=false;
  Cat1: number;
  Cat2: number[] = [];
  Keywords: string;
  LowestPrice?: number;
  HishestPrice?: number;
  Sort: string;
  SortType: string;
  Page: number = 1;
  MbrID: string;
  RecommendMode: boolean;
  HomeProType: number = 2;
}
export class ProductListRes {
  cat1Name: string;
  maxPage: number;
  brandList: brandClass[] = [];
  products: proListItem[] = [];
}
export class brandClass {
  img: string;
  cId: number;
  isChoose: boolean;
  sort: number;
  cName: string;
}
export class proListItem {
  id: number;
  img: string;
  name: string;
  isCollect: boolean;
  allSellAmount: number;
  onSaleDT: Date;
  isSoldOut: boolean;
  points?: number;
  pP_Point?: number;
  pP_Price?: number;
  label?: labelItem;
  discountPrice?: number;
}

export class labelItem {
  backGroundColor: string;
  color: string;
  text: string;
}

export enum ProductType {
  電子票券 = 14,
  宅配商品 = 15,
  公益類型 = 27,
}
