import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'hideUsername' })

export class HideUsernamePipe implements PipeTransform {
    transform(text: string) {
        if (text.length < 2) {
            return text;
        };

        if (text.length == 2) {
            text = text.replace(text[1], "*");
            return text;
        }

        for (let i = 0; i < text.length; i++) {
            if (i == 0 || i == (text.length - 1)) { continue; }
            text = text.replace(text[i], "*");
        }

        return text;
    }
}