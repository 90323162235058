<div [ngClass]="{'homefooter': headerService.Footer}">
  <footer class="main-footer" [ngClass]="{'main-footer--home-page': isHomePage}">
    <div class="container">
      <div class="main-footer__site-info">
        <!-- <div class="main-footer__logo-wrap">
        <p class="main-footer__info">
          「悠遊付票券商城平台」由 精誠資訊(喜客券 電子票券) 提供悠遊付會員專屬的票券兌換平台。
        </p>
        <p class="main-footer__info">
          悠遊付會員可在平台內使用悠遊付支付方式兌換各式商品，兌換後可立即前往門市使用，體驗電子票券便利及樂趣。
        </p>
      </div> -->
        <!-- <div class="main-footer__logo-wrap">
                <a [routerLink]="['/']">
                    <img class="main-footer__logo" src="/assets/images/logo/logo_white.svg" alt="悠遊付票券兌換">
                </a>
                <a class="main-footer__site-map-link" [routerLink]="['/sitemap']">網站地圖</a>
                <p class="main-footer__info" style="margin-top: 20px">
                    最佳瀏覽網頁解析度: Chrome 1280以上
                </p>
                <p class="main-footer__info">
                    本平台是由 精誠資訊(喜客券 電子票券) 建置與維運。
                </p>
                <p class="main-footer__info">
                    提供悠遊付會員在平台內使用點數兌換各式商品
                </p>
            </div>
            <div class="main-footer__info-wrap">
                <p class="main-footer__info">
                    【商品兌換相關問題】<br />
                    精誠客服：02-8798-6963
                </p>
                <p class="main-footer__info" style="margin-top: 20px">
                    【點數或其他相關問題】<br />
                    悠客券銀行客服:02-2181-0101<br />
                    悠客券永昌綜合證券客服:02-412-8889<br />
                    悠客券產物保險客服: 02-2761-6969<br />
                    悠客券期貨客服:02-2718-0000
                </p>
                <p class="main-footer__info" style="margin-top: 20px">
                    客服時段：星期一至五 9AM-6PM，不含國定假日與政府機關公佈之停止上班日。
                </p>
            </div> -->
        <div class="linkWrap" [ngClass]="{'isBill': isBill}">
          <!-- <a [routerLink]="['Colophon' ]" class="site-map__link">版權頁</a> -->
          <a class="site-map__link" href="#" (click)="handleCopyrightLinkClick($event)">版權頁</a>
          <a class="site-map__link" href="#" (click)="handlePrivacyLinkClick($event)">服務條款與隱私權政策</a>
          <!-- <a [routerLink]="['Trems' ]" class="site-map__link">服務條款</a> -->
          <!-- <a [routerLink]="['Privacy' ]" class="site-map__link">隱私權政策</a> -->
          <ng-container *ngIf="questionTypeList && questionTypeList.length>0">
            <a [routerLink]="['/question', questionTypeList[0].id]" class="site-map__link">常見問題QA</a>
          </ng-container>
          <a [routerLink]="['/question', 'contact']" class="site-map__link">聯絡我們</a>
        </div>
        <div class="main-footer__copyright-wrap">
          <p class="main-footer__copyright-text">本平台是由精誠資訊(喜客券 電子票券)建置與維運。<br>
            提供悠遊付會員專屬的兌換商品服務。</p>
        </div>
      </div>

      <!-- <div class="main-footer__copyright-wrap">
      <p class="main-footer__copyright">© 版權所有 悠遊卡股份有限公司</p>
    </div> -->
    </div>
  </footer>
</div>

<!-- privacy modal -->

<app-modal [isModalOpen]="isPrivacyModalOpen" (modalClose)="isPrivacyModalOpen=$event">
  <h3 headerContent class="general-modal__title">隱私權保護政策</h3>
  <div bodyContent class="privacy-modal__content-wrap">
    <p class="privacy-modal__text CopyRightText textfullpage">悠客券商城(以下稱本平台)使用者個人資料暨隱私權保護政策，請參閱以下條款：</p>
    <ol class="privacy-modal__number-list">
      <li class="privacy-modal__number-list-item">
        <!-- <span class="privacy-modal__number-list-title">1.本聲明適用之範圍</span> -->
        <span class="privacy-modal__number-list-text textfullpage">
          1.本聲明適用於悠遊付會員藉由電腦或行動裝置連結登入悠客券商城時，即適用本聲明。本聲明不適用於本平台以外之相關網站連結，倘您點選該連結，您必須參考該連結網站中的隱私權保護政策。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">2.個人資料之蒐集聲明</span>
        <span class="privacy-modal__number-list-text textfullpage">
          (1)悠客券商城係由「精誠資訊股份有限公司」(以下稱精誠資訊或本公司)所維運。本平台向您聲明，當您以悠遊付會員身分登入悠客券商城時，本平台之悠遊付會員身分驗證係由悠遊卡股份有限公司之悠遊付產品協助驗證，本平台並不保留身分證字號等信息。
          <br />
          (2)您如參與商品兌換活動或贈獎活動，本平台可能會請您提供收件人姓名、住址、電話、e-mail或其他相關資料，俾便寄送活動兌換之商品或贈送之獎品予您。
          <br>
          (3)當您透過本平台進行交易，為辦理收付款金流服務時，本平台將導至悠遊卡股份有限公司所提供之悠遊付支付方式，您將因進行交易而使用該支付服務，其所提供的相關資料之保護及管理，請參閱該交易服務之隱私權政策。
          <br>
          (4)除此之外，我們也會保留您在上網瀏覽或查詢時，伺服器自行產生的相關紀錄，包括但不限於您使用連線設備的IP位址、使用的瀏覽器、使用時間、瀏覽及點選紀錄等。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">3.蒐集資料之運用聲明</span>
        <span class="privacy-modal__number-list-text textfullpage">
          (1)您於線上商品兌換服務中所提供的個人資訊將會由負責該商品服務的相關單位處理，以便與您聯繫。
          <br>
          (2)各類線上商品兌換或獎品贈送，您所提供的個人資料，將在取得您明確同意的前提下，視蒐集目的分別做為答覆問題、客服通知，票券提供、或是商業及市場分析之用。個人資料之使用目的、利用對象、時間等資訊請參考本平台之內容，從本平台上獲得的資料將只使用在與資料蒐集時所告知您的特定目的上。除非在蒐集時有向您告知並徵得您的同意，否則本平台所蒐集的個人資料，將不會與他人共同使用、亦不會使用在未告知您的用途上。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">4.Cookie 技術之使用</span>
        <span class="privacy-modal__number-list-text textfullpage">
          (1)本平台可能使用cookie技術，為了提供便利使用者提供更適合會員個人需要的服務；cookie是本平台伺服器用來和使用者瀏覽器進行溝通的一種技術，它可能在使用者的電腦或行動裝置中儲存某些資訊，本平台也會讀取儲存在使用者電腦中的cookie資料。您可以經由電腦或行動裝置中瀏覽器的設定，取消、或限制此項功能，但可能因此無法使用部份網站功能。若您想知道如何取消、或限制此項功能，可參照您所使用的瀏覽器關於如何管理cookie的相關說明。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span
          class="privacy-modal__number-list-title textfullpage">5.本平台特定目的外利用個資之聲明：本平台絕不會洩露或轉售您的個人資料給其他與您所參與活動不相關的第三方。惟有下列三種情形，本平台會於特定目的外利用您的個人資料：</span>
        <span class="privacy-modal__number-list-text">
          <!-- 本平台絕不會洩露或轉售您的個人資料給其他與您所參與活動不相關的第三方。惟有下列三種情形，本平台會與第三方共用您的個人資料： -->
          <ul class="privacy-modal__bullet-list">
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              取得您的明確同意或授權允許時。
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              司法單位或其他政府機關經合法正式的程序要求時。
            </li>
            <li class="privacy-modal__bullet-list-item textfullpage">
              <span class="privacy-modal__bullet "></span>
              為了提供您其他服務或優惠權益，需要與提供該服務或優惠之第三人於特定目的外利用您的資料時，本平台會在活動時提供充分說明並告知，您可以自由選擇是否接受這項服務或優惠。
            </li>
          </ul>
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span
          class="privacy-modal__number-list-title textfullpage">6.您將可能在本平台中點選到第三方網站，該等第三方網站的隱私權保護聲明運作，並非由本平台管理，所以於該第三方網站中，不適用本平台隱私權保護聲明。建議您在該等第三方網站中欲提供個人資料前，詳閱該等第三方網站的隱私權保護相關聲明。</span>
        <!-- <span class="privacy-modal__number-list-text">
          在本平台中，您將可能點選到第三方網站。該等第三方網站的隱私權保護聲明運作，並非由本平台管理，所以於該等第三方網站中，不適用本平台隱私權保護聲明。建議您在該等第三方網站中欲提供個人資料前，詳閱該等第三方網站的隱私權保護相關聲明。
        </span> -->
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title textfullpage">7.
          當事人權利行使：除法令另有規定外，您得對本平台所蒐集、處理、利用與保存您的個人資料，要求：</span>
        <span class="privacy-modal__number-list-text">
          <!-- 除法令另有規定外，您得對本平台所蒐集、處理、利用與保存您的個人資料，要求： -->
          <ul class="privacy-modal__bullet-list">
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              撤回先前有關蒐集、處理或利用之同意；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              限制處理或利用方式(限歐盟居民及歐盟居民自歐盟境內所提供之個人資料適用)；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              查詢或閱覽；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              製給複製本；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              將您所提供之個人資料移轉到您所指定的第三方或裝置(限歐盟居民及歐盟居民自歐盟境內所提供之個人資料適用)；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              補充或更正；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              停止蒐集、處理或利用；或刪除。
            </li>
          </ul>
        </span>
      </li>
      <!-- <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-text">
          本平台將依個人資料保護法及相關法令規範，決定是否受理您的申請。<br />
          除法令另有規定外，本平台將於蒐集之特定目的達成或消滅後，依本平台內部規定將您的個人資料銷毀刪除，或將您的個人資料去識別化且後續無法追蹤到您本人的方式留存。
        </span>
      </li> -->
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">8.安全性</span>
        <span class="privacy-modal__number-list-text textfullpage">
          為了防止您所提供的個人資料遭外洩、遺失、濫用、變更或破壞，本平台將使用通常認可的標準技術以及操作上的防護，來保護您的個人資料，例如網站的傳輸過程使用SSL（Secure Socket Layer）作為資料傳輸加密保護機制
          。但由於本平台無法確保不會發生未經授權的存取事件，我們建議您不要將您使用本平台的相關帳號密碼分享給任何第三人；如您與他人共用電腦或行動裝置，你應充分注意於離開本平台前先行登出您的帳號，以防止後續使用者得接觸到您於本平台所留存的個人資訊。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">9.本聲明之修訂</span>
        <span class="privacy-modal__number-list-text textfullpage">
          由於社會環境及法令規定之變遷或科技技術進步，本平台將盡全力保護您的隱私，故本平台有權不定時修訂與公布本項聲明以合時宜。也請您隨時上網參閱本項聲明，以保障您的權益。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">10.會員聯絡資訊</span>
        <span class="privacy-modal__number-list-text textfullpage">
          本平台為保護使用者個人資料，維護網路隱私權，特訂定本隱私權保護聲明，若您對於本隱私權保護聲明、或與個人資料有關之相關事項有任何疑問，或欲行使上述當事人權利時，可利用本平台”聯絡我們”
          詢問，我們會提供最完整的說明。如您認為本平台有違反法令致影響您的權益時，您可與本平台聯繫或有權利向個資保護相關主管機關進行申訴。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        本隱私權聲明最近更新：111年 11月 14日
      </li>
    </ol>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="butn" (click)="isPrivacyModalOpen = false;">確認</button>
  </div>
</app-modal>

<app-modal [isModalOpen]="isCopyrightModalOpen" (modalClose)="isCopyrightModalOpen=$event">
  <h3 headerContent class="general-modal__title">版權聲明</h3>
  <div bodyContent class="privacy-modal__content-wrap ">
    <!-- <p class="privacy-modal__text CopyRightText">本平台致力於遵守保護個人資料的相關法令及本平台隱私保護政策，妥善管理和保護會員的隱私權。</p> -->
    <ol class="privacy-modal__number-list">
      <li class="privacy-modal__number-list-item">
        <!-- <span class="privacy-modal__number-list-title">1.</span> -->
        <span class="privacy-modal__number-list-text">
          1.本平台所使用之軟體或程式、平台網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本平台或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-text">
          2.若您欲引用或轉載前述軟體、程式或網站內容，必須依法取得本平台或其他權利人的事前書面授權同意。尊重智慧財產權是您應盡的義務，如有違反，您應對悠遊卡股份有限公司／精誠資訊股份有限公司負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-text">
          3.在尊重他人智慧財產權之原則下，會員同意在使用本平台服務時，不作侵害他人智慧財產權之行為。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-text">
          4.若會員有涉及侵權之情事，本平台可暫停全部或部份之服務，或逕自以取消會員帳號之方式處理。
        </span>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-text">
          5.若對本平台版權有任何問題，或有發現智慧財產權遭侵害之情事，敬請聯絡本平台，聯絡方式：電子郵件信箱：giftshop@systex.com
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-text">
          6.本聲明之解釋與適用，以及有關的爭議，均應依照中華民國法律予以處理，並以臺灣臺北地方法院為第一審管轄法院。
        </span>
      </li>
      <!-- <p class="privacy-modal__text CopyRightText">會員對於本平台之授權</p>
      <span class="privacy-modal__number-list-text">
        　 對於會員所登錄或留存之個人資料，會員同意本平台網站得於合理之範圍內蒐集、處理、保存、傳遞及使用該等資料，以提供使用者其他資訊或服務、或作成會員統計資料、或進行關於網路行為之調查或研究，或為任何之合法使用。
      </span>
      <p class="privacy-modal__text CopyRightText">準據法與管轄法院</p>
      <span class="privacy-modal__number-list-text">
        　本約定書之解釋與適用，以及與本約定書有關的爭議，均應依照中華民國法律予以處理，並以台灣台北地方法院為第一審管轄法院。
      </span> -->
    </ol>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="butn" (click)="isCopyrightModalOpen = false;">確認</button>
  </div>
</app-modal>
