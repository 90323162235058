<!-- <h2 class="cart-title">購物車</h2> -->
<section class="cart-content" *ngIf="cartNumber>0">
  <section class="process-step">
    <div class="process-step__item process-step__item--completed process-step__item--completed-last" >
      <span class="process-step__text">
      1 確認訂單
      </span>
    </div>
    <!-- <div class="process-step__item">
      <span class="process-step__text">
     2 交易成功
      </span>
    </div> -->
    <div class="process-step__item-gray process-step__item--completed process-step__item--completed-last" >
      <span class="process-step__text-gray">
      2 交易成功
      </span>
    </div>
  </section>

  <!-- <a class="privacy-link" href="#" (click)="handlePrivacyLinkClick($event)">網站隱私權保護聲明</a> -->

    <ul class="product-list">
      <!-- <li class="product-list__item product-list__item--first-row">
        <span class="product-list__cell product-list__cell--first-row">商品資料/商品編號</span>
        <span class="product-list__cell product-list__cell--first-row">兌換金額</span>
        <span class="product-list__cell product-list__cell--first-row">數量</span>
        <span class="product-list__cell product-list__cell--first-row">小計</span> -->
        <!-- <span class="product-list__cell product-list__cell--first-row">功能</span> -->
      <!-- </li> -->
      <div class="itemback">
      <span class="cart-title detail">商品資訊</span>
      <li class="product-list__item" *ngFor="let item of infos;let isLast = last;"
        [ngClass]="{'product-list__item--last-row': isLast}">
        <span class="product-list__cell">
          <img class="product-list__image" src="{{item.img}}" alt="">
          <span class="product-list__info">
            <span class="product-list__info-text">{{item.productType}}</span>

            <span class="product-list__info-text"
              [ngClass]="{'product-list__info-text--mb5mobile': item.limitPointType}">{{item.productName}}
              <!-- red label -->
              <span *ngIf="false"
                class="product-list__activity-label-wrap product-list__activity-label-wrap--first"><img
                  class="product-list__activity-label" src="/assets/images/icon/product_red_label.svg" alt="">
              </span>
              <!-- green label -->
              <span *ngIf="false" class="product-list__activity-label-wrap"><img class="product-list__activity-label"
                  src="/assets/images/icon/product_green_label.svg" alt="">
              </span>
              <!-- reach label-->
              <span *ngIf="false" class="product-list__activity-label-wrap product-list__activity-label-wrap--last"><img
                  class="product-list__activity-label" src="/assets/images/icon/product_reach_label.svg" alt="">
              </span>
            </span>
            <span class="product-list__info-text">{{item.sn}}</span>
            <span class="point-dollar-number point-dollar-number--bold point-dollar-number--mt10p cartprice"
              *ngIf="item.payType==2">
              <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
              <span class="point-dollar-number__text" style="font-size:14px;">{{item.price}}元</span>
            </span>
            <div class="input-group">
              <label class="input-group__label" for="Number_Products">
                商品數量
              </label>
              <!-- <input class="purchinput" id="Number_Products" type="number" placeholder="請輸入商品數量"
                [(ngModel)]="item.amount" (keyup)="amountChange($event,item)"> -->
                <select  class="input-group__select input-group__select--receipt-info"
                name="receipt-category" id=""  [(ngModel)]="item.amount" (change)="amountChange($event,item)">
                *ngFor="let item of items"
                <option selected value={{item}} *ngFor="let item of numbertem">{{item}}</option>
                <!-- <option value=2>手機條碼</option>
                <option value=3>自然人憑證</option>
                <option value=4>發票捐贈</option> -->
              </select>

            </div>
            <span *ngIf="item.limitPointType" class="product-list__info-text product-list__info-text--limit-point-type">
              <span><img src="/assets/images/icon/shock_mark_white.svg" alt=""></span>
              <span>限定 使用{{item.limitPointType}}</span>
            </span>
            <!-- <span class="product-list__info-text product-list__info-text--mobile-amount-button-wrap">
              <button class="product-list__amount-button" (click)="calc(item,'-')">-</button>
              <button class="product-list__amount-button">{{item.amount}}</button>
              <button class="product-list__amount-button" (click)="calc(item,'+')">+</button>
            </span> -->
          </span>
        </span>
        <!-- <span class="product-list__cell" data-attr="票券價格">
          <span class="point-dollar-number point-dollar-number--bold">
            <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
            <span class="point-dollar-number__text">{{item.point}}元</span>
          </span>
          <span class="point-dollar-number point-dollar-number--bold point-dollar-number--mt10p"
            *ngIf="item.payType==2">
            <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
            <span class="point-dollar-number__text purchPrice">{{item.price}}元</span>
          </span>
        </span>
        <span class="product-list__cell">
          <span>
            <button class="product-list__amount-button" (click)="calc(item,'-')">-</button>
            <button class="product-list__amount-button">{{item.amount}}</button>
            <button class="product-list__amount-button" (click)="calc(item,'+')">+</button>
          </span>
        </span> -->
        <!-- <span class="product-list__cell" data-attr="小計">
          <div>
            <span class="point-dollar-number point-dollar-number--bold">
              <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
              <span class="point-dollar-number__text">{{item.point*item.amount}}點</span>
            </span>
            <span class="add-sign" *ngIf="item.payType==2">＋</span>
            <span class="point-dollar-number point-dollar-number--bold point-dollar-number--desktop-mt10p"
              *ngIf="item.payType==2">
              <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
              <span class="point-dollar-number__text purchPrice">{{item.price*item.amount}}元</span>
            </span>
          </div>
        </span> -->
        <!-- <span class="product-list__cell" [attr.data-category]="item.productType" [attr.data-pId]="item.sn">
          <span class="product-list__close-icon" (click)="initDeleteModal(item,false)">+</span>
        </span> -->
        <!-- 商品不足時顯示 -->
        <span class="product-list__empty-mask" *ngIf="item.remainingProductNum<=0">
          <button class="product-list__empty-button" (click)="initDeleteModal(item,true)">商品已銷售完畢，請點選移除商品</button>
          <p class="product-list__empty-note">
            <span class="note__icon-wrap">
              <img class="note__icon" src="/assets/images/icon/shock_mark_white.svg" alt="">
            </span>
            <span>提醒您，移除後商品會加入您的收藏清單中</span>
          </p>
        </span>
      </li>
    </div>
    </ul>
    <section class="cart-form">
      <div class="row">
        <div class="delivery-info-wrap col-12"
          [ngClass]="{'col-lg-6':isHasGoldFlow || hasCharityTypeProduct,'delivery-info-wrap--full': !isHasGoldFlow && !hasCharityTypeProduct}">
          <div class="delivery-info" [ngClass]="{'delivery-info--half': !isHasGoldFlow && !hasCharityTypeProduct}"
            [hidden]="!isHasDelivery">
            <h3 class="cart-form__title">宅配資訊<span>*</span></h3>
            <div class="input-group">
              <label class="input-group__label" for="receiver-name">
                收件人
              </label>
              <input class="input-group__input" id="receiver-name" type="text" placeholder="請輸入姓名"
                [(ngModel)]="checkoutReq.Recipient">
            </div>
            <div class="input-group">
              <label class="input-group__label" for="receiver-phone">
                收件人電話
              </label>
              <input class="input-group__input" id="receiver-phone" type="text" placeholder="請輸入收件人電話"
                [(ngModel)]="checkoutReq.Tel">
            </div>
            <div class="input-group input-group--address-city">
              <label class="input-group__label" for="receiver-address">
                收件地址
              </label>
              <div>
                <select #receiverAddressCity class="input-group__select input-group__select--address-city"
                  name="address-city" id="" [(ngModel)]="checkoutReq.City"
                  (change)="zipcodeFilter(receiverAddressCity.value, 'receiver')">
                  <option disabled selected="selected" value="">城市/縣</option>
                  <option [value]="item.id" *ngFor="let item of counties">{{item.id}}</option>
                </select>
                <select class="input-group__select input-group__select--address-city" name="address-town" id=""
                  [(ngModel)]="checkoutReq.Area">
                  <option disabled selected="selected" value="">地區/鄉鎮</option>
                  <option [value]="item.city+'|'+item.zipcode" *ngFor="let item of selectedZipcodeForReceiverAddress">
                    {{item.city}}
                  </option>
                </select>
              </div>
            </div>
            <div class="input-group input-group--address-street">
              <input class="input-group__input input-group__input--address-street" id="receiver-address" type="text"
                placeholder="請輸入詳細地址" [(ngModel)]="checkoutReq.Address">
            </div>
            <div class="address-checkbox-section">
              <div>
                <input id="address-cBox" class="address-checkbox-section__input" type="checkbox"
                  (click)="isAddressCheckboxChecked=!isAddressCheckboxChecked">
                <label for="address-cBox" class="address-checkbox-section__label"
                  [ngClass]="{'address-checkbox-section__label--active': isAddressCheckboxChecked}">加入常用地址</label>
              </div>
              <a class="address-checkbox-section__link" href="#" (click)="addressClick($event)">常用地址選擇</a>
              <!-- modal -->
              <!-- 傳入modalOpen property -->
              <app-modal [isModalOpen]="isAddressModalOpen" (modalClose)="isAddressModalOpen=$event">
                <!-- 加上headerContent 字段 會置於modal 標題處 -->
                <h4 headerContent class="address-modal__title">常用地址選擇</h4>
                <!-- 加上bodyContent 字段 會置於modal 內容處 -->
                <div bodyContent class="address-modal__list-wrap">
                  <ul class="address-modal__list">
                    <li class="address-modal__item">
                      <span class="address-modal__cell address-modal__cell--first-row">選擇</span>
                      <span class="address-modal__cell address-modal__cell--first-row">收件人</span>
                      <span class="address-modal__cell address-modal__cell--first-row">收件人電話</span>
                      <span class="address-modal__cell address-modal__cell--first-row">收件地址</span>
                      <span class="address-modal__cell address-modal__cell--first-row">刪除</span>
                    </li>
                    <li class="address-modal__item" *ngFor="let item of commonAddressInfo;let i =index">
                      <span class="address-modal__cell">
                        <input class="address-modal__radio" name="common-address-radio" type="radio" value="{{i}}"
                          (change)="chooseCommonAddress($event)">
                      </span>
                      <span class="address-modal__cell">{{item.cRecipent}}</span>
                      <span class="address-modal__cell">{{item.cRecipentTel}}</span>
                      <span class="address-modal__cell">
                        <span>
                          {{item.cZipCode}}
                        </span>
                        <span>
                          {{item.cCity+item.cArea+item.cAddress}}
                        </span>
                      </span>
                      <span class="address-modal__cell">
                        <span (click)="deleteCommonAddress(item.cId)">
                          +
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div footerContent class="address-modal__button-wrap">
                  <button class="address-modal__button address-modal__button--cancel address-modal__cancel-button"
                    (click)="isAddressModalOpen=false">取消</button>
                  <button class="address-modal__button address-modal__button--confirm address-modal__confirm-button"
                    (click)="confirmCommonAddress(false)">確認</button>
                </div>
              </app-modal>
              <!-- modal -->
            </div>
          </div>
          <!-- <div class="contact-info" [ngClass]="{'contact-info--half': !isHasGoldFlow && isHasDelivery && !hasCharityTypeProduct,
                        'contact-info--full': !isHasGoldFlow && !isHasDelivery && !hasCharityTypeProduct}">
            <h3 class="cart-form__title">
              聯絡資訊<span>*</span>
            </h3>
            <div class="input-group input-group--contact-email contact-info__contact-email-wrap"
              [ngClass]="{'contact-info__contact-email-wrap--full': !isHasGoldFlow && !isHasDelivery && !hasCharityTypeProduct}">
              <label class="input-group__label" for="contact-email">
                聯繫E-mail
              </label>
              <input class="input-group__input" id="contact-email" type="text" placeholder="test@gmail.com"
                [(ngModel)]="checkoutReq.Email">
            </div>
            <div class="contact-info__recent-email-wrap"
              [ngClass]="{'contact-info__recent-email-wrap--full': !isHasGoldFlow && !isHasDelivery && !hasCharityTypeProduct}"> -->
          <!-- <a class="contact-info__recent-email-link" href="javascript:void(0)"
                (click)="getLastOrderAddress()">帶入上一筆訂單郵件資訊</a> -->
          <!-- </div>
          </div> -->
        </div>
        <div class="col-12 col-lg-6" [hidden]="!isHasGoldFlow && !hasCharityTypeProduct">
          <!-- <div class="payment-info" [hidden]="!isHasGoldFlow">
            <h3 class="cart-form__title">
              支付與發票資訊<span>*</span>
            </h3>
            <h4 class="payment-info__sub-title">付款方式</h4>
            <div class="payment-info__button-wrap">
              <button class="payment-info__button" [ngClass]="{'payment-info__button--active': paymentMethod==1}"
                (click)="paymentMethodChange(1)">信用卡</button>
              <button *ngIf="isMobileDevice" class="payment-info__button"
                [ngClass]="{'payment-info__button--active': paymentMethod==2}"
                (click)="paymentMethodChange(2)">台灣Pay</button>
              <button class="payment-info__button" [ngClass]="{'payment-info__button--active': paymentMethod==3}"
                (click)="paymentMethodChange(3)">悠遊付</button>
            </div>
          </div> -->
          <div class="buyer-receipt-info">
            <!--*ngIf="!(receiptType==4 && !hasCharityTypeProduct)" -->
            <h3 class="cart-form__title">
              購買者發票/資訊<span>＊</span>
            </h3>
            <div class="buyer-receipt-info__text-wrap">
              <p class="buyer-receipt-info__text">
                1.因開立發票需有您的個人資料，請您務必輸入正確購買者資訊。(如您同意預存發票資訊，日後購買商品時將自動帶出。)<br>
                2.若發票中獎，發票類型選擇電子信箱之用戶，紙本發票將會以掛號寄送到您提供的資料並以e-mail通知您。</p>
              <!-- <div class="checkcenter">
              <div  class="anonymous-checkbox-wrap">
                  <input id="anonymous-cBox" class="signature-modal__input" type="checkbox">
                 <p>紀錄上一次資料</p>
                </div>
                </div> -->

              <!-- <p class="buyer-receipt-info__text">
                紀錄上一次資料</p>
                <a class="privacy-link" href="#" (click)="handlePrivacyLinkClick($event)">我同意悠客券的網站 服務條款 及 隱私權政策</a> -->
            </div>
            <div class="input-group" style="margin-bottom: 10px;">
              <label class="input-group__label input-group__label--for-select" for="receipt-category">
                發票類型
              </label>
              <select (change)="resetReceiptInfoInput()" class="input-group__select input-group__select--receipt-info"
                name="receipt-category" id="" [(ngModel)]="receiptType" (ngModelChange)="changeInvoiceType($event)">
                <option selected value=1>電子信箱</option>
                <option value=2>手機條碼</option>
                <option value=3>自然人憑證</option>
                <option value=4>發票捐贈</option>
                <option value=5>三聯式發票</option>
              </select>
            </div>
            <!-- ngIf -->
            <div class="receipt-info__text-wrap" *ngIf="receiptType==1">
              <input class="input-group__input" id="contact-email" type="text" placeholder="請輸入電子信箱"
                [(ngModel)]="checkoutReq.Email">
              <p class="receipt-info__text">
                訂單發票資訊將會寄送至您填寫的信箱
              </p>
            </div>
            <div class="receipt-info__text-wrap" *ngIf="receiptType==2">
              <input (input)="onInputChange()" [(ngModel)]="receiptInfoInput2"
                class="receipt-info__input" type="text" placeholder="/1234ABC">
              <span class="receipt-info__warning"
                *ngIf="!isReceiptInfoInputValid(receiptInfoInput2) && isReceiptInfoInput2Changed && receiptInfoInput2.trim().length>0">請輸入正確格式</span>
              <p class="receipt-info__text">
                請先至財政部電子發票整合服務平台申請手機條碼，請輸入條碼下方「符號及大寫英文及數字」共8碼。(範例：/1234ABC)
              </p>
            </div>
            <div class="receipt-info__text-wrap" *ngIf="receiptType==3">
              <input (input)="isReceiptInfoInput3Changed=true" [(ngModel)]="receiptInfoInput3"
                class="receipt-info__input" type="text" placeholder="AB123 456 7890 1234">
              <span class="receipt-info__warning"
                *ngIf="!isReceiptInfoInputValid(receiptInfoInput3) && isReceiptInfoInput3Changed && receiptInfoInput3.trim().length>0">請輸入正確格式</span>
              <p class="receipt-info__text">
                請先至有辦理自然人憑證業務之戶政事務所申請，請輸入憑證條碼下方「大寫英文數字」。(範例：AB123 456 7890 1234)
              </p>
            </div>
            <div class="receipt-info__text-wrap" *ngIf="receiptType == 5">
              <!-- db最多50字 -->
              <input [(ngModel)]="receiptInfoInput5Name"
                class="receipt-info__input" type="text" placeholder="請輸入公司名稱" [maxlength]="42">
            </div>
            <div class="receipt-info__text-wrap" *ngIf="receiptType == 5">
              <input [(ngModel)]="receiptInfoInput5Number"
                class="receipt-info__input" type="text" placeholder="請輸入統一編號" (change)="checkCompanyNumber()" [maxlength]="8">
              <span class="receipt-info__warning" *ngIf="!isCompanyNumberPass && receiptInfoInput5Number"
                >請輸入正確統編格式</span>
            </div>
            <div class="receipt-info__text-wrap" *ngIf="receiptType==4">
              <p class="receipt-info__text">
                如您於訂購時選擇將發票捐贈，系統會將此次的交易發票捐贈給「財團法人創世社會福利基金會(愛心代碼:919)」，依據法令規定，已捐贈的發票無法索取紙本發票及更改捐贈對象，如有退換貨需求，精誠資訊股份有限公司將會將該發票作廢。
              </p>
            </div>
            <!-- ngIf -->

            <div class="receipt-info">
              <h3 class="cart-form__title">
                發票資訊<span>＊</span>
              </h3>
              <div class="input-group" style="margin-bottom: 10px;">
                <label class="input-group__label" for="buyer-receipt-name">
                  姓名
                </label>
                <input [(ngModel)]="checkoutReq.InvoiceName" type="text" class="input-group__input"
                  id="buyer-receipt-name">
              </div>
              <div class="input-group" style="margin-bottom: 10px;">
                <label class="input-group__label" for="buyer-receipt-phone">
                  聯絡電話
                </label>
                <input [(ngModel)]="checkoutReq.InvoicePhone" maxlength="10" (keypress)="phoneCheck($event)" type="text"
                  class="input-group__input" id="buyer-receipt-phone">
              </div>
              <div class="input-group input-group--address-city">
                <label class="input-group__label" for="buyer-receipt-address">
                  地址
                </label>
                <div>
                  <select [(ngModel)]="checkoutReq.InvoiceCity" #receiptAddressCity
                    class="input-group__select input-group__select--address-city" name="buyer-receipt-address-city"
                    (change)="zipcodeFilter(receiptAddressCity.value, 'receipt')">
                    <option disabled selected="selected" value="">城市/縣</option>
                    <option [value]="item.id" *ngFor="let item of counties">{{item.id}}</option>
                  </select>
                  <select [(ngModel)]="checkoutReq.InvoiceArea"
                    class="input-group__select input-group__select--address-city" name="buyer-receipt-address-town">
                    <option disabled selected="selected" value="">地區/鄉鎮</option>
                    <option [value]="item.city+'|'+item.zipcode" *ngFor="let item of selectedZipcodeForReceiptAddress">
                      {{item.city}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="input-group input-group--address-street">
                <input [(ngModel)]="checkoutReq.InvoiceAddress"
                  class="input-group__input input-group__input--address-street" id="buyer-receipt-address" type="text"
                  placeholder="請輸入詳細地址">
              </div>
              <div style="color:#E7007F">＊請填寫正確的發票資訊，以免無法將中獎發票寄出。</div>
              <div class="buyer-receipt-info__recent-address-wrap">
                <div *ngIf="hasCharityTypeProduct" class="anonymous-checkbox-wrap">
                  <input id="anonymous-cBox" class="anonymous-checkbox-wrap__input" type="checkbox"
                    (click)="IsCharityAnonymousCheckboxChecked=!IsCharityAnonymousCheckboxChecked">
                  <label for="anonymous-cBox" class="anonymous-checkbox-wrap__label"
                    [ngClass]="{'anonymous-checkbox-wrap__label--active': IsCharityAnonymousCheckboxChecked}">不同意捐款姓名公開揭露
                  </label>
                </div>
                <!-- <a href="#" class="buyer-receipt-info__recent-address-link"
                  (click)="$event.preventDefault();getLastInvoiceInfo()">帶入上一筆資訊</a> -->
              </div>
            </div>


            <div class="checkandbring">
              <div>
                <!-- <a class="bring-Information" href="#" *ngIf="userInfo.ID !=null" (click)="$event.preventDefault();getLastInvoiceInfo()">帶入上一筆資訊</a> -->
                <div class="checkcenter">
                  <input id="signature-checkbox01" class="signature-modal__checkbox" type="checkbox"
                    [(ngModel)]="checkoutReq.IsAgreeInvoiceInfo" />
                  <label for="signature-checkbox01" class="signature-modal__label" [ngClass]="{
                'signature-modal__label--active':checkoutReq.IsAgreeInvoiceInfo
              }"> <a class="privacy-black" >我同意預存發票資訊</a></label>
                </div>
                <div class="checkcenter">
                  <input id="signature-checkbox02" class="signature-modal__checkbox" type="checkbox"
                    [(ngModel)]="isReadpersonalCheckbox" />
                  <label for="signature-checkbox02" class="signature-modal__label" [ngClass]="{
                'signature-modal__label--active':isReadpersonalCheckbox
              }"> <a class="privacy-black" href="#" (click)="handlePrivacyLinkClick($event)">我同意<p class="privacy-link">悠客券
                      服務條款及隱私權政策</p></a></label>
                </div>
                <div class="checkcenter">
                  <input id="signature-checkbox03" class="signature-modal__checkbox" type="checkbox"
                    [(ngModel)]="isReturnCheckbox" />
                  <label for="signature-checkbox03" class="signature-modal__label" [ngClass]="{
                'signature-modal__label--active':isReturnCheckbox
              }"> <a class="privacy-black" >我同意訂單銷貨辦理退貨時，由開立發票單位（精誠資訊股份有限公司）代為處理發票及銷貨退回證明。</a></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order-top-info row">
        <!-- <div class="order-top-info__title-wrap">
          <div>
            <h3 class="cart-form__title">訂單資訊</h3>
          </div>
        </div> -->
        <div class="order-top-info__list-wrap">
          <ul class="order-top-info__list">
            <!-- <li class="order-top-info__item">
              <span class="order-top-info__cell">商品總點數</span>
              <span class="point-dollar-number">
                <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
                <span class="point-dollar-number__text">{{this.totalPoint}}點</span>
              </span>
            </li> -->
            <!-- <li class="order-top-info__item">
              <span class="order-top-info__cell">商品總金額</span>
              <span class="point-dollar-number">
                <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
                <span class="point-dollar-number__text">{{this.totalPrice}}元</span>
              </span>
            </li> -->
            <li class="order-top-info__item order-top-info__item--activity"
              *ngIf="this.RGDiscountPoint>0||this.FDiscountPoint>0">
              <span class="order-top-info__cell order-top-info__cell--activity-title">活動優惠</span>
              <span class="order-top-info__cell order-top-info__cell--activity-content">
                <span *ngIf="this.RGDiscountPoint>0 ">紅配綠 -
                  <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
                  <span class="point-dollar-number__text">{{this.RGDiscountPoint}}點</span></span>
                <span *ngIf=" this.FDiscountPoint>0">滿額贈 -
                  <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
                  <span class="point-dollar-number__text">{{this.FDiscountPoint}}點</span></span>

              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="order-bottom-info row">
        <div class="order-bottom-info__blank-wrap"></div>
        <div class="order-bottom-info__list-wrap">
          <ul class="order-bottom-info__list">
            <li class="order-bottom-info__item">
              <span>點數資訊</span>
              <a href="/" class="order-bottom-info__link" (click)="checkPointInfo($event)">
                查看點數資訊
              </a>
            </li>
            <li class="order-bottom-info__item">
              <span>
                <span>應支付點數</span>
                <span class="point-note" (click)="isMobileTooltipOpen=!isMobileTooltipOpen">
                  <img src="/assets/images/icon/shock_mark_white.svg" alt="">
                  <span class="point-note-tooltip">
                    <span>提醒您，您可自行選擇支付點數的比例</span>
                  </span>
                </span>
              </span>
              <span class="point-dollar-number">
                <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
                <span
                  class="point-dollar-number__text">{{this.totalPoint-this.RGDiscountPoint-this.FDiscountPoint}}點</span>
              </span>
            </li>
            <li class="order-bottom-info__item" [ngClass]="{'order-bottom-info__item--open': isMobileTooltipOpen}">
              <span class="point-note-tooltip-mobile"
                [ngClass]="{'point-note-tooltip-mobile--open': isMobileTooltipOpen}">
                <span>提醒您，您可自行選擇支付點數的比例</span>
              </span>
            </li>
            <li class="order-bottom-info__item">
              <div class="order-bottom-info__input-outer-wrap">
                <label class="order-bottom-info__label" for="v-bonus">悠遊付點數</label>
                <div class="order-bottom-info__input-inner-wrap">
                  <input class="order-bottom-info__input" id="v-bonus" type="number" min="0"
                    [(ngModel)]="checkoutReq.VBonus" (keydown)="avoidNumberTypeChar($event)"
                    (input)="filterNumberValue($event)" (change)="distributeMbrPoint('VBonus')"
                    placeholder="至少支付 {{addThouthandMark(limitVBonus)}}點 悠遊付點數">
                </div>
              </div>
              <p class="order-bottom-info__point-text">
                您尚有<span>{{addThouthandMark(userInfo.Bonus)}}</span>點，即將到期({{userInfo.ClearVBonusDate |
                date:'yyyy/MM/dd' }})點數為
                <span>{{addThouthandMark(userInfo.ClearVBonus)}}</span>點
              </p>
            </li>
            <li class="order-bottom-info__item">
              <div class="order-bottom-info__input-outer-wrap">
                <label class="order-bottom-info__label" for="card-bonus">信用卡紅利點數</label>
                <div class="order-bottom-info__input-inner-wrap">
                  <input class="order-bottom-info__input" id="card-bonus" type="number" min="0"
                    [(ngModel)]="checkoutReq.CardBonus" (keydown)="avoidNumberTypeChar($event)"
                    (input)="filterNumberValue($event)" (change)="distributeMbrPoint('CardBonus')"
                    placeholder="至少支付 {{addThouthandMark(limitCardBonus)}}點 信用卡紅利點數">
                </div>
              </div>
              <p class="order-bottom-info__point-text order-bottom-info__point-text--credit-card">
                您尚有<span>{{addThouthandMark(userInfo.CardBonus)}}</span>點，即將到期({{userInfo.ClearCardDate
                | date:'yyyy/MM/dd' }})點數為
                <span>{{addThouthandMark(userInfo.ClearCardBonus)}}</span>點
              </p>
            </li>
          </ul>
        </div>
      </div> -->
      <div class="process-step-button row">
        <div class="process-step-button__button-wrap">
          <!-- <button class="process-step-button__button process-step-button__button--back"
            (click)="setCartStepCompleted(2)">
            <img class="process-step-button__icon" src="/assets/images/icon/arrow_left_black.svg" alt="">
            返回上一頁
          </button> -->
          <div class="order-top-info-cell">
            <span class="order-top-info__cell">訂單金額</span>
            <span class="point-dollar-number purchPrice">
              <!-- <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt=""> -->
              <span class="point-dollar-number__text">${{this.totalPrice}}元</span>
            </span>
          </div>
          <button class="process-step-button__button process-step-button__button--next" style="height: 45px;" (click)="sendValidate()">
            前往結帳
          </button>
          <!-- <button class="process-step-button__button process-step-button__button--next"
          (click)="sendValidate();confirmModalStep=1">
          前往結帳
        </button> -->
        </div>
      </div>
    </section>

</section>
<!-- alertModal -->
<app-modal [isModalOpen]="isAlertModalOpen" (modalClose)="isAlertModalOpen=$event">
  <h4 headerContent class="general-modal__title">提示訊息</h4>
  <div bodyContent class="general-modal__text-wrap">
    <p class="general-modal__text" *ngFor="let row of alertMsg.split('br')">{{row}}</p>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="isAlertModalOpen=false">確認</button>
  </div>
</app-modal>
<!-- confirmModal -->
<app-modal [isModalOpen]="isComfirmModalOpen" (modalClose)="isComfirmModalOpen=$event">
  <h4 headerContent class="general-modal__title">提示訊息</h4>
  <div bodyContent class="general-modal__text-wrap">
    <ng-container *ngIf="confirmModalStep==1">
      <p class="general-modal__text" *ngFor="let word of preConfirmMessage.split('br/')">{{word}}</p>
    </ng-container>
    <ng-container *ngIf="confirmModalStep==2">
      <p class="general-modal__text" *ngFor="let word of confirmMessege.split('br/')">{{word}}</p>
    </ng-container>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--cancel" (click)="isComfirmModalOpen=false">取消</button>
    <button *ngIf="confirmModalStep==1" class="general-modal__button general-modal__button--confirm"
      (click)="confirmModalStep=2">確認</button>
    <button *ngIf="confirmModalStep==2" class="general-modal__button general-modal__button--confirm"
      (click)="checkout()">確認</button>
  </div>
</app-modal>
<!-- deleteModal -->
<app-modal [isModalOpen]="isDeleteModalOpen" (modalClose)="isDeleteModalOpen=$event">
  <h4 headerContent class="general-modal__title">刪除商品</h4>
  <div bodyContent class="general-modal__text-wrap">
    <p class="general-modal__text">您確定要移此此項商品?</p>
    <p class="general-modal__text">{{deleteModalData.productName}}</p>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--cancel" (click)="isDeleteModalOpen=false">取消</button>
    <button class="general-modal__button general-modal__button--confirm"
      (click)="deleteCart(deleteModalData)">確認</button>
  </div>
</app-modal>

<div class="cart-content cart-content--empty" *ngIf="cartNumber==0">
  <div class="cart-empty__wrap">
    <img class="cart-empty__image" src="/assets/images/icon/cart_empty.svg" alt="">
    <p class="cart-empty__text">您的購物車是空的</p>
    <a class="cart-empty__button" [routerLink]="['/']">去購物吧</a>
  </div>
</div>



<!-- <form id="PosForm" method="POST" [action]="posUrl" hidden>
  <input name="MerchantID" id="merchantId" />
  <input name="TerminalID" id="terminalId" />
  <input name="MerchantName" id="merchantName" />
  <input name="lidm" id="lidm" />
  <input name="merID" id="merId" />
  <input name="customize" id="customize" />
  <input name="PageVer" id="pageVer" />
  <input name="purchAmt" id="purchAmt" />
  <input name="txType" id="txType" />
  <input name="checkValue" id="checkValue" />
  <input name="NumberOfPay" id="numberOfPay" />
  <input name="encode" id="encode" />
  <input name="AutoCap" id="autoCap" />
  <input name="AuthResURL" id="authResURL" />
  <input name="AuthInfoPage" id="authInfoPage" />
</form>

<form id="TWPayForm" method="POST" [action]="twPayUrl" hidden>
  <input name="acqBank" id="acqBank" />
  <input name="terminalId" id="terminalId" />
  <input name="merchantId" id="merchantId" />
  <input name="encQRCode" id="encQRCode" />
  <input name="encRetURL" id="encRetURL" />
  <input name="orderNumber" id="orderNumber" />
  <input name="txnType" id="txnType" />
  <input name="walletList" id="walletList" />
  <input name="preferWalletList" id="preferWalletList" />
  <input name="verifyCode" id="verifyCode" />
</form> -->

<!-- privacy modal -->

<app-modal [isModalOpen]="isPrivacyModalOpen" (modalClose)="isPrivacyModalOpen=$event">
  <h3 headerContent class="general-modal__title__privacy">隱私權保護政策</h3>
  <div bodyContent class="privacy-modal__content-wrap">
    <p class="privacy-modal__text CopyRightText textfullpage">悠客券商城(以下稱本平台)使用者個人資料暨隱私權保護政策，請參閱以下條款：</p>
    <ol class="privacy-modal__number-list">
      <li class="privacy-modal__number-list-item">
        <!-- <span class="privacy-modal__number-list-title">1.本聲明適用之範圍</span> -->
        <span class="privacy-modal__number-list-text textfullpage">
          1.本聲明適用於悠遊付會員藉由電腦或行動裝置連結登入悠客券商城時，即適用本聲明。本聲明不適用於本平台以外之相關網站連結，倘您點選該連結，您必須參考該連結網站中的隱私權保護政策。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">2.個人資料之蒐集聲明</span>
        <span class="privacy-modal__number-list-text textfullpage">
          (1)悠客券商城係由「精誠資訊股份有限公司」(以下稱精誠資訊或本公司)所維運。本平台向您聲明，當您以悠遊付會員身分登入悠客券商城時，本平台之悠遊付會員身分驗證係由悠遊卡股份有限公司之悠遊付產品協助驗證，本平台並不保留身分證字號等信息。
          <br />
          (2)您如參與商品兌換活動或贈獎活動，本平台可能會請您提供收件人姓名、住址、電話、e-mail或其他相關資料，俾便寄送活動兌換之商品或贈送之獎品予您。
          <br>
          (3)當您透過本平台進行交易，為辦理收付款金流服務時，本平台將導至悠遊卡股份有限公司所提供之悠遊付支付方式，您將因進行交易而使用該支付服務，其所提供的相關資料之保護及管理，請參閱該交易服務之隱私權政策。
          <br>
          (4)除此之外，我們也會保留您在上網瀏覽或查詢時，伺服器自行產生的相關紀錄，包括但不限於您使用連線設備的IP位址、使用的瀏覽器、使用時間、瀏覽及點選紀錄等。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">3.蒐集資料之運用聲明</span>
        <span class="privacy-modal__number-list-text textfullpage">
          (1)您於線上商品兌換服務中所提供的個人資訊將會由負責該商品服務的相關單位處理，以便與您聯繫。
          <br>
          (2)各類線上商品兌換或獎品贈送，您所提供的個人資料，將在取得您明確同意的前提下，視蒐集目的分別做為答覆問題、客服通知，票券提供、或是商業及市場分析之用。個人資料之使用目的、利用對象、時間等資訊請參考本平台之內容，從本平台上獲得的資料將只使用在與資料蒐集時所告知您的特定目的上。除非在蒐集時有向您告知並徵得您的同意，否則本平台所蒐集的個人資料，將不會與他人共同使用、亦不會使用在未告知您的用途上。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">4.Cookie 技術之使用</span>
        <span class="privacy-modal__number-list-text textfullpage">
          (1)本平台可能使用cookie技術，為了提供便利使用者提供更適合會員個人需要的服務；cookie是本平台伺服器用來和使用者瀏覽器進行溝通的一種技術，它可能在使用者的電腦或行動裝置中儲存某些資訊，本平台也會讀取儲存在使用者電腦中的cookie資料。您可以經由電腦或行動裝置中瀏覽器的設定，取消、或限制此項功能，但可能因此無法使用部份網站功能。若您想知道如何取消、或限制此項功能，可參照您所使用的瀏覽器關於如何管理cookie的相關說明。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title textfullpage">5.本平台特定目的外利用個資之聲明：本平台絕不會洩露或轉售您的個人資料給其他與您所參與活動不相關的第三方。惟有下列三種情形，本平台會於特定目的外利用您的個人資料：</span>
        <span class="privacy-modal__number-list-text">
          <!-- 本平台絕不會洩露或轉售您的個人資料給其他與您所參與活動不相關的第三方。惟有下列三種情形，本平台會與第三方共用您的個人資料： -->
          <ul class="privacy-modal__bullet-list">
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              取得您的明確同意或授權允許時。
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              司法單位或其他政府機關經合法正式的程序要求時。
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              為了提供您其他服務或優惠權益，需要與提供該服務或優惠之第三人於特定目的外利用您的資料時，本平台會在活動時提供充分說明並告知，您可以自由選擇是否接受這項服務或優惠。
            </li>
          </ul>
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title textfullpage">6.您將可能在本平台中點選到第三方網站，該等第三方網站的隱私權保護聲明運作，並非由本平台管理，所以於該第三方網站中，不適用本平台隱私權保護聲明。建議您在該等第三方網站中欲提供個人資料前，詳閱該等第三方網站的隱私權保護相關聲明。</span>
        <!-- <span class="privacy-modal__number-list-text">
          在本平台中，您將可能點選到第三方網站。該等第三方網站的隱私權保護聲明運作，並非由本平台管理，所以於該等第三方網站中，不適用本平台隱私權保護聲明。建議您在該等第三方網站中欲提供個人資料前，詳閱該等第三方網站的隱私權保護相關聲明。
        </span> -->
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title textfullpage">7.	當事人權利行使：除法令另有規定外，您得對本平台所蒐集、處理、利用與保存您的個人資料，要求：</span>
        <span class="privacy-modal__number-list-text">
          <!-- 除法令另有規定外，您得對本平台所蒐集、處理、利用與保存您的個人資料，要求： -->
          <ul class="privacy-modal__bullet-list">
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              撤回先前有關蒐集、處理或利用之同意；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              限制處理或利用方式(限歐盟居民及歐盟居民自歐盟境內所提供之個人資料適用)；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              查詢或閱覽；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              製給複製本；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              將您所提供之個人資料移轉到您所指定的第三方或裝置(限歐盟居民及歐盟居民自歐盟境內所提供之個人資料適用)；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              補充或更正；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              停止蒐集、處理或利用；或刪除。
            </li>
          </ul>
        </span>
      </li>
      <!-- <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-text">
          本平台將依個人資料保護法及相關法令規範，決定是否受理您的申請。<br />
          除法令另有規定外，本平台將於蒐集之特定目的達成或消滅後，依本平台內部規定將您的個人資料銷毀刪除，或將您的個人資料去識別化且後續無法追蹤到您本人的方式留存。
        </span>
      </li> -->
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">8.安全性</span>
        <span class="privacy-modal__number-list-text textfullpage">
          為了防止您所提供的個人資料遭外洩、遺失、濫用、變更或破壞，本平台將使用通常認可的標準技術以及操作上的防護，來保護您的個人資料，例如網站的傳輸過程使用SSL（Secure Socket Layer）作為資料傳輸加密保護機制 。但由於本平台無法確保不會發生未經授權的存取事件，我們建議您不要將您使用本平台的相關帳號密碼分享給任何第三人；如您與他人共用電腦或行動裝置，你應充分注意於離開本平台前先行登出您的帳號，以防止後續使用者得接觸到您於本平台所留存的個人資訊。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">9.本聲明之修訂</span>
        <span class="privacy-modal__number-list-text textfullpage">
          由於社會環境及法令規定之變遷或科技技術進步，本平台將盡全力保護您的隱私，故本平台有權不定時修訂與公布本項聲明以合時宜。也請您隨時上網參閱本項聲明，以保障您的權益。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">10.會員聯絡資訊</span>
        <span class="privacy-modal__number-list-text textfullpage">
          本平台為保護使用者個人資料，維護網路隱私權，特訂定本隱私權保護聲明，若您對於本隱私權保護聲明、或與個人資料有關之相關事項有任何疑問，或欲行使上述當事人權利時，可利用本平台”聯絡我們” 詢問，我們會提供最完整的說明。如您認為本平台有違反法令致影響您的權益時，您可與本平台聯繫或有權利向個資保護相關主管機關進行申訴。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        本隱私權聲明最近更新：111年 11月 14日
      </li>
    </ol>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--confirm" (click)="isPrivacyModalOpen = false;">確認</button>
  </div>
</app-modal>
