<nav class="pagination">
    <ul class="pagination__list">
        <li class="pagination__item pagination__item--arrow-left" *ngIf="currentPage!=1">
            <a href="" class="pagination__link pagination__link--arrow" (click)="onPaginationLinkClick((currentPage-1),$event)">
                <span class="page">
                    <img src="/assets/images/icon/pagination_arrow_right.svg" alt="">
                </span>
            </a>
        </li>
        <!-- 頁數一永遠出現 -->
        <li class="pagination__item">
            <a (click)="onPaginationLinkClick(1,$event)" href="" class="pagination__link"
                [ngClass]="{'pagination__link--active': currentPage == 1}">
                <span>1</span>
            </a>
        </li>
        <li class="pagination__item" *ngIf="totalPages>=5 && currentPage>=4">
            <a href="" class="pagination__link pagination__link--ignore">
                <span>...</span>
            </a>
        </li>
        <!--  -->
        <ng-container *ngFor="let item of createEmptyArray(totalPages);let i=index">
            <li class="pagination__item" *ngIf="
            (i!=0) && 
            (i!=totalPages-1)&&
            (
            ((currentPage<=3 && i<=2) || (currentPage>3 && totalPages-currentPage>2 && i<=currentPage && i>=currentPage-2)) ||
                ((currentPage>=totalPages-2 && i>=totalPages-3))
            )
            ">
                <a (click)="onPaginationLinkClick((i+1),$event)" href="" class="pagination__link"
                    [ngClass]="{'pagination__link--active': currentPage == (i+1)}">
                    <span>{{i+1}}</span>
                </a>
            </li>
        </ng-container>
        <!--  -->
        <li class="pagination__item" *ngIf="totalPages>=5 && totalPages-currentPage>2">
            <a href="" class="pagination__link pagination__link--ignore">
                <span>...</span>
            </a>
        </li>
        <li class="pagination__item" *ngIf="totalPages>1">
            <a (click)="onPaginationLinkClick(totalPages,$event)" href="" class="pagination__link"
                [ngClass]="{'pagination__link--active': currentPage == totalPages}">
                <span>{{totalPages}}</span>
            </a>
        </li>
        <li class="pagination__item pagination__item--arrow-right" *ngIf="currentPage!=totalPages">
            <a href="" class="pagination__link pagination__link--arrow" (click)="onPaginationLinkClick((currentPage+1),$event)">
                <span class="page">
                    <img src="/assets/images/icon/pagination_arrow_right.svg" alt="">
                </span>
            </a>
        </li>
        <li class="pagination__item pagination__item--text">
            <span>前往頁面</span>
        </li>
        <li class="pagination__item pagination__item--tab">
            <select name="pagination-pages" id="" [(ngModel)]="currentPage" (change)="onPaginationTabChange($event)">
                <option [value]="i+1" *ngFor="let item of createEmptyArray(totalPages);let i=index">{{i+1}}</option>
            </select>
        </li>
    </ul>
</nav>