import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Brand } from '../../model/product';

@Component({
  selector: 'app-teamCarousel',
  templateUrl: './teamCarousel.component.html',
  styleUrls: ['./teamCarousel.component.scss'],
})
export class TeamCarouselComponent implements OnInit, OnDestroy {
  carouselAutoCounter: number = 0; // for timeout
  carouselItemActive: number = 0; // for UI
  carouselInterval: ReturnType<typeof setTimeout>;

  // 父元件給定作為錨點的元素
  @Input('anchorElement') public parentAnchorElement: HTMLElement;
  @Input('banners') public banners: Brand[];
  dataTeamList: string[];

  constructor(private elements: ElementRef,
    public  sanitizer: DomSanitizer //safe
    ) {}

  ngOnInit(): void {
    this.setCarouselInteval();
    this.resetCarouselHeight();
    this.BannerInit();
  }

  ngOnDestroy() {
    this.clearCarouselInterval();
  }

  @HostListener('window:resize', ['$event'])
  onResize($event?: any) {
    // 待確認圖片上傳的固定高度
    this.resetCarouselHeight();
  }

  onCarouselChange(element: HTMLElement, index: number, $event?: any) {
    if ($event) {
      $event.preventDefault();
    }

    const marginDistance = '-' + index + '00%';
    if(element)element.style['marginLeft'] = marginDistance;

    this.carouselAutoCounter = index;
    this.carouselItemActive = index;

    this.resetCarouselHeight();
    // 重新計算timout 時間
    this.clearCarouselInterval();
    this.setCarouselInteval();
  }

  resetCarouselHeight(imageElement?: HTMLImageElement) {
    if (this.banners.length == 0) {
      return;
    }
    if (imageElement && !imageElement.classList.contains('js-active-image')) {
      return;
    }

    // // 更換圖片後調整最外層容器高，避免遮色片位置不對 // 寫法待優化
    // const _this = this;
    // setTimeout(function () {
    //   const carousel =
    //     _this.elements.nativeElement.querySelector('.js-carousel');
    //   const carouselActiveItemImage =
    //     _this.elements.nativeElement.querySelector('.js-active-image');
    //   carousel.style['height'] = carouselActiveItemImage.offsetHeight + 'px';
    // }, 0);
  }

  setCarouselInteval() {
    const _this = this;

    this.carouselInterval = setInterval(function () {
      let list = Math.ceil(_this.banners.length / 8);
      // if (_this.carouselAutoCounter == (_this.carouselItemAmount - 1)) {
      if (_this.carouselAutoCounter == list - 1) {
        _this.carouselAutoCounter = 0;
      } else {
        _this.carouselAutoCounter++;
      }
      const index = _this.carouselAutoCounter;
      const element =
        _this.elements.nativeElement.querySelector('.js-carousel-inner');
      _this.onCarouselChange(element, index);
    }, 5000);
  }

  clearCarouselInterval() {
    clearInterval(this.carouselInterval);
  }

  scrollToAnchor($event: any) {
    $event.preventDefault();

    // 避免父元件未給定target element，預設距離為0
    let elementScrollTop = 0;
    if (this.parentAnchorElement) {
      elementScrollTop =
        this.parentAnchorElement.offsetTop -
        this.parentAnchorElement.scrollTop +
        this.parentAnchorElement.clientTop;
    }

    if (window.innerWidth < 1200) {
      // 因header 手機版有浮動區塊，需多給距離以免被遮擋
      elementScrollTop -= 50;
    }

    window.scrollTo({
      top: elementScrollTop,
      behavior: 'smooth',
    });
  }

  BannerInit() {
    if (this.banners && this.banners.length > 8) {
    this.dataTeamList = [];
      let list = Math.ceil(this.banners.length / 8);
      for (var i = 0; i < list; i++) {
        this.dataTeamList.push(`slide${i}`);
      }
    } else {
    this.dataTeamList = [`slide`];
    }

  }
}
