<!-- <h2 class="result-title">購物車</h2> -->
<!-- 交易成功 -->
<section class="result-content result-content--success" *ngIf="state==1">
    <div class="process-step process-step--completed">
        <div class="process-step__item process-step__item--completed process-step__item--ignore">
            <span class="process-step__text">
                確認訂單
            </span>
        </div>
        <div class="process-step__item process-step__item--completed">
            <span class="process-step__text">
                訂單完成
            </span>
        </div>
    </div>
    <div class="transaction-result">
        <h3 class="transaction-result__title">您的訂單已經成立，謝謝您！</h3>
        <p class="transaction-result__text">訂單確認郵件已經寄送至您的Email：</p>
        <p class="transaction-result__text transaction-result__text--mail">{{msg}}</p>
        <a class="transaction-result__link" routerLink="/member/order">
            查看您的訂單資訊
        </a>
    </div>
</section>
<!-- 交易失敗 -->
<section class="result-content result-content--fail" *ngIf="state==2">
    <!-- <div class="process-step process-step--failed">
        <div class="process-step__item process-step__item--completed process-step__item--ignore">
            <span class="process-step__text">
                確認訂單
            </span>
        </div>
        <div class="process-step__item process-step__item--failed">
            <span class="process-step__text">
                交易失敗
            </span>
        </div>
    </div> -->
    <section class="process-step">
      <div class="process-step__item process-step__item--completed process-step__item--completed-last" >
        <span class="process-step__text">
        1 確認訂單
        </span>
      </div>
      <div class="cartline"></div>
      <div class="process-step__item-gray process-step__item--completed process-step__item--completed-last" >
        <span class="process-step__text-gray">
        2 交易失敗
        </span>
      </div>
    </section>
    <div class="transaction-result">
        <h3 class="transaction-result__title">交易失敗</h3>
        <p class="transaction-result__text transaction-result__text--error">{{msg}}</p>
        <button class="transaction-result__button" (click)="BackToCart()">
            返回上一頁
        </button>
    </div>
</section>
