<div class="home container">
  <app-breadcrumb></app-breadcrumb>
  <section class="site-map">
    <h2 class="site-map__title">網站地圖</h2>
    <div class="site-map__content">
      <section class="site-map__outer-wrap">
        <div class="site-map__list-wrap">
          <ng-container *ngIf="siteMapRes">
            <ul *ngFor="let newsObj of siteMapRes.newsList" class="site-map__list">
              <li class="site-map__item site-map__item--title">{{newsObj.newsTypeName}}</li>
              <li *ngFor="let news of newsObj.data" class="site-map__item">
                <a href="" class="site-map__link">{{news.cName}}</a>
              </li>
            </ul>
          </ng-container>
        </div>
        <div class="site-map__list-wrap">
          <ul class="site-map__list">
            <li class="site-map__item site-map__item--title">品牌館</li>
            <ng-container *ngIf="siteMapRes">
              <li *ngFor="let brand of siteMapRes.parentBrandList" class="site-map__item">
                <a [routerLink]="['/product', 'list', brand.id]" class="site-map__link">{{brand.name}}</a>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="site-map__list-wrap">
          <ul class="site-map__list">
            <li class="site-map__item site-map__item--title">常見問題</li>
            <ng-container *ngIf="siteMapRes">
              <li *ngFor="let type of siteMapRes.qaTypeList" class="site-map__item">
                <a [routerLink]="['/question', type.id]" class="site-map__link">{{type.name}}</a>
              </li>
            </ng-container>
            <li class="site-map__item">
              <a [routerLink]="['/question', 'contact']" class="site-map__link">聯絡我們</a>
            </li>
          </ul>
        </div>
        <div class="site-map__list-wrap">
          <ul class="site-map__list">
            <li class="site-map__item site-map__item--title">會員服務</li>
            <li class="site-map__item">
              <a [routerLink]="['/member', 'cart']" class="site-map__link">購物車</a>
            </li>
            <li class="site-map__item">
              <a [routerLink]="['/member', 'order']" class="site-map__link">訂單查詢</a>
            </li>
            <li class="site-map__item">
              <a [routerLink]="['/member', 'point']" class="site-map__link">會員點數</a>
            </li>
            <li class="site-map__item">
              <a [routerLink]="['/member', 'ticket']" class="site-map__link">會員票夾</a>
            </li>
            <li class="site-map__item">
              <a [routerLink]="['/member', 'favorite']" class="site-map__link">我的收藏</a>
            </li>
            <li class="site-map__item">
              <a [routerLink]="['/member', 'voucher']" class="site-map__link">優惠碼</a>
            </li>
            <li class="site-map__item">
              <a [routerLink]="['/member', 'cart']" class="site-map__link">登出</a>
            </li>
          </ul>
        </div>
      </section>

    </div>
  </section>
</div>
