import { MbrInfo, Ticket } from './../../../shared/model/member';
import { HttpService } from './../../../shared/service/http.service';
import { LoaderService } from './../../../shared/service/loader.service';
import { LoginService } from './../../../shared/service/login.service';
import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/shared/service/breadcrumb.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';
import { Router } from '@angular/router';
export interface ModalDataForUseTicket {
  imgUrl: string
  name: string
  expiredDate: string
  sn: string
  purchaseDate: string
  exchangeWay: string
  barCodeImgUrl: string
  isQR?: number;
  pinCode: string;
  barcode1: string;
  barcode2: string;
  ticketPrecautions: string;
  couponNo: string;
  multiBarcodeType: string;
  qRcodeString: string;
}
export interface ModalDataForShareTicket {
  name: string
  ticketLink: string
  snKey: string
}
@Component({
  selector: 'app-member-ticket',
  templateUrl: './member-ticket.component.html',
  styleUrls: ['./member-ticket.component.scss']
})

export class MemberTicketComponent implements OnInit {
  // 切換票券類型
  ticketTypeTab: number = 1;
  // 是否顯示排序區塊
  isSortSectionShow = false;
  // 升降冪排序
  isSortASC = false;
  // modal
  isModalOpen = false;
  modalProcess: number = 0; // process 區分使用票券與轉贈票券的步驟，0 為使用票券
  modalTitle: string = "";
  noModalFooter: boolean = true;
  modalDataForUseTicket: ModalDataForUseTicket = {
    imgUrl: "",
    name: "",
    expiredDate: "",
    sn: "",
    purchaseDate: "",
    exchangeWay: "",
    barCodeImgUrl: "",
    isQR: 0,
    pinCode: "",
    barcode1: "",
    barcode2: "",
    ticketPrecautions: "",
    couponNo: "",
    multiBarcodeType: "",
    qRcodeString: "",
  };
  modalDataForShareTicket: ModalDataForShareTicket = {
    name: "",
    ticketLink: "",
    snKey: "",
  };
  userInfo: MbrInfo;
  infos: Ticket[] = [];
  filterInfos: Ticket[] = [];
  constructor(
    private loginService: LoginService,
    private loaderService: LoaderService,
    private httpService: HttpService,
    private breadcrumbService: BreadcrumbService,
    private pagetagService: PagetagService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userInfo = this.loginService.GetLocalInfo();
    if (this.userInfo.IsLogin) {
      this.getTicketList(this.userInfo.ID, true);
    }
    this.breadcrumbService.breadcrumbData.next([{ breadcrumb: '可使用票券', path: '' }])
  }

  //取得會員所有票券
  getTicketList(mbrID: string, isInit = false) {
    this.loaderService.start();
    var sortType = this.isSortASC ? "asc" : "desc";
    this.httpService.get("/Member/GetTicketList?mbrID=" + mbrID + '&sortType=' + sortType).subscribe(
      (x: any) => {
        this.infos = x.result;
        if (isInit) {
          this.clickTicketTypeTag(1);
        }
        this.loaderService.stop();
      }
    )
  }
  // 改變升降冪
  changeSortDirection($event: any) {
    if ($event.target.classList.contains('js-sort-direction')) {
      this.isSortASC = !this.isSortASC;

      this.getTicketList(this.userInfo.ID);
    }
  }

  useTicket(item: Ticket) {
    if (item) {
      // 若有item 則在此賦值
      this.modalDataForUseTicket.imgUrl = item.img
      this.modalDataForUseTicket.name = item.title;
      this.modalDataForUseTicket.expiredDate = item.useLimitDT;
      this.modalDataForUseTicket.sn = item.ticketNo;
      this.modalDataForUseTicket.purchaseDate = item.buyDT;
      this.modalDataForUseTicket.exchangeWay = item.exchangeType;
      this.modalDataForUseTicket.barCodeImgUrl = "";

      this.modalDataForUseTicket.isQR = item.isQR;
      this.modalDataForUseTicket.pinCode = item.pinCode;
      this.modalDataForUseTicket.barcode1 = item.barcode1;
      this.modalDataForUseTicket.barcode2 = item.barcode2;
      this.modalDataForUseTicket.ticketPrecautions = item.ticketPrecautions;

      this.modalDataForUseTicket.couponNo = item.couponNo;
      this.modalDataForUseTicket.qRcodeString = item.qRcodeString;
      this.modalDataForUseTicket.multiBarcodeType = item.multiBarcodeType;

      this.modalProcess = 0;
      this.noModalFooter = true;
      this.isModalOpen = true;
    }
  }
  shareTicket(item: Ticket) {
    if (item) {
      // 若有item 則在此賦值
      this.modalDataForShareTicket.name = item.title;
      this.modalDataForShareTicket.ticketLink = item.ticketLink;
      this.modalDataForShareTicket.snKey = item.snKey;
      this.modalProcess = 6;
      this.noModalFooter = true;
      this.isModalOpen = true;
    }
  }

  transferTicket() {
    this.modalProcess = 1;
    this.noModalFooter = false;
    this.isModalOpen = true;
  }

  clickTicketTypeTag(type: number) {
    //type => 1:可使用 2:已使用+過期 3:已轉贈
    this.ticketTypeTab = type;
    switch (type) {
      case 1:
        this.filterInfos = this.infos.filter(x => x.ticketType == 1)
        this.breadcrumbService.breadcrumbData.next([{ breadcrumb: '可使用票券', path: '' }])
        this.callPagetagService('可使用票券');
        break;
      case 2:
        this.filterInfos = this.infos.filter(x => x.ticketType == 2 || x.ticketType == 3)
        this.breadcrumbService.breadcrumbData.next([{ breadcrumb: '已使用或過期', path: '' }])
        this.callPagetagService('已使用或過期');
        break;
      case 3:
        this.filterInfos = this.infos.filter(x => x.ticketType == 4)
        this.breadcrumbService.breadcrumbData.next([{ breadcrumb: '已轉贈票券', path: '' }])
        this.callPagetagService('已轉贈票券');
        break;
    }
  }

  callPagetagService(type = '可使用票券') {
    this.pagetagService.callPageTag(this.router.url, `會員服務 | 會員票券 | ${type}`)
  }
}
