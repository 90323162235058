<div class="home container">
    <app-breadcrumb></app-breadcrumb>
    <section class="login">
        <h2 class="login__title">會員登入</h2>
        <form #form="ngForm">
            <div class="login__content">
                <div class="login__wrap">
                    <div class="login__input-wrap">
                        <label class="login__label" for="">身分證字號</label>
                        <input class="login__input" type="text" placeholder="請輸入您的身分證字號"
                            (ngModelChange)="id = $event.toUpperCase()" [ngModel]="id"
                            [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="login__recaptcha-wrap">
                        <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required
                            [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"
                            (resolved)="isResolved=true">
                        </re-captcha>
                    </div>
                    <div class="login__button-wrap">
                        <a [routerLink]="['/']" class="login__button login__button--cancel">取消</a>
                        <button class="login__button login__button--login"
                            [ngClass]="{'login__button--disabled': !isResolved}" (click)="send(form)">登入</button>
                    </div>
                    <div class="login__note-wrap">
                        <p class="login__note">
                            <span class="note__icon-wrap">
                                <img class="note__icon" src="/assets/images/icon/shock_mark_white.svg" alt="">
                            </span>
                            <span class="note__text">
                                若有疑問請洽客服人員
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </section>
</div>
<!-- alertModal -->
<app-modal [isModalOpen]="isMsgModalOpen" (modalClose)="isMsgModalOpen=$event">
    <h3 headerContent class="general-modal__title">提示信息</h3>
    <div bodyContent class="general-modal__text-wrap">
        <p class="general-modal__text" *ngFor="let row of Msg.split('br')">{{row}}</p>
    </div>
    <div footerContent class="general-modal__button-wrap">
        <button class="general-modal__button general-modal__button--confirm general-modal__button--only"
            (click)="Confirm()">確認</button>
    </div>
</app-modal>