import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-btn',
  templateUrl: './top-btn.component.html',
  styleUrls: ['./top-btn.component.scss']
})
export class TopBtnComponent implements OnInit {
  isTopBtnHide: boolean = true;

  constructor() { }

  ngOnInit(): void {
    // this.onScroll();
  }

  @HostListener('window:scroll', ['$event']) onScroll() {
    if (window.scrollY > 300) {
      this.isTopBtnHide = false;
    } else {
      this.isTopBtnHide = true;
    }
  }

  onTopBtnClick($event: any) {
    $event.preventDefault();

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
}
