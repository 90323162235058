import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { RelatedNews } from 'src/app/shared/model/product';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-detail-info',
  templateUrl: './product-detail-info.component.html',
  styleUrls: ['./product-detail-info.component.scss']
})
export class ProductDetailInfoComponent implements OnInit {
  @Input() public isShow: boolean = true;
  @Input() public relatedNews: RelatedNews;
  @Input() public product :boolean =false;
  @Output() public closeInfo = new EventEmitter();
  constructor(private router: Router) { }
  ngOnInit(): void {
    if (!this.isShow) { return };

    const windowWidth = window.innerWidth;
    if (windowWidth < 1200) {
      this.autoClose();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize($event?: any) {
    if (!this.isShow) { return };

    const windowWidth = window.innerWidth;
    if (windowWidth < 1200) {
      this.autoClose();
    }
  }

  // setTimeout in mobile mode
  autoClose() {
    const _this = this;
    setTimeout(function () {
      _this.isShow = false;
    }, 5000)
  }

  onClose() {
    this.closeInfo.emit(false);
  }

  goToLink(event: any, isAngularBased: boolean, url: string, isTargetBlank = false) {
    event.preventDefault();
    if (isTargetBlank) {
      window.open(url, '_blank');
      return;
    }
    if (isAngularBased) {
      this.router.navigate([url]);
      return;
    }
    window.location.href = url;
  }
}
