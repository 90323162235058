<div class="product-background">
  <section class="product-content">
    <div class="bottom">
      <div class="product-content__fixed-info-wrap">
        <app-product-detail-info [isShow]="isInfoShow" [product]="!!product.label" [relatedNews]="relatedNews"
          (closeInfo)="isInfoShow=$event">
        </app-product-detail-info>
      </div>
      <div *ngIf="product.label" class="product-content__label"
        [ngStyle]="{color:product.label.color,backgroundColor: product.label.backGroundColor}">
        <!-- [ngClass]="{'product-content__label-news':isInfoShow}" -->
        {{product.label.text}}
      </div>
      <div class="product-content__title-wrap">
        <!-- <h2 class="product-content__title">{{product.categoryName2}}商家產品</h2> -->
      </div>
      <section class="product-content__info">
        <div class="product-content__row">
          <div class="product-content__col product-content__col--image-section">
            <div class="out-of-stock-mask" *ngIf="product.isSoldOut">
              <div class="out-of-stock-mask__circle">
                <span class="out-of-stock-mask__text">已售完</span>
              </div>
            </div>
            <div style="display: flex;justify-content: center;">
              <img *ngIf="product.img1" [src]="product.img1" alt="">
            </div>
          </div>
          <div class="product-content__col product-content__col--text-section">
            <div>
              <div class="text-section__title-wrap">
                <h3 class="text-section__title">{{product.name}}</h3>
                <!-- red label -->
                <span *ngIf="false"
                  class="product-content__activity-label-wrap product-content__activity-label-wrap--first"><img
                    class="product-content__activity-label" src="/assets/images/icon/product_red_label.svg" alt="">
                </span>
                <!-- green label -->
                <span *ngIf="false" class="product-content__activity-label-wrap"><img
                    class="product-content__activity-label" src="/assets/images/icon/product_green_label.svg" alt="">
                </span>
                <!-- reach label-->
                <span *ngIf="false"
                  class="product-content__activity-label-wrap product-content__activity-label-wrap--last"><img
                    class="product-content__activity-label" src="/assets/images/icon/product_reach_label.svg" alt="">
                </span>
              </div>
              <div class="text-section__sn">
                <p>商品編號：</p>
                <p>{{sn}}</p>
              </div>
              <!-- <div class="text-section__description">
            <p>商品說明</p>
            <div *ngIf="product.desc" [innerHTML]="product.desc | html"></div>
          </div> -->
              <div class="text-section__expiration" *ngIf="product.expiration">
                <p>商品兌換期：{{product.expiration}}</p>
              </div>
            </div>

            <div class="text-section__amount" *ngFor="let item of product.exchangeTypes; let i = index">
              <p class="best-seller__price best-seller__price--money amount" *ngIf="item.type==2">
                {{item.price}}元
              </p>
              <!-- 代套版 -->
              <p class="best-seller__price delete" *ngIf="item.type==2 && item.discountPrice !== 0">
                {{item.discountPrice}}元
              </p>
            </div>

            <!-- <div class="text-section__payment-method">
          <p>
            商品售價
          </p>
          <div *ngFor="let item of product.exchangeTypes; let i = index">
            <span *ngIf="item.type==3">
              {{item.point}}元
            </span>
          </div>

          <div *ngFor="let item of product.exchangeTypes; let i = index">
            <p class="best-seller__price best-seller__price--money" *ngIf="item.type==2">
              {{item.price}}元
            </p>
          </div>

          <button *ngFor="let item of product.exchangeTypes; let i = index" [ngClass]="{active: paymentMethod==i}"
            (click)="setActivePaymentMethod(i,item)">
            <span>{{item.type==1?"全額使用點數兌換":"悠遊付"}}
            </span>
            <span *ngIf="item.type==1">
              {{item.point}}點
            </span>
            <span *ngIf="item.type==2">
              {{item.point}}點+{{item.price}}元
            </span>
          </button>

        </div> -->
            <div [hidden]="!limitMsg" class="text-section__point-use-condition">
              <p>商品點數使用條件</p>
              <p>{{limitMsg}}</p>
            </div>
            <div class="row text-section__add-to-cart-section">
              <div class="col-12 d-flex justify-content-between">
                <!-- <button #addToCartBtn class="text-section__button text-section__button--primary"
            (click)="isTicketProductType ? openTicketAlertModal() : addToCart()" mat-raised-button
            *ngIf="!product.isSoldOut">前往結帳
            <img src="/assets/images/icon/shopping_cart_white.svg" alt="">
          </button> -->
                <button #addToCartBtn class="text-section__button text-section__button--primary" (click)="addToCart()"
                  mat-raised-button *ngIf="!product.isSoldOut">前往結帳
                  <img src="/assets/images/icon/shopping_cart_white.svg" alt="">
                </button>
                <!-- <button class="text-section__button text-section__button--light" (click)="addToFavorite()">加入收藏

              <img #favoriteIcon *ngIf="!isCollect" src="/assets/images/icon/favorite_heart_black.svg" alt="">
              <img #favoriteIcon *ngIf="isCollect" src="/assets/images/icon/favorite_heart_pink.svg" alt="">
            </button> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div style="background-color: FFFFFF;">
      <article class="ads" *ngIf="product.ads">
        <div class="ads__content" *ngFor="let ad of product.ads">
          <a [href]="ad.cUrl" [target]="ad.cAnother == 1 ? '_blank' : ''" *ngIf="ad.cUrl">
            <img [src]="ad.cImg" alt="">
          </a>
          <a (click)="goEventPage(ad.cType, ad.id)" *ngIf="!ad.cUrl">
            <img [src]="ad.cImg" alt="">
          </a>
        </div>
      </article>
    </div>
    <article class="product-content__note">
      <div class="note__product-spec">
        <h4>商品描述</h4>
        <div *ngIf="product.desc" [innerHTML]="product.desc | html"></div>
      </div>
      <div class="note__warning-list">
        <h4>商品注意事項</h4>
        <div *ngIf="product.prodPrecautions" [innerHTML]="product.prodPrecautions | html"></div>
      </div>
    </article>
    <div class="recommended-line" ></div>
    <article class="product-list">
      <h4 style="margin-top: 15px;">推薦商品</h4>
      <div class="product-list__row">
        <!-- each item -->
        <div class="product-list__col" *ngFor="let item of product?.recommendProducts">
          <div class="product-list__item">
            <div *ngIf="item.label" class="product-list__labeltag" [ngStyle]="{color:item.label.color,backgroundColor: item.label.backGroundColor}">{{item.label.text}}</div>
            <div class="product-list__image-wrap">
              <div class="product-list__out-of-stock-mask" *ngIf="item.isSoldOut"
                routerLink="/product/detail/{{item.id}}" (click)="goRecommendProductLink(item.id)">
                <div>
                  <span>已售完</span>
                </div>
              </div>
              <a routerLink="/product/detail/{{item.id}}" class="product-list__product-image"
                (click)="goRecommendProductLink(item.id)">
                <img class="main" src="{{item.img}}" alt="">
              </a>
            </div>
            <div class="product-list__name-wrap">
              <a routerLink="/product/detail/{{item.id}}" class="product-list__name"
                (click)="goRecommendProductLink(item.id)">
                {{item.name}}
              </a>
            </div>
            <div class="product-list__price-wrap">
              <div class="d-flex">
                <p class="product-list__price product-list__price--money" *ngIf="item.pP_Price">
                  {{item.pP_Price}}元</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>

    <article class="mobile-product-list">
      <h4 style="margin-top: 15px;color: #CD2382;font-size: 20px;;margin-left:5px;">推薦商品</h4>
      <div class="recommended-brand__brand-slider-mobile-text" *ngIf="product?.recommendProducts">
        <div class="brand-slider-mobile__row-wrap">
          <swiper [spaceBetween]="2" [loop]="true" [slidesPerView]="1.1" [autoplay]="false" [navigation]="false"
            class="mySwiper">
            <ng-template swiperSlide *ngFor="let productList of product.productLists;">
              <div class="brand-slider-mobile__col" *ngFor="let item of productList ">
                <div class="brand-slider-mobile__item">
                  <ng-container *ngIf="item.label">
                    <div class="brand-slider-mobile__labeltag" [ngStyle]="{color:item.label.color,backgroundColor: item.label.backGroundColor}">
                      {{item.label.text}}
                    </div>
                  </ng-container>
                  <a (click)="goRecommendProductLink(item.id)">
                    <div style="margin-bottom: 10px;">
                      <img src="{{item.img}}" alt="">
                      <div class="brand-slider-mobile__name">{{item.name}}</div>
                    </div>
                    <div class="best-seller__price__main" *ngIf="item.pP_Point ||item.pP_Price">
                      <p class="best-seller__price best-seller__price--money" >
                        {{item.pP_Price}}元
                      </p>
                      <p class="best-seller__price best-seller__price--delete" *ngIf="item.discountPrice !== 0">
                        {{item.discountPrice}}元
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </ng-template>
          </swiper>
        </div>
      </div>
    </article>
  </section>
</div>

<!-- modal -->
<app-modal [isModalOpen]="isModalOpen" [isCloseBtnShow]="isCloseBtnShow"
  (modalClose)="isModalOpen=$event;isAgreeRefreshModal=$event">
  <h4 headerContent class="general-modal__title">{{modalTiTle}}</h4>
  <div bodyContent class="general-modal__text-wrap">
    <p class="general-modal__text">{{modalMsg}}</p>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button routerLink="/" class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="goHomePage=false" *ngIf="goHomePage && !isTicketModalMode">返回首頁</button>
    <button class="general-modal__button general-modal__button--cancel" *ngIf="isAgreeRefreshModal"
      (click)="isModalOpen=false;isAgreeRefreshModal=false">取消</button>
    <button class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="onModalConfirm()" *ngIf="!goHomePage && !isTicketModalMode">確認</button>
    <ng-container *ngIf="isTicketProductType && isTicketModalMode">
      <button class="general-modal__button general-modal__button--cancel" (click)="handleTickeAlertModal()">取消</button>
      <button class="general-modal__button general-modal__button--confirm"
        (click)="handleTickeAlertModal(true)">確定</button>
    </ng-container>
  </div>
</app-modal>
