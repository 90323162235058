import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CollectionTarget } from 'src/app/shared/model/cart';

@Component({
  selector: 'app-header-info',
  templateUrl: './header-info.component.html',
  styleUrls: ['./header-info.component.scss']
})
export class HeaderInfoComponent implements OnInit {

  @Input() public isInfoShow: boolean = false;;
  @Input() public isHeaderFixed: boolean;
  @Input() public collectionTarget: CollectionTarget;

  @Output() public infoClose = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  onInfoClose() {
    this.infoClose.emit(false);
  }
}
