import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable()
export class HttpService {
  url: string = environment.apiUrl;
  constructor(private http: HttpClient) {}
  post(api: string, request: any) {
    return this.http.post<any>(this.url + api, request);
  }
  authPost(api: string, request: any) {
    let jwtToken = sessionStorage.getItem('jwtToken');
    // 创建标头对象
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization':'bearer '+ jwtToken||'', // 添加自定义标头 "token"
    });

    // 创建请求选项对象，将标头添加到选项中
    const options = {
      headers: headers,
      // responseType: 'arraybuffer' as 'json',
    };

    return this.http.post<any>(this.url + api, request, options);
  }
  get(api: string) {
    return this.http.get(this.url + api);
  }
  authGet(api: string) {
    let jwtToken = sessionStorage.getItem('jwtToken');
    // 创建标头对象
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization':'bearer '+ jwtToken||'', // 添加自定义标头 "token"
    });

    // 创建请求选项对象，将标头添加到选项中
    const options = {
      headers: headers,
      // responseType: 'arraybuffer' as 'json',
    };

    return this.http.get(this.url + api,options);
  }
  delete(api: string) {
    return this.http.delete(this.url + api);
  }
}
