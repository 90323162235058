import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteMapRes } from 'src/app/shared/model/home';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';

@Component({
  selector: 'app-home-sitemap',
  templateUrl: './home-sitemap.component.html',
  styleUrls: ['./home-sitemap.component.scss']
})
export class HomeSitemapComponent implements OnInit {
  siteMapRes: SiteMapRes;

  constructor(private httpService: HttpService,
    private loaderService: LoaderService,
    private pagetagService: PagetagService,
    private router: Router) { }

  ngOnInit(): void {
    this.callPagetagService();
    this.getData();
  }

  getData() {
    this.loaderService.start();
    this.httpService.get('/home/GetSiteMap').subscribe((res: any) => {
      this.siteMapRes = res;
      this.siteMapRes.newsList.sort(x => x.newsType)
      this.loaderService.stop();
    }, err => {
      alert('資料請求錯誤')
      this.loaderService.stop();
    });
  }

  callPagetagService() {
    this.pagetagService.callPageTag(this.router.url, '網站地圖')
  }
}
