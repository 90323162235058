import { Component, ElementRef, Input, OnInit, Output, EventEmitter, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { ItemsPerPageData } from '../../model/pagination';
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  // 分頁功能
  @Input() currentPage: number = 1;
  itemsPerPage: number = 12; // 預設不管裝置寬，皆顯示12筆資料
  totalPages: number = 20;

  // 斷點
  // mobile first logic
  smWidth = 576;
  mdWidth = 768;
  lgWidth = 992;
  xlWidth = 1200;

  // 父元件給定作為錨點的元素
  @Input('anchorElement') public parentAnchorElement: HTMLElement;
  @Input('totalItems') public totalItems: number;
  // 父元件給定各斷點展示數量
  @Input('itemsPerPageData') public itemsPerPageData: ItemsPerPageData;
  @Output() SelectPage = new EventEmitter<number>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.setItemsPerPage();
    this.setTotalPages();

    let isResetCurrentPage = true;
    const currentPageKey = 'currentPage';
    for (let key in changes) {
      if (key == currentPageKey) {
        isResetCurrentPage = false;
      }
    }
    if (isResetCurrentPage) {
      this.currentPage = 1;
    }
  }

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setItemsPerPage();
    this.setTotalPages();
  }

  setItemsPerPage() {
    if (!this.itemsPerPageData) { return; }

    let windowWidth = window.innerWidth;

    if (windowWidth < this.smWidth) {
      this.itemsPerPage = this.itemsPerPageData['min'];
    } else if (windowWidth < this.mdWidth) {
      this.itemsPerPage = this.itemsPerPageData['sm'];
    } else if (windowWidth < this.lgWidth) {
      this.itemsPerPage = this.itemsPerPageData['md'];
    } else if (windowWidth < this.xlWidth) {
      this.itemsPerPage = this.itemsPerPageData['lg'];
    } else {
      this.itemsPerPage = this.itemsPerPageData['xl'];
    }
  }

  setTotalPages() {
    this.totalPages = (this.totalItems / this.itemsPerPage) !== 0 ? Math.ceil(this.totalItems / this.itemsPerPage) : 1;
  }

  // for *ngFor
  createEmptyArray(num: number) {
    return new Array(num);
  }

  onPaginationLinkClick(pageNum: number, $event?: any) {
    $event.preventDefault();
    this.currentPage = pageNum;
    this.scrollToAnchor();
    this.SelectPage.emit(pageNum);
  }
  onPaginationTabChange($event: any) {
    let pageNum = parseInt($event.target.value);
    this.currentPage = pageNum;
    this.scrollToAnchor();
    this.SelectPage.emit(pageNum);
  }

  scrollToAnchor() {
    // 避免父元件未給定target element，預設距離為0
    let elementScrollTop = 0;
    if (this.parentAnchorElement) {
      elementScrollTop = (this.parentAnchorElement.offsetTop - this.parentAnchorElement.scrollTop + this.parentAnchorElement.clientTop);
    }

    setTimeout(function () {
      window.scrollTo({
        top: elementScrollTop,
        behavior: "smooth"
      });
    }, 200)
  }
}

