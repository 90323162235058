import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ModalDataForUseTicket } from '../member/member-ticket/member-ticket.component';
import { HeaderService } from 'src/app/shared/service/header.service';

@Component({
  selector: 'app-ticket-link',
  templateUrl: './ticket-link.component.html',
  styleUrls: ['./ticket-link.component.scss'],
})
export class TicketLinkComponent implements OnInit {
  isResolved = true;
  sn: string | null;
  token: string | undefined;
  key: string;
  modalProcess: number = 0; // process 區分驗證密碼與票券顯示的步驟，0 為驗證密碼 1為顯示票券
  isMsgModalOpen = false;
  isGift: boolean = false;
  Msg: string = '';
  modalDataForUseTicket: ModalDataForUseTicket = {
    imgUrl: '',
    name: '',
    expiredDate: '',
    sn: '',
    purchaseDate: '',
    exchangeWay: '',
    barCodeImgUrl: '',
    isQR: 0,
    pinCode: '',
    barcode1: '',
    barcode2: '',
    ticketPrecautions: '',
    couponNo: '',
    multiBarcodeType: '',
    qRcodeString: '',
  };

  constructor(
    private loaderService: LoaderService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    public headerService: HeaderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const segments = this.router.url;
    console.log(segments); // 输出当前路径段的数组
    if (segments.includes('type=Gift')) {
      this.isGift = true;
    }
    this.headerService.Footer = false;
    this.headerService.Global = false;
    this.sn = this.route.snapshot.queryParamMap.get('sn');
    let key = this.route.snapshot.queryParamMap.get('snkey');
    if (!this.sn) {
      location.href = '/';
    }
    if (!key) {
      this.key = key as string;
      this.getTicket();
    }
  }
  ngOnDestroy() {
    this.headerService.Footer = true;
    this.headerService.Global = true;
  }
  getTicket() {
    this.loaderService.start();
    this.httpService
      .get(
        '/Member/GetTicket?sn=' +
          this.sn +
          '&key=' +
          this.key +
          '&token=' +
          this.token
      )
      .subscribe((x: any) => {
        if (x.code == 1) {
          let item = x.result;
          this.modalDataForUseTicket.imgUrl = item.img;
          this.modalDataForUseTicket.name = item.title;
          this.modalDataForUseTicket.expiredDate = item.useLimitDT;
          this.modalDataForUseTicket.sn = item.ticketNo;
          this.modalDataForUseTicket.purchaseDate = item.buyDT;
          this.modalDataForUseTicket.exchangeWay = item.exchangeType;
          this.modalDataForUseTicket.barCodeImgUrl = '';
          this.modalDataForUseTicket.isQR = item.isQR;
          this.modalDataForUseTicket.pinCode = item.pinCode;
          this.modalDataForUseTicket.barcode1 = item.barCode1;
          this.modalDataForUseTicket.barcode2 = item.barCode2;
          this.modalDataForUseTicket.ticketPrecautions = item.ticketPrecautions;
          this.modalDataForUseTicket.couponNo = item.couponNo;
          this.modalDataForUseTicket.qRcodeString = item.qRcodeString;
          this.modalDataForUseTicket.multiBarcodeType = item.multiBarcodeType;
          this.modalProcess = 1;
        } else {
          this.isMsgModalOpen = true;
          this.Msg = x.result;
        }

        this.loaderService.stop();
      });
  }
  Confirm() {
    this.isMsgModalOpen = false;
    this.isResolved = false;
  }
}
