<h2 class="point-title">會員點數<span class="note-shock-icon" (click)="isPointRegulationModalOpen=true">
    <img src="/assets/images/icon/shock_mark_white.svg" alt="">
  </span></h2>
<section class="point-content">
  <div class="point-note">
    <p class="point-note__text">您好
      您即將到期<span>{{closestExpiredDate}}</span>的點數共計<span>{{addThouthandMark(closestExpiredBonus)}}</span>點，提醒您盡速換購商品，避免權益喪失唷!
    </p>
  </div>
  <section class="point-overview">
    <div class="point-overview__top-section">
      <div class="point-overview__title-wrap">
        <h3 class="point-overview__title">您的點數明細總覽</h3>
      </div>
      <div class="point-overview__text-wrap">
        <p class="point-overview__text">
          可使用點數共計
        </p>
        <p class="point-overview__text">
          <span class="point-dollar-number">
            <img class="point-dollar-number__image point-dollar-number__image--mr2p point-dollar-number__image--desktop"
              src="/assets/images/icon/money-red.svg" alt="">
            <span
              class="point-dollar-number__text point-dollar-number__text--primary point-dollar-number__text--mr3p">{{addThouthandMark(this.mbrInfo.Bonus
              + this.mbrInfo.CardBonus)}}</span>
          </span>點
        </p>
      </div>
      <div class="point-overview__button-wrap">
        <a *ngIf="false" [routerLink]="['/member','point','transfer']" routerLinkActive="router-link-active"
          class="point-overview__button">轉贈點數</a>
      </div>
    </div>
    <div class="point-overview__bottom-section">
      <div class="point-overview__info-wrap point-overview__info-wrap--platform">
        <p class="point-overview__info-text point-overview__info-text--first">悠客券平台</p>
        <p class="point-overview__info-text">
          <span>{{addThouthandMark(this.mbrInfo.Bonus)}}</span>點
        </p>
        <p class="point-overview__info-text point-overview__info-text--mobile point-overview__info-text--time">
          {{this.mbrInfo.ClearVBonusDate}}</p>
        <p class="point-overview__info-text point-overview__info-text--mobile point-overview__info-text--last">
          即將到期<span>{{addThouthandMark(this.mbrInfo.ClearVBonus)}}</span>點
        </p>
        <p class="point-overview__info-text point-overview__info-text--desktop">
          ({{this.mbrInfo.ClearVBonusDate}}即將到期<span>{{addThouthandMark(this.mbrInfo.ClearVBonus)}}</span>點)
        </p>
      </div>
      <div class="point-overview__info-wrap point-overview__info-wrap--bank">
        <p class="point-overview__info-text point-overview__info-text--first">信用卡紅利點數</p>
        <p class="point-overview__info-text">
          <span>{{addThouthandMark(this.mbrInfo.CardBonus)}}</span>點
        </p>
        <p class="point-overview__info-text point-overview__info-text--mobile">
          {{this.mbrInfo.ClearCardDate}}</p>
        <p class="point-overview__info-text point-overview__info-text--mobile">
          即將到期<span>{{addThouthandMark(this.mbrInfo.ClearCardBonus)}}</span>點
        </p>
        <p class="point-overview__info-text point-overview__info-text--desktop">
          ({{this.mbrInfo.ClearCardDate}}即將到期<span>{{addThouthandMark(this.mbrInfo.ClearCardBonus)}}</span>點)
        </p>
      </div>
    </div>
  </section>
  <section class="point-search" [hidden]="isDetailMode">
    <form #searchForm="ngForm">
      <div class="point-search__top-section">
        <div class="point-search__title-wrap">
          <h3 class="point-search__title">您的點數明細</h3>
        </div>
        <div class="point-search__text-wrap">
          <p class="point-search__text">
            當筆交易點數明細，請於商品交易完成後1個小時進行查詢。
          </p>
        </div>
      </div>
      <div class="point-search__bottom-section">
        <div class="point-search__time-wrap">
          <span>顯示近</span>
          <select class="point-search__select point-search__select--time" ngModel
            [(ngModel)]="pointHistoryReq.dateRange" name="dateRange">
            <option [value]="dateRangeEnum.all">全部</option>
            <option [value]="dateRangeEnum.oneMonth">1個月</option>
            <option [value]="dateRangeEnum.threeMonth">3個月</option>
          </select>
          <span>點數歷程</span>
        </div>
        <div class="point-search__type-wrap">
          <div class="point-search__radio-wrap">
            <input class="point-search__radio" ngModel name="historyType" type="radio" [value]="historyTypeEnum.all"
              [(ngModel)]="pointHistoryReq.historyType">
            <label class="point-search__label" for="">全部歷程</label>
          </div>
          <!-- <div class="point-search__radio-wrap">
                        <input class="point-search__radio" ngModel name="historyType" type="radio"
                            [value]="historyTypeEnum.getPoint" [(ngModel)]="pointHistoryReq.historyType">
                        <label class="point-search__label" for="">獲得點數歷程</label>
                    </div> -->
          <div class="point-search__radio-wrap">
            <input class="point-search__radio" ngModel name="historyType" type="radio"
              [value]="historyTypeEnum.usePoint" [(ngModel)]="pointHistoryReq.historyType">
            <label class="point-search__label" for="">已使用點數歷程</label>
          </div>
          <select class="point-search__select point-search__select--history" ngModel name="historyType"
            [(ngModel)]="pointHistoryReq.historyType" id="">
            <option [value]="historyTypeEnum.all">全部歷程</option>
            <!-- <option [value]="historyTypeEnum.getPoint">獲得點數歷程</option> -->
            <option [value]="historyTypeEnum.usePoint">已使用點數歷程</option>
          </select>
        </div>
        <div class="point-search__count-wrap">
          <select class="point-search__select point-search__select--count" ngModel name="itemsPerPage" id=""
            (change)="onItemsPerPageChange($event)">
            <option value="10">顯示10筆</option>
            <option value="20">顯示20筆</option>
            <option value="30">顯示30筆</option>
          </select>
        </div>
        <!-- for pagination -->
        <input type="text" hidden ngModel name="currentPage">
      </div>
    </form>
  </section>
  <section #pointListWrap>
    <ul class="point-list" *ngIf="pointHistoryRes && !isDetailMode">
      <li class="point-list__item point-list__item--first-row">
        <span class="point-list__cell point-list__cell--first-row">日期</span>
        <!-- <span class="point-list__cell point-list__cell--first-row">獲得點數 (+)</span> -->
        <span class="point-list__cell point-list__cell--first-row">使用點數 (-)</span>
        <!-- <span class="point-list__cell point-list__cell--first-row">獲得來源</span> -->
        <span class="point-list__cell point-list__cell--first-row">使用項目</span>
        <span class="point-list__cell point-list__cell--first-row">功能</span>
      </li>
      <!-- <li class="point-list__item">
                    <span class="point-list__cell" data-attr="日期">
                        <span>
                            2021/10/02
                        </span>
                    </span>
                    <span class="point-list__cell" [ngClass]="{'point-list__cell--mobile-none': false}" data-attr="獲得點數">
                        <span>
                            +4000
                        </span>
                    </span>
                    <span class="point-list__cell" [ngClass]="{'point-list__cell--mobile-none': true}" data-attr="使用點數">
                        <span>
                        </span>
                    </span>
                    <span class="point-list__cell" [ngClass]="{'point-list__cell--mobile-none': false}" data-attr="+4000">
                        <span>
                            有償-購買車險
                        </span>
                    </span>
                    <span class="point-list__cell" [ngClass]="{'point-list__cell--mobile-none': true}" data-attr="使用明細">
                        <span>
                        </span>
                    </span>
                    <span class="point-list__cell" data-attr="點數到期日">
                        <span>
                            2021/12/31
                        </span>
                    </span>
                </li>
                <li class="point-list__item">
                    <span class="point-list__cell" data-attr="日期">
                        <span>
                            2021/09/03
                        </span>
                    </span>
                    <span class="point-list__cell" [ngClass]="{'point-list__cell--mobile-none': true}" data-attr="獲得點數">
                        <span>
                        </span>
                    </span>
                    <span class="point-list__cell" [ngClass]="{'point-list__cell--mobile-none': false}" data-attr="使用點數">
                        <span>
                            -300
                        </span>
                    </span>
                    <span class="point-list__cell" [ngClass]="{'point-list__cell--mobile-none': true}" data-attr="獲得來源">
                        <span>
                        </span>
                    </span>
                    <span class="point-list__cell" [ngClass]="{'point-list__cell--mobile-none': false}" data-attr="-300">
                        <span>
                            訂單編號：2021010300001
                        </span>
                    </span>
                    <span class="point-list__cell" data-attr="點數到期日">
                        <span>
                            2021/12/31
                        </span>
                    </span>
                </li> -->
      <li class="point-list__item" *ngFor="let item of pointHistoryRes.details; trackBy:trackByIdentify">
        <span class="point-list__cell" data-attr="日期">
          <span>
            {{item.dateTime | date:'YYYY/MM/dd'}}
          </span>
        </span>
        <!-- <span class="point-list__cell" [ngClass]="{'point-list__cell--mobile-none': true}" data-attr="獲得點數">
                        <span>
                        </span>
                    </span> -->
        <span class="point-list__cell" [ngClass]="{'point-list__cell--mobile-none': false}" data-attr="使用點數">
          <span>
            {{item.totalBonusChange}}
          </span>
        </span>
        <!-- <span class="point-list__cell" [ngClass]="{'point-list__cell--mobile-none': true}" data-attr="+4000">
                        <span>
                        </span>
                    </span> -->
        <span class="point-list__cell" [ngClass]="{'point-list__cell--mobile-none': false}"
          [attr.data-attr]="item.totalBonusChange">
          <span>
            訂單編號：{{item.orderNumber}}
          </span>
          <span *ngIf="item.vBonus >0">悠遊付點數：{{item.vBonus}}</span>
          <span *ngIf="item.cardBonus > 0">信用卡點數：{{item.cardBonus}}</span>
          <!-- <span *ngFor="let prod of item.prods">{{prod.paid == "001" ? "有償" : "無償"}}-{{prod.prodName}}
                            {{prod.bonusChange}}</span> -->
        </span>
        <span class="point-list__cell" data-attr="功能">
          <!-- <span>
                            &nbsp;
                        </span> -->
          <a class="point-list__detail-link" href="#"
            (click)="$event.preventDefault();handlePointLinkClick(item)">查看明細</a>
        </span>
      </li>
    </ul>
  </section>
  <section *ngIf="isDetailMode">
    <div class="point-back-to-list">
      <a (click)="backToMainPointList($event)">
        <img src="/assets/images/icon/arrow_left_black.svg" alt="">
        回上頁
      </a>
    </div>
    <ul class="point-detail-list" *ngIf="pointHistoryRes && pointDetailData">
      <li class="point-detail-list__item point-detail-list__item--first-row">
        <span class="point-detail-list__cell point-detail-list__cell--first-row">日期</span>
        <span class="point-detail-list__cell point-detail-list__cell--first-row">使用點數 (-)</span>
        <span class="point-detail-list__cell point-detail-list__cell--first-row">使用項目</span>
        <span class="point-detail-list__cell point-detail-list__cell--first-row">項目內容</span>
      </li>

      <li class="point-detail-list__item">
        <span class="point-detail-list__cell" data-attr="日期">
          <span>
            {{pointDetailData.dateTime | date:'YYYY/MM/dd'}}
          </span>
        </span>
        <span class="point-detail-list__cell" [ngClass]="{'point-detail-list__cell--mobile-none': false}"
          data-attr="使用點數">
          <span>
            {{pointDetailData.totalBonusChange}}
          </span>
        </span>
        <span class="point-detail-list__cell" [ngClass]="{'point-list__cell--mobile-none': false}"
          [attr.data-attr]="pointDetailData.totalBonusChange">
          <span>
            訂單編號：{{pointDetailData.orderNumber}}
          </span>
          <span *ngIf="pointDetailData.vBonus >0">悠客券平台：{{pointDetailData.vBonus}}</span>
          <span *ngIf="pointDetailData.cardBonus > 0">信用卡點數：{{pointDetailData.cardBonus}}</span>
        </span>
        <span class="point-detail-list__cell" data-attr="項目內容">
          <span *ngFor="let prod of pointDetailData.prods">{{prod.paid == "001" ? "有償" :
            "無償"}}-{{prod.prodName}}
            {{prod.bonusChange}}</span>
        </span>
      </li>
    </ul>
  </section>
  <app-pagination *ngIf="pointHistoryRes && !isDetailMode" [anchorElement]="pointListWrap" [totalItems]="totalDataCount"
    [currentPage]="currentPage" [itemsPerPageData]="perPageCountModel" (SelectPage)="onPaginationChange($event)">
  </app-pagination>
</section>

<!-- point regulation modal -->
<app-modal [isModalOpen]="isPointRegulationModalOpen" (modalClose)="isPointRegulationModalOpen=$event">
  <h4 headerContent class="point-regulation-modal__title">會員點數使用辦法與規範</h4>
  <div bodyContent class="point-regulation-modal__text-wrap">
    <p class="point-regulation-modal__text">當年度無償點數使用超過2萬點將會收到扣繳憑單
    </p>
    <!-- <ol class="point-regulation-modal__list">
            <li class="point-regulation-modal__item">當年度無償點數使用超過2萬點將會收到扣繳憑單</li>
            <li class="point-regulation-modal__item point-regulation-modal__item--last">當月點數明細紀錄需於次月才會顯示</li>
        </ol> -->
  </div>
  <div footerContent class="point-regulation-modal__button-wrap">
    <button
      class="point-regulation-modal__button point-regulation-modal__button--confirm point-regulation-modal__button--only"
      (click)="isPointRegulationModalOpen=false">我知道了</button>
  </div>
</app-modal>
