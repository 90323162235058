
export class Cart {
  cartID: number;
  img: string;
  amount: number =1;
  productType: string;
  productName: string = "";
  sn: string;
  point: number;
  price: number;
  payType: number;
  productTypeCodeID: number;
  remainingProductNum: number;
  limitPointType: string;
  productID: number;
}
export class CreateProductCart {
  MemberId: string
  ProductId: number
  Amount: number
}
export class CreateCart {
  isDetailId = true;
  MemberId: string
  ProductId:number
  ProductDtlId: number
  Amount: number
  Category1_id: number
  Category2_id: number
  isAgreeRefresh: boolean;
}

export class CheckoutReq {
  MbrId: string;
  MbrToken: string;
  MbrName: string;
  CardBonus: number;
  VBonus: number;
  Recipient: string
  Tel: string
  City: string = '' //default option value
  Area: string = '' // default option value
  Address: string
  Email: string
  IsAddCommonAddress: boolean
  PaymentMethod?: number;
  InvoiceMethod?: number;
  InvoiceTo: string;
  EmpNo: string;
  InvoiceName?: string;
  InvoicePhone?: string;
  InvoiceArea?= '';
  InvoiceCity?= '';
  InvoiceAddress?: string;
  InvoiceCompanyNumber?: string = '';
  IsCharityAnonymous = false;
  hasCharityTypeProduct = false;
  PID: number;
  Amount: number;
  IsAgreeInvoiceInfo: boolean = true;
}

export class InvoiceInfo {
  InvoiceName: string;
  InvoicePhone: string;
  InvoiceZipCode: number;
  InvoiceArea: string;
  InvoiceCity: string;
  InvoiceAddress: string;
}

export class CommonAddress {
  cId: number;
  cRecipent: string;
  cRecipentTel: string;
  cRecipentEmail: string;
  cCity: string;
  cArea: string;
  cAddress: string;
  cZipCode: number;
}

export class PosForm {
  merchantId: string;
  terminalId: string;
  merchantName: string;
  lidm: string;
  merId: string;
  customize: number;
  pageVer: string;
  purchAmt: number;
  txType: number = 0;
  checkValue: string;
  numberOfPay: number;
  encode: string;
  autoCap: number = 1;
  authResURL: string;
  authInfoPage: string;
}

export class TWPayForm {
  acqBank: string;
  terminalId: string;
  merchantId: string;
  encQRCode: string;
  encRetURL: string;
  orderNumber: string;
  txnType: string;
  walletList: string;
  preferWalletList: string;
  verifyCode: string;
}

export class DeleteCartReq {
  isCartId = true;
  Ids: number[] = [];
  isAddCollect: boolean;
}

export class CollectionTarget {
  productID: number
  productName: string
  productPoint: number
  insufficientPoint: number
}
