import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { addThouthandSeparator } from 'src/app/shared/helpers/format-helper';
import { MbrInfo, PointHistoryDateRange, PointHistoryDetail, PointHistoryHistoryType, PointHistoryReq, PointHistoryRes } from 'src/app/shared/model/member';
import { ItemsPerPageData } from 'src/app/shared/model/pagination';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';

@Component({
  selector: 'app-member-point',
  templateUrl: './member-point.component.html',
  styleUrls: ['./member-point.component.scss']
})
export class MemberPointComponent implements OnInit, AfterViewInit, OnDestroy {
  isDetailMode = false;
  detailOrderNumber: string;
  pointDetailData = new PointHistoryDetail();
  backFromDetail = false;

  // modal
  isPointRegulationModalOpen = false;
  pointHistoryReq: PointHistoryReq = new PointHistoryReq();
  pointHistoryRes: PointHistoryRes;;

  formValueChangeSub: Subscription;
  @ViewChild('searchForm') searchForm: NgForm;
  tempSearchFormData: {
    dateRange: string,
    historyType: string,
    itemsPerPage: string,
    currentPage: string
  }

  dateRangeEnum = PointHistoryDateRange;
  historyTypeEnum = PointHistoryHistoryType;

  totalDataCount = 0;
  previousPage = 0;
  currentPage = 1;
  perPageCountModel: ItemsPerPageData = new ItemsPerPageData();

  mbrInfo: MbrInfo;
  closestExpiredDate: Date;
  closestExpiredBonus: number;

  constructor(private httpService: HttpService,
    private loginService: LoginService,
    private pagetagService: PagetagService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.initQueryParams();
    this.setItemsPerPageModel(10);

    new Promise((resolve, reject) => {
      this.getMemberInfo();

      if (this.mbrInfo) { resolve('') }
      reject('會員未登入')
    }).then(() => {
      this.getClosestBonusExpiredData()
    }).catch(err => {
    })

    this.callPagetagService();
  }

  ngAfterViewInit() {
    this.onFormValueChange();
    this.initForm();
  }

  ngOnDestroy() {
    this.formValueChangeSub.unsubscribe();
  }

  trackByIdentify(index: number, item: PointHistoryDetail) {
    return item.orderNumber;
  }

  backToMainPointList(event: any) {
    if (event) {
      event.preventDefault();
    }
    this.isDetailMode = false;
    this.backFromDetail = true;
    this.patchTempSearchFormData();
  }

  handlePointLinkClick(item: PointHistoryDetail) {
    if (!item) { return }
    this.detailOrderNumber = item.orderNumber;
    this.pointDetailData = item;
    this.isDetailMode = true;
    this.setTempSearchFormData();
  }

  patchTempSearchFormData() {
    if (this.searchForm) {
      // 從詳細頁回到列表頁時，將之前的search form data 還原
      this.searchForm.setValue({
        ...this.tempSearchFormData
      })
    }
  }

  setTempSearchFormData() {
    // 進入詳細頁前，記住目前search form data
    this.tempSearchFormData = { ...this.searchForm.value }
  }

  getMemberInfo() {
    this.mbrInfo = this.loginService.GetLocalInfo();
  }

  getClosestBonusExpiredData() {
    let vBonusTimeStamp = Date.parse(this.mbrInfo.ClearVBonusDate.toString());
    let cardBonusTimeStamp = Date.parse(this.mbrInfo.ClearCardDate.toString());

    if (vBonusTimeStamp < cardBonusTimeStamp) {
      this.closestExpiredDate = this.mbrInfo.ClearVBonusDate;
      this.closestExpiredBonus = this.mbrInfo.ClearVBonus;
    } else if (cardBonusTimeStamp < vBonusTimeStamp) {
      this.closestExpiredDate = this.mbrInfo.ClearCardDate;
      this.closestExpiredBonus = this.mbrInfo.ClearCardBonus;
    } else if (vBonusTimeStamp == cardBonusTimeStamp) {
      this.closestExpiredDate = this.mbrInfo.ClearVBonusDate;
      this.closestExpiredBonus = this.mbrInfo.ClearVBonus + this.mbrInfo.ClearCardBonus;
    }
  }

  getPointHistory() {
    this.loaderService.start();

    let queryStr = this.getQueryStr();
    this.httpService.get(`api/member/getPointHistory?${queryStr}`).subscribe(
      (x: any) => {
        this.pointHistoryRes = x;
        this.totalDataCount = this.pointHistoryRes.totalDataCount;

        this.loaderService.stop();
      }
    )
  }

  getPointHistoryDetail(orderNumber: string) {
    for (let i = 0; i < this.pointHistoryRes.details.length; i++) {
      if (this.pointHistoryRes.details[i].orderNumber == orderNumber) {
        this.pointDetailData = this.pointHistoryRes.details[i];
        this.detailOrderNumber = this.pointHistoryRes.details[i].orderNumber;
        break;
      }
    }
    this.setTempSearchFormData();
    this.isDetailMode = true;
  }

  initQueryParams() {
    this.pointHistoryReq.memberID = this.loginService.GetLocalInfo().ID;
  }

  getQueryStr() {
    let queryStr = '';
    for (const [key, value] of Object.entries(this.pointHistoryReq)) {
      queryStr += `${key}=${value}&`;
    }

    return queryStr;
  }

  initForm() {
    setTimeout(() => {
      this.searchForm.setValue({
        dateRange: '0',
        historyType: '0',
        itemsPerPage: '10',
        currentPage: '1'
      })
    })
  }

  onFormValueChange() {
    this.formValueChangeSub = this.searchForm.valueChanges!.subscribe(x => {
      // 如果是從詳細頁回來，不做後續動作
      if (this.backFromDetail) { this.backFromDetail = !this.backFromDetail; return }

      let sendRequest = true;
      this.pointHistoryReq = Object.assign({ memberID: this.pointHistoryReq.memberID }, x);

      // 如果不是變動currentPage，則設定currentPage 回1
      if (this.previousPage == (typeof this.pointHistoryReq.currentPage == "number" ?
        this.pointHistoryReq.currentPage :
        parseInt(this.pointHistoryReq.currentPage))) {
        this.pointHistoryReq.currentPage = 1;
        this.currentPage = 1;
        this.previousPage = 0;
        this.onPaginationChange(1);
        return;
      }
      this.currentPage = typeof this.pointHistoryReq.currentPage == "number" ? this.pointHistoryReq.currentPage : parseInt(this.pointHistoryReq.currentPage);
      this.previousPage = this.currentPage;

      for (let i = 0; i < Object.values(x).length; i++) {
        if (!Object.values(x)[i]) {
          sendRequest = false;
          break;
        }

        continue;
      }

      if (sendRequest) { this.getPointHistory() };
    })
  }

  onPaginationChange(page: number) {
    if (this.isDetailMode) { return }
    this.searchForm.controls['currentPage'].setValue(page.toString());
  }

  onItemsPerPageChange($event: any) {
    const count = $event.target.value;
    this.setItemsPerPageModel(count)
  }

  setItemsPerPageModel(count: number) {
    this.perPageCountModel.min = count;
    this.perPageCountModel.sm = count;
    this.perPageCountModel.md = count;
    this.perPageCountModel.lg = count;
    this.perPageCountModel.xl = count;

    this.perPageCountModel = { ...this.perPageCountModel };
  }

  callPagetagService() {
    this.pagetagService.callPageTag(this.router.url, '會員服務 | 會員點數')
  }

  addThouthandMark(num: number) {
    return addThouthandSeparator(num);
  }
}
