import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { LoginService } from './login.service';
import { Subject } from 'rxjs';
import { BreadcrumbData } from '../model/breadcrumb';
@Injectable()

export class BreadcrumbService {
    breadcrumbData = new Subject<BreadcrumbData[]>();
    constructor(private httpService: HttpService, private loginService: LoginService) {
    }
}