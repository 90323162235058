import { Brand, Brandlist, proListItem } from './product';
import { QuestionType } from './question';
import { Event } from './event';

export class HomeInfoRes {
  hotTitle: string;
  hotTitle2: string;
  topBanners: HomeBanner[] = [];
  midBanners: HomeBanner[] = [];
  activities: HomeActivity[] = [];
  hotProducts: proListItem[] = [];
  hotProducts2: proListItem[] = [];
  hitProducts: proListItem[] = [];
  hotSellProducts: proListItem[] = [];
  brands: Brandlist[] = [];
  parentBrands: Brand[] = [];
}

export class HomeBanner {
  cName: string;
  cAnother: boolean;
  cMediaType: number;
  cUrl: string;
  cImg: string;
  cImgM: string;
  youtubeUrl: any;
}

class HomeActivity {
  cID: number;
  cTitle: string;
  cContentType: number;
  cImg: string;
  cUrl: string;
  cType: number;
}

export class MobileBrandList {
  brandLists: BrandListPage[] = [];
  productLists: BrandListPage[] = [];
}

export class BrandListPage {
  brands: Brandlist[] = [];
  products:proListItem[]=[];
}

export class GetTblCodeRes {
  cCodeName: string;
  cCodeId: number;
}

export class SiteMapRes {
  public parentBrandList: SiteMapParentBrand[];
  public qaTypeList: QuestionType[];
  public newsList: SiteMapNews[];
}

export class SiteMapParentBrand {
  public id: number;
  public name: string;
}

export class SiteMapNews {
  public newsType: number;
  public newsTypeName: string;
  public data: Event[];
}

export class SignatureReq {
  public memberID: string;
  public status: number;
  public content: string;
}

export class SignatureRes {}

export enum SignatureStatus {
  Agree = 1,
  Disagree = 2,
  DisagreeAndDoNotShow = 3,
}
