import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { HttpService } from "../service/http.service";
import { ShutdownService } from "../service/shutdown.service";

@Injectable()
export class ShutdownGuard implements CanActivate, CanActivateChild {

    constructor(private httpService: HttpService, private router: Router, private shutdownService: ShutdownService) { }

    // 參數會在進入路由後注入
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> {
        return new Observable<boolean>(obs => {
            let isShutdown;
            this.httpService.get('/index/stop').subscribe((res: any) => {
                isShutdown = res.result == '200' ? false : true;
                this.shutdownService.isShutdown.next(isShutdown);
                if (!isShutdown) {
                    if (route.url.length > 0 && route.url[0].path == 'shutdown') {
                        this.router.navigate(['/']);
                        return
                    }
                    obs.next(true);
                    return
                }

                if (isShutdown) {
                    this.shutdownService.shutdownContent.next(res.result);
                    if (route.url.length > 0 && route.url[0].path == 'shutdown') {
                        obs.next(true);
                        return
                    }

                    this.router.navigate(['/shutdown']);
                    return
                }
            }, err => {
                this.shutdownService.isShutdown.next(false);
                obs.next(true);
            })
        });
    }

    // 針對子路由
    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }
}
