<div class="home container">
    <app-breadcrumb></app-breadcrumb>
    <section *ngIf="shutdownContent.trim().length > 0" class="shutdown">
        <h2 class="shutdown__title">停機公告</h2>
        <div class="shutdown__content">
            <div class="shutdown__wrap">
                <div [innerHtml]="shutdownContent | html">
                </div>
            </div>
        </div>
    </section>
</div>