import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MbrInfo } from 'src/app/shared/model/member';
import { HeaderService } from 'src/app/shared/service/header.service';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss'],
})
export class MemberComponent implements OnInit {
  childrenPathObjList: { path: string; name: string }[] = [
    { path: 'order', name: '訂單查詢' },
    // { path: 'point', name: '會員點數' },
    // { path: 'ticket', name: '會員票夾' },
    // { path: 'favorite', name: '我的收藏' },
    // { path: 'voucher', name: '優惠碼' },
  ];

  isCartPage = false;
  userInfo: MbrInfo;

  constructor(
    private router: Router,
    public headerService: HeaderService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.detectIfCartPage();
    // this.userInfo = this.loginService.GetLocalInfo();
    // if (!this.userInfo.ID) {
    //   //透過change事件執行登入功能
    //   let obj = document.getElementById('doLogin');
    //   if (obj) {
    //     obj.dispatchEvent(new Event('change'));
    //   }
    // }
  }

  detectIfCartPage() {
    let url = window.location.href;
    if (url && url.length > 0 && url.indexOf('cart') > 0) {
      this.isCartPage = true;
    } else {
      this.isCartPage = false;
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (
          event.url &&
          event.url.length > 0 &&
          event.url.indexOf('cart') > 0
        ) {
          this.isCartPage = true;
          return;
        }
        this.isCartPage = false;
      });
  }
}
