import { HeaderService } from './shared/service/header.service';
import { HeaderComponent } from './shared/components/header/header.component';
import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import { LoginService } from './shared/service/login.service';
import { environment } from '../environments/environment';
import { HttpService } from './shared/service/http.service';
import { JsonPipe } from '@angular/common';
import { stringify } from 'querystring';
import { LoaderService } from './shared/service/loader.service';
import { getUserToken } from './shared/service/uupay_js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild(HeaderComponent) header: HeaderComponent;
  title = 'ClientAppNew';
  checked = false;
  isChecked = false;
  currentRoute: string;
  isCheckModalOpen = false;
  userToken: string;
  login: any;
  constructor(
    private http: HttpService,
    private router: Router,
    private loaderService: LoaderService,
    private loginService: LoginService,
    public headerService: HeaderService
  ) {
    this.currentRoute = '';
    // this.loginService
    //   .CheckMember(
    //     'ab0f3b41a452c0b5076fec85745a7ca6cc7b1a00e8aba427729d60a9082f281d'
    //   )
    //   .subscribe((x) => {
    //     this.checked = x;
    //     if (!x) this.isCheckModalOpen = true;
    //   });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // let userInfo = loginService.GetLocalInfo();
        // if (userInfo.IsLogin) {
        //   let now = new Date();
        //   let expireDate = new Date(userInfo.ExpireDate);
        //   if (expireDate < now) {
        //     alert('登入到期，請重新登入')
        //     loginService.Logout();
        //   } else {
        //     loginService.ResetBonus(userInfo.ID).subscribe((x: string) => {
        //       if (x == "success") {
        //         this.header.ngOnInit();
        //       }
        //     });
        //   }
        // }
      }

      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }

      if (event instanceof NavigationError) {
      }
    });
  }
  // ngOnInit(): void {
  // }
  ngAfterViewInit(): void {
    //透過change事件執行登入功能
    let obj = document.getElementById('doLogin');
    if (obj) {
      obj.addEventListener('change', (event) => {
        this.loginFunc();
      });
    }
  }

  check() {
    let arg = { Token: this.userToken };
    this.isChecked = true;
    // this.loginService.CheckMember(this.userToken).subscribe((x)=>{
    // });
    this.http
      .post('/Member/GetUserConfirm', arg)
      .subscribe((x: { jwtToken: string; isConfirm: boolean }) => {
        this.checked = !x.isConfirm;
        if (x) {
          this.isCheckModalOpen = !x.isConfirm;
          sessionStorage.setItem('jwtToken', x.jwtToken);
        }
        clearInterval(this.login);
      });
  }

  MemberConfirm() {
    if (this.userToken && this.userToken.length > 0) {
      this.isCheckModalOpen = false;
      this.loaderService.start();
      this.http
        .authPost('/Member/MemberConfirm', { Token: this.userToken })
        .subscribe((x: any) => {
          this.loaderService.stop();
          this.checked = x;
          if (!x) this.isCheckModalOpen = true;
        });
    }
  }

  loginFunc() {
    // localStorage.clear();
    let i = 0;
    this.login = setInterval(() => {
      let token = sessionStorage.getItem('userToken');
      if (!token || token.length == 0) {
        i++;
        if (i % 120 == 1) {
          getUserToken();
        }
      }
      if (token && token.length > 0 && !this.isChecked) {
        this.userToken = token;
        this.check();
      }
    }, 500);
  }
}
